/* eslint-disable max-len */
import React, { type SVGProps } from 'react';

const Cloud: React.FC<Partial<SVGProps<SVGSVGElement>>> = (props: Partial<SVGProps<SVGSVGElement>>) => {
  const { width, height } = props;

  return (
        <svg
            width= {width ?? '20' }
            height= {height ?? '16'}
            fill= {'currentColor'}
            viewBox="0 0 20 16"
            xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M8 0H2C0.9 0 0.00999999 0.9 0.00999999 2L0 14C0 15.1 0.9 16 2 16H18C19.1 16 20 15.1 20 14V4C20 2.9 19.1 2 18 2H10L8 0Z" />
        </svg>

  );
};

export default Cloud;
