import { useRef, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { type DocumentLoadEvent } from '@react-pdf-viewer/core';

import { OpportunityDocumentsViewer } from '../../components';
import type { OpportunityDocumentsViewerRef, RequirementMatchData } from '../../components';
import {
  selectRequirement,
  updateMatches,
  selectOpportunityFile,
  setDocumentLoading,
  setDocumentNumPages
} from '../../redux/WizardSlice';
import { selectWizardState } from '../../redux/store';
import { PDF_LOAD_DELAY_MS } from '../../utils/pdfUtils';

interface Props {
  onAddRequirement?: (requirementText: string, opportunityFileUid?: string) => void
}

const WizardOpportunityDocumentsViewer: React.FC<Props> = (
  { onAddRequirement }: Props
) => {
  const fileViewerRef = useRef<OpportunityDocumentsViewerRef>(null);

  const dispatch = useDispatch();
  const {
    requirements,
    opportunityFiles,
    selectedRequirementUid,
    selectedOpportunityFileIndex,
    matches
  } = useSelector(selectWizardState);

  useEffect(() => {
    const selectedReq = requirements?.find((req) => req.uid === selectedRequirementUid);
    const fileIndex = opportunityFiles
      ?.findIndex((file) => file.uid === selectedReq?.opportunity_file?.uid) ?? -1;
    if (fileIndex === selectedOpportunityFileIndex) {
      jumpToRequirement(selectedRequirementUid, matches);
    } else if (fileIndex !== -1 && fileIndex !== selectedOpportunityFileIndex) {
      dispatch(selectOpportunityFile({ opportunityFileIndex: fileIndex }));
    }
  }, [selectedRequirementUid]);

  // Jump to currently selected requirement every time matches are re-fetched
  useEffect(() => {
    setTimeout(() => {
      if (selectedRequirementUid !== '') {
        jumpToRequirement(selectedRequirementUid, matches);
      }
    }, PDF_LOAD_DELAY_MS);
  }, [matches]);

  const jumpToRequirement = useCallback((requirementUid: string, _matches: RequirementMatchData[]) => {
    const match = _matches.find((match) => match.requirementUid === requirementUid);
    const pageNumber = match?.pageNumber;
    if (pageNumber !== undefined) {
      fileViewerRef?.current?.jumpToPage(pageNumber);
    }
  }, [fileViewerRef]);

  const handleGetMatches = useCallback((newMatches: RequirementMatchData[]) => {
    dispatch(updateMatches({ matches: newMatches }));
  }, [fileViewerRef, selectedRequirementUid]);

  const handleClickFile = useCallback((fileIndex: number) => {
    dispatch(selectOpportunityFile({ opportunityFileIndex: fileIndex }));
  }, []);

  const handleClickRequirement = useCallback((requirementUid: string) => {
    dispatch(selectRequirement({ requirementUid }));
  }, []);

  const handleDocumentStartLoading = useCallback(() => {
    dispatch(setDocumentLoading({ loading: true }));
  }, []);

  const handleDocumentFinishedLoading = useCallback((e?: DocumentLoadEvent) => {
    dispatch(setDocumentLoading({ loading: false }));
    if (e !== undefined) {
      dispatch(setDocumentNumPages({ numPages: e.doc.numPages, opportunityFileIndex: selectedOpportunityFileIndex }));
    }
  }, [selectedOpportunityFileIndex]);

  return (
    <OpportunityDocumentsViewer
      ref={fileViewerRef}
      style={{ height: '100%' }}
      onClickHighlightedRequirement={handleClickRequirement}
      onAddRequirement={onAddRequirement}
      focusedRequirementUid={selectedRequirementUid}
      opportunityFiles={opportunityFiles ?? []}
      requirements={requirements ?? []}
      onGetMatches={handleGetMatches}
      currentFileIndex={selectedOpportunityFileIndex}
      onClickFile={handleClickFile}
      onStartDocumentLoad={handleDocumentStartLoading}
      onFinishDocumentLoad={handleDocumentFinishedLoading}
    />
  );
};

export default WizardOpportunityDocumentsViewer;
