import { useCallback } from 'react';
import axios from 'axios';
import type { AxiosError } from 'axios';
import { useAuth0 } from '@auth0/auth0-react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import * as Sentry from '@sentry/react';

import type {
  ResponseDataType,
  ResponseInputType,
  RouteWithRequestType,
  Content,
  Request,
  RouteWithGet
} from '../types/apiTypes';
import { showSuccessNotification, showFailureNotification } from '../utils/mantineUtils';
import { getAuthHeaders, getContentHeaders, getFullURLForRoute } from '../utils/apiUtils';

interface MutationConfig<
  Route extends RouteWithRequestType<RequestType>,
  ContentType extends Content = 'application/json',
  RequestType extends Request = 'post',
> {
  showSuccessMessage?: boolean
  successMessage?: string | React.ReactNode
  showFailureMessage?: boolean
  failureMessage?: string
  requestType?: RequestType
  contentType?: ContentType
  mutationKey?: string
  queryKeysToInvalidate?: RouteWithGet[]
  onSuccess?: (data: ResponseDataType<RequestType, Route>) => void
  onError?: (error: AxiosError) => void
  awaitRefetch?: boolean
};

const defaultMutationConfig: MutationConfig<RouteWithRequestType<'post'>, 'application/json', 'post'> = {
  showSuccessMessage: true,
  showFailureMessage: true,
  awaitRefetch: true,
  contentType: 'application/json',
  requestType: 'post'
};

function useEnsisMutation <
  Route extends RouteWithRequestType<RequestType>,
  ContentType extends Content = 'application/json',
  RequestType extends Request = 'post'
> (
  route: Route,
  mutationConfig?: MutationConfig<Route, ContentType, RequestType>
) {
  const { getAccessTokenSilently } = useAuth0();
  const queryClient = useQueryClient();

  const mutationConfigWithDefaults = {
    ...defaultMutationConfig,
    ...(mutationConfig ?? {})
  };
  const {
    showSuccessMessage,
    showFailureMessage,
    successMessage,
    failureMessage,
    requestType,
    contentType,
    mutationKey,
    queryKeysToInvalidate,
    awaitRefetch,
    onSuccess,
    onError
  } = mutationConfigWithDefaults;

  const mutateFunction = useCallback(
    async (
      data: ResponseInputType<RequestType, ContentType, Route>
    ): Promise<ResponseDataType<RequestType, Route>> => {
      // Get Auth0 access token
      const accessToken = await getAccessTokenSilently();

      let requestData = data;
      // need special form data handling when uploading files
      if (contentType === 'multipart/form-data' && 'files' in data && Array.isArray(data.files)) {
        requestData = new FormData();
        const { files, ...otherData } = data;
        files.forEach((file) => {
          requestData.append('files', file);
        });
        Object.entries(otherData).forEach(([key, value]) => {
          requestData.append(key, value);
        });
      }

      const headers = {
        ...getAuthHeaders(accessToken),
        ...getContentHeaders(contentType ?? 'application/json')
      };
      const fullURL = getFullURLForRoute(route);

      let result;
      if (requestType === 'delete') {
        result = await axios.delete(
          fullURL,
          { headers, data: requestData }
        );
      } else if (requestType === 'patch') {
        result = await axios.patch(
          fullURL,
          requestData,
          { headers }
        );
      } else if (requestType === 'get') {
        result = await axios.get(
          fullURL,
          { headers }
        );
      } else {
        result = await axios.post(
          fullURL,
          requestData,
          { headers }
        );
      }

      // invalidate caches queries
      if (queryKeysToInvalidate !== undefined) {
        const promises: Array<Promise<void>> = queryKeysToInvalidate.map(
          async (queryKey) => { await queryClient.invalidateQueries({ queryKey: [queryKey] }); }
        );
        if (awaitRefetch ?? true) {
          await Promise.all(promises);
        }
      }
      return result.data;
    }, [getAccessTokenSilently, route, queryKeysToInvalidate]
  );

  return useMutation({
    mutationKey: [mutationKey ?? route],
    mutationFn: mutateFunction,
    onSuccess: (data) => {
      if (onSuccess !== undefined) {
        onSuccess(data);
      }
      if (showSuccessMessage ?? false) {
        showSuccessNotification(successMessage);
      }
    },
    onError: (err: AxiosError) => {
      if (onError !== undefined) {
        onError(err);
      }
      if (showFailureMessage ?? false) {
        showFailureNotification(failureMessage);
      }
      Sentry.captureException(err);
    }
  });
};

export default useEnsisMutation;
