import { Box, Checkbox, Text, Group, Tooltip } from '@mantine/core';
import { useHover } from '@mantine/hooks';

import { useEffect, useRef, useState, forwardRef, useImperativeHandle } from 'react';

import WizardRequirementDropdown from '../../components/DropdownMenus/WizardRequirementDropdown';
import type { OpportunityRequirement } from '../../types/apiTypes';
import { getRequirementIdentifierSpan } from '../../utils/requirementUtils';

interface Props {
  checkedRequirements: string[]
  setCheckedRequirements: (reqs: string[]) => void
  requirement: OpportunityRequirement
  handleClickRequirement: () => void
  handleEditRequirement: () => void
  opportunityUid: string
}

export interface ReviewRequirementsRowRef {
  scrollIntoView: () => void
}

const ReviewRequirementsRow = forwardRef<
ReviewRequirementsRowRef,
Props
>((
  props: Props,
  ref
) => {
  const {
    checkedRequirements,
    handleEditRequirement,
    handleClickRequirement,
    requirement,
    setCheckedRequirements,
    opportunityUid
  } = props;

  const {
    uid: requirementUid,
    text: requirementText,
    identifier: requirementIdentifier
  } = requirement;

  const [isOverflowed, setIsOverflow] = useState(false);
  const textElementRef = useRef<HTMLDivElement>(null);

  /*
    Logic to determine if the requirementText overflows its box (isOverflowed). If so, render a
    tooltip with full requirementText on hover.
  */
  useEffect(() => {
    if (textElementRef.current !== null) {
      setIsOverflow(textElementRef.current.scrollHeight > textElementRef.current.clientHeight);
    }
  }, [requirementText]);

  useImperativeHandle(ref, () => ({
    scrollIntoView: () => {
      hoverRef.current?.scrollIntoView();
    }
  }));

  const { hovered, ref: hoverRef } = useHover();
  return (
    <Group ta='start' ref={hoverRef}>
      <Box w={20} p={4}>
      {(hovered || checkedRequirements?.length > 0) &&
        <Checkbox
          radius={4}
          value={requirementUid}
          checked={checkedRequirements.includes(requirementUid)}
          onChange={(event) => {
            event.stopPropagation();
            setCheckedRequirements(
              event.currentTarget.checked
                ? [...checkedRequirements, requirementUid]
                : checkedRequirements.filter((uid) => uid !== requirementUid)
            );
          }}
        />
      }
      </Box>
      <Group
        justify='space-between'
        p='4px 0px'
        w='calc(100% - 40px)'
        style={{ borderBottom: '1px solid var(--mantine-color-greyPurple-2)' }}
      >
        <Tooltip
          events={{
            hover: isOverflowed,
            focus: false,
            touch: false
          }}
          w={'calc(55vw - 120px)'}
          multiline
          fz='md'
          label={requirementText}
        >
          <Text
            ref={textElementRef}
            td={hovered ? 'underline' : undefined}
            w={'calc(100% - 55px)'}
            style={{
              overflow: 'hidden',
              display: '-webkit-box',
              WebkitBoxOrient: 'vertical',
              WebkitLineClamp: 2,
              textOverflow: 'ellipsis',
              cursor: 'pointer'
            }}
            onClick={handleClickRequirement}
          >
            {getRequirementIdentifierSpan(requirementIdentifier ?? '')}
            {requirementText}
          </Text>
        </Tooltip>
        <Box w={26} h={26}>
          {
            checkedRequirements.length === 0 &&
            <WizardRequirementDropdown
              requirementUid={requirementUid}
              opportunityUid={opportunityUid}
              handleOpenEditRequirement={handleEditRequirement}
            />
          }
        </Box>
      </Group>
    </Group>
  );
});

ReviewRequirementsRow.displayName = 'ReviewRequirementsRow';

export default ReviewRequirementsRow;
