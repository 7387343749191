import { Text } from '@mantine/core';

import ModalContent from '../ModalContent';

interface Props {
  generateNarrative: () => void
  close: () => void
}
const EmptyRequirementsGenerateNarrative: React.FC<Props> = (props: Props) => {
  const { generateNarrative, close } = props;

  return (
  <ModalContent
    title="Generate Narrative"
    primaryButton={{
      label: 'Generate',
      onClick: () => { close(); generateNarrative(); }
    }}
    secondaryButton={{
      label: 'Cancel',
      onClick: close
    }}
    onClose={close}
  >
    <Text>
      Some requirements are missing responses.
      We recommend completing as many as possible to improve the quality of your narrative.
    </Text>
  </ModalContent>
  );
};

export default EmptyRequirementsGenerateNarrative;
