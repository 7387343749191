import { Loader, Center } from '@mantine/core';
import type { CenterProps, LoaderProps } from '@mantine/core';

type Props = CenterProps & {
  loaderProps?: LoaderProps
};

const CenteredLoader: React.FC<Props> = ({ loaderProps, ...otherProps }: Props) => (
  <Center {...otherProps}>
    <Loader {...(loaderProps ?? {})} />
  </Center>
);

export default CenteredLoader;
