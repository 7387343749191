import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

import type { OpportunityRequirement, OpportunityFile } from '../types/apiTypes';
import type { RequirementMatchData } from '../components';

export type OpportunityFileWithPages = OpportunityFile & {
  numPages?: number
};
interface WizardState {
  requirements: OpportunityRequirement[]
  opportunityFiles: OpportunityFileWithPages[]
  matches: RequirementMatchData[]
  selectedRequirementUid: string
  selectedOpportunityFileIndex: number
  documentLoading: boolean
}
const initialState: WizardState = {
  requirements: [],
  opportunityFiles: [],
  matches: [],
  selectedRequirementUid: '',
  selectedOpportunityFileIndex: 0,
  documentLoading: true
};

interface RequirementUpdatePayload {
  requirement: OpportunityRequirement
}

interface UpdateMatchesPayload {
  matches: RequirementMatchData[]
}

interface RequirementDeletePayload {
  requirementUids: string[]
}

interface SelectRequirementPayload {
  requirementUid: string
}

interface SelectOpportunityFilePayload {
  opportunityFileIndex: number
}

interface SetDocumentLoadingPayload {
  loading: boolean
}
interface SetDocumentNumPagesPayload {
  numPages: number
  opportunityFileIndex: number
}

const wizardSlice = createSlice({
  name: 'wizard',
  initialState,
  reducers: {
    addAllRequirements (state, action: PayloadAction<OpportunityRequirement[]>) {
      state.requirements = action.payload;
    },
    addAllOpportunityFiles (state, action: PayloadAction<OpportunityFile[]>) {
      state.opportunityFiles = action.payload;
    },
    addRequirement (state, action: PayloadAction<RequirementUpdatePayload>) {
      const result = [...state.requirements];
      result.unshift(action.payload.requirement);
      state.requirements = result;
    },
    deleteRequirements (state, action: PayloadAction<RequirementDeletePayload>) {
      const result = [...state.requirements];
      state.requirements = result.filter((req) => !action.payload.requirementUids.includes(req.uid));
    },
    editRequirement (state, action: PayloadAction<RequirementUpdatePayload>) {
      const currentIndex = state.requirements.findIndex((req) => req.uid === action.payload.requirement.uid);
      state.requirements[currentIndex] = action.payload.requirement;
    },
    selectRequirement (state, action: PayloadAction<SelectRequirementPayload>) {
      state.selectedRequirementUid = action.payload.requirementUid;
    },
    selectOpportunityFile (state, action: PayloadAction<SelectOpportunityFilePayload>) {
      state.selectedOpportunityFileIndex = action.payload.opportunityFileIndex;
    },
    updateMatches (state, action: PayloadAction<UpdateMatchesPayload>) {
      state.matches = action.payload.matches;
    },
    setDocumentLoading (state, action: PayloadAction<SetDocumentLoadingPayload>) {
      state.documentLoading = action.payload.loading;
    },
    setDocumentNumPages (state, action: PayloadAction<SetDocumentNumPagesPayload>) {
      const result = [...state.opportunityFiles];
      result[action.payload.opportunityFileIndex].numPages = action.payload.numPages;
      state.opportunityFiles = result;
    },
    resetWizardState (state, action: PayloadAction) {
      return initialState;
    }
  }
});

export const {
  addAllRequirements,
  addAllOpportunityFiles,
  addRequirement,
  deleteRequirements,
  editRequirement,
  selectRequirement,
  updateMatches,
  selectOpportunityFile,
  setDocumentLoading,
  setDocumentNumPages,
  resetWizardState
} = wizardSlice.actions;

export default wizardSlice.reducer;
