import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

interface NarrativeComplianceState {
  selectedResponseUid: string
  showWarning: boolean
  // requirement uid to list of compliant text
  requirementToCompliantText: Record<string, string[]>
};

interface UpdateSelectedRequirementPayload {
  responseUid: string
}

interface UpdateRequiremetnCompliantTextPayload {
  requirementToCompliantText: Record<string, string[]>
}

const initialState: NarrativeComplianceState = {
  requirementToCompliantText: {},
  showWarning: true,
  selectedResponseUid: ''
};

const narrativeCompliance = createSlice({
  name: 'compliance',
  initialState,
  reducers: {
    resetNarrativeCompliance () {
      return initialState;
    },
    resetSelection (state) {
      state.selectedResponseUid = initialState.selectedResponseUid;
      state.showWarning = initialState.showWarning;
    },
    updateSelectedUid (state, action: PayloadAction<UpdateSelectedRequirementPayload>) {
      state.selectedResponseUid = action.payload.responseUid;
      state.showWarning = initialState.showWarning;
    },
    updateShowWarning (state, action: PayloadAction<boolean>) {
      state.showWarning = action.payload;
    },
    setMatches (state, action: PayloadAction<UpdateRequiremetnCompliantTextPayload>) {
      state.requirementToCompliantText = action.payload.requirementToCompliantText;
    }
  }
});

export const {
  updateShowWarning,
  resetNarrativeCompliance,
  setMatches,
  resetSelection,
  updateSelectedUid
} = narrativeCompliance.actions;
export default narrativeCompliance.reducer;
