import { Tooltip } from '@mantine/core';
import { RichTextEditor } from '@mantine/tiptap';

import classes from './GenerationFlow.module.css';

interface Props {
  onClickRegenerate: () => void
  disabled: boolean
}

const RevisionControl: React.FC<Props> = ({ onClickRegenerate, disabled }: Props) => (
  <Tooltip
    events={{
      hover: disabled,
      focus: false,
      touch: false
    }}
    label='Select 4 or more words to regenerate'
  >
    <RichTextEditor.Control
      p={0}
      onClick={onClickRegenerate}
      w={100}
      className={classes.customControls}
      style={{ borderRadius: '4px' }}
      disabled={disabled}
    >
      Revise
    </RichTextEditor.Control>
  </Tooltip>
);

export default RevisionControl;
