/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/quotes */
import type { SVGProps } from "react";
const SvgExitApp = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill="#343A40"
      d="M5 3h14c1.11 0 2 .89 2 2v4h-2V5H5v14h14v-4h2v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2m8.92 12.58L12.5 17l-5-5 5-5 1.42 1.41L11.33 11H21v2h-9.67z"
    />
  </svg>
);
export default SvgExitApp;
