import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { ActionIcon, Box, Button, Group, LoadingOverlay, Modal, Space, Stack } from '@mantine/core';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useDisclosure } from '@mantine/hooks';

import { formatSectionsMetadata, hasPermissionsForObject } from '../../utils/apiUtils';
import { resetText as resetOutlineText } from '../../redux/OutlineEditorSlice';
import { resetText as resetNarrativeText } from '../../redux/NarrativeEditorSlice';
import {
  selectNarrativeIsDirty, selectOutlineIsDirty, selectUpdatedNarrativeText, selectUpdatedOutlineText
} from '../../redux/store';
import useEnsisQuery from '../../hooks/useEnsisQuery';
import useEnsisMutation from '../../hooks/useEnsisMutation';
import { showWarningNotification, defaultModalProps } from '../../utils/mantineUtils';
import {
  CenteredLoader,
  type EditorViewType,
  type Position,
  viewTypeToLabelMap,
  SectionEditorTitle,
  SectionEditorNavigation
} from '../../components';
import type { RequirementResponse } from '../../types/apiTypes';
import {
  AddRequirement,
  DeleteSection,
  PreventNavigationWarning,
  RegenerateNarrative,
  GenerateNarrative,
  RenameSection,
  EmptyRequirementsGenerateNarrative,
  AddSection
} from '../../components/Modals';
import EditorPane,
{
  type NarrativeOverlayState,
  getViewTypeHasUnsavedChanges
} from './EditorPane/EditorPane';
import { SplitView } from '../../icons';
import CreateNarrativeDropdown from '../../components/DropdownMenus/CreateNarrativeDropdown';
import CloseRightPaneWarning from '../../components/Modals/CloseRightPaneWarning';
import { editorContentIsEmpty, EMPTY_EDITOR_CONTENT, formatTextForEditor } from '../../utils/stringUtils';
import { logAnalyticsEvent } from '../../utils/analyticsUtils';
import { ProposalStatusType } from '../../utils/constants';
import { UserAssignmentDropdown } from '../../components/DropdownMenus';

const ACQUIRE_LOCK_INTERVAL_MS = 1000 * 60;

export interface SectionData {
  uid: string
  title: string
  slug?: string
  ordinal?: number
  requirementResponses?: RequirementResponse[]
  parentSectionUid?: string
  childSections?: SectionData[]
}

const SectionEditor: React.FC = () => {
  const { proposalUid, sectionUid } = useParams();
  const dispatch = useDispatch();
  const updatedOutlineText = useSelector(selectUpdatedOutlineText);
  const outlineIsDirty = useSelector(selectOutlineIsDirty);
  const { updatedContent } = useSelector(selectUpdatedNarrativeText);
  const narrativeIsDirty = useSelector(selectNarrativeIsDirty);
  const previousSectionUid = useRef<string>('');
  const acquireLockInterval = useRef<NodeJS.Timer>();

  const [initialMutationPending, setInitialMutationPending] = useState(true);
  const [lockAcquired, setLockAcquired] = useState(false);
  const { data: proposalData, isLoading: proposalLoading } = useEnsisQuery(`/app/proposals/${proposalUid}/data`);
  const currentSection = proposalData?.sections?.find((sectionObj) => sectionObj.uid === sectionUid);
  const hasEditPermission = hasPermissionsForObject(proposalData, 'change');
  const [addRequirementOpened, addRequirementHandlers] = useDisclosure();
  const [renameSectionOpened, renameSectionHandlers] = useDisclosure();
  const [deleteSectionOpened, deleteSectionHandlers] = useDisclosure();
  const [addSubsectionOpened, addSubsectionHandlers] = useDisclosure();

  const [closePaneWarningOpened, closePaneWarningHandlers] = useDisclosure();
  const [generateNarrativeOpened, generateNarrativeHandlers] = useDisclosure();
  const [regenerateNarrativeOpened, regenerateNarrativeHandlers] = useDisclosure();
  const [emptyRequirementsOpened, emptyRequirementsHandlers] = useDisclosure();
  const [narrativeOverlayState, setNarrativeOverlayState] = useState<NarrativeOverlayState>(null);
  const [leftPaneView, setLeftPaneView] = useState<EditorViewType>('outline');
  const [rightPaneView, setRightPaneView] = useState<EditorViewType | null>(
    currentSection?.content === null ? 'documents' : 'narrative'
  );
  const [selectedRequirementUid, setSelectedRequirementUid] = useState('');
  const [defaultRequirementText, setDefaultRequirementText] = useState('');
  const [opportunityFileToAddTo, setOpportunityFileToAddTo] = useState<string | undefined>(undefined);
  const proposalStatus = proposalData?.status as ProposalStatusType;

  const {
    data: requirementResponseData,
    isLoading: requirementResponseLoading,
    isSuccess: queryIsSuccess
  } = useEnsisQuery(
    `/app/proposals/${proposalUid}/sections/${sectionUid}/requirement-responses`
  );

  const acquireLockMutation = useEnsisMutation(
    `/app/proposals/${proposalUid}/acquire-section-write-lock`,
    { requestType: 'post', showFailureMessage: false, showSuccessMessage: false }
  );
  const releaseLockMutation = useEnsisMutation(
    `/app/proposals/${proposalUid}/release-section-write-lock`,
    { requestType: 'post', showFailureMessage: false, showSuccessMessage: false }
  );

  const saveSectionOutlineMutation = useEnsisMutation(
    '/app/requirement-responses',
    {
      contentType: 'application/json',
      requestType: 'patch',
      successMessage: 'Section saved',
      onSuccess: () => { dispatch(resetOutlineText()); },
      queryKeysToInvalidate: [`/app/proposals/${proposalUid}/sections/${sectionUid}/requirement-responses`]
    }
  );
  const generateNarrativeMutation = useEnsisMutation(
    '/app/proposal-editing/generate-narrative', {
      requestType: 'post',
      showSuccessMessage: false,
      queryKeysToInvalidate: [`/app/proposals/${proposalUid}/data`]
    }
  );
  const updateSectionMutation = useEnsisMutation(
    `/app/proposal-sections/${sectionUid}/data`,
    {
      requestType: 'patch',
      successMessage: 'Section saved',
      onSuccess: () => { dispatch(resetNarrativeText()); },
      queryKeysToInvalidate: [`/app/proposals/${proposalUid}/data`]
    }
  );

  // locking logic
  // release lock on unmount
  useEffect(() => () => {
    releaseLockMutation.mutate({ section_uid: sectionUid ?? '' });
    dispatch(resetOutlineText());
    dispatch(resetNarrativeText());
    clearInterval(acquireLockInterval.current);
  }, []);

  const requestLock = useCallback(() => {
    acquireLockMutation.mutate({ section_uid: sectionUid ?? '' });
  }, [sectionUid, acquireLockMutation.isPending]);

  // on section change
  useEffect(() => {
    if (!queryIsSuccess) {
      return;
    }
    const changedSections = sectionUid !== previousSectionUid.current;
    if (hasEditPermission) {
      requestLock();
      clearInterval(acquireLockInterval.current);
      acquireLockInterval.current = setInterval(requestLock, ACQUIRE_LOCK_INTERVAL_MS);
    }
    if (changedSections) {
      if (previousSectionUid.current !== '') {
        // release lock if changed to different section
        releaseLockMutation.mutate({ section_uid: previousSectionUid.current });
        dispatch(resetOutlineText());
        dispatch(resetNarrativeText());
      }
      previousSectionUid.current = sectionUid ?? '';
    }
  }, [sectionUid, queryIsSuccess]);

  // on acquireLockMutation success
  useEffect(() => {
    if (!acquireLockMutation.isSuccess) {
      return;
    }
    // Clear initial loading state
    if (initialMutationPending) {
      setInitialMutationPending(false);
    }
    const _lockAcquired = acquireLockMutation.data?.lock_acquired ?? false;
    if (_lockAcquired !== lockAcquired) {
      setLockAcquired(_lockAcquired);
    }
    if (hasEditPermission && !_lockAcquired) {
      setLockAcquired(false);
      const userWithLock = acquireLockMutation.data?.lock_owner_email ?? '';
      showWarningNotification(`Editing unavailable: User ${userWithLock} is currently editing this section`);
    }
  }, [acquireLockMutation.isSuccess]);

  // update to only allow editing in authoring mode --> do we need a toast letting users know?
  const canEdit = lockAcquired && hasEditPermission && proposalStatus === ProposalStatusType.AUTHORING;

  const narrativeExists = currentSection?.content !== null &&
    currentSection?.content !== undefined;

  // on section change, handle editor pane views
  useEffect(() => {
    if (!narrativeExists) {
      handleShowSinglePane();
      setLeftPaneView('outline');
    } else {
      handleShowSecondaryPane();
    }
  }, [sectionUid, narrativeExists]);

  // reset narrative overlay state
  useEffect(() => {
    setNarrativeOverlayState(null);
  }, [sectionUid]);

  const hasEmptyRequirements = useMemo(() => {
    const items = requirementResponseData?.items.filter(
      (response) => (
        response.requirement !== null && editorContentIsEmpty(response.content)
      )
    );
    return (items?.length ?? 0) > 0;
  }, [requirementResponseData]);

  const formattedSections = useMemo(() => (
    formatSectionsMetadata(proposalData?.sections ?? [])), [proposalData]);

  const handleClickAddRequirement = useCallback((
    requirementText: string,
    opportunityFileUid?: string
  ) => {
    setDefaultRequirementText(requirementText);
    setOpportunityFileToAddTo(opportunityFileUid);
    addRequirementHandlers.open();
  }, [addRequirementHandlers]);

  const handleShowNarrativeInPane = useCallback(() => {
    if (leftPaneView !== 'narrative' && rightPaneView !== 'narrative') {
      setRightPaneView('narrative');
    }
  }, [leftPaneView, rightPaneView]);

  const handleSaveNarrative = useCallback(() => {
    updateSectionMutation.mutate({ content: updatedContent === EMPTY_EDITOR_CONTENT ? '' : updatedContent });
  }, [updatedContent]);

  const handleSaveOutline = useCallback(() => {
    const data = {
      proposal_uid: proposalUid ?? '',
      edits: Object.entries(updatedOutlineText).map(([key, value]) => ({
        requirement_response_uid: key,
        new_content: value === EMPTY_EDITOR_CONTENT ? '' : value
      }))
    };
    saveSectionOutlineMutation.mutate(data);
  }, [saveSectionOutlineMutation.mutate, updatedOutlineText]);

  const handleSavePage = useCallback(() => {
    if (outlineIsDirty) {
      handleSaveOutline();
    }
    if (narrativeIsDirty) {
      handleSaveNarrative();
    }
  }, [handleSaveNarrative, handleSaveOutline]);

  const handleShowSinglePane = useCallback(() => {
    if (rightPaneView === 'narrative') {
      dispatch(resetNarrativeText());
    } else if (rightPaneView === 'outline') {
      dispatch(resetOutlineText());
    }
    setRightPaneView(null);
  }, []);

  const handleShowSecondaryPane = useCallback(() => {
    let newRightPaneView: EditorViewType = leftPaneView === 'narrative' ? 'outline' : 'narrative';
    if (!narrativeExists) {
      newRightPaneView = leftPaneView === 'outline' ? 'documents' : 'outline';
    }
    setRightPaneView(newRightPaneView);
  }, [leftPaneView, narrativeExists]);

  const handleClickGenerateNarrative = useCallback(() => {
    logAnalyticsEvent('GenerateNarrative');
    if (hasEmptyRequirements) {
      emptyRequirementsHandlers.open();
    } else {
      generateNarrativeHandlers.open();
    }
  }, [generateNarrativeHandlers, emptyRequirementsHandlers, hasEmptyRequirements]);

  const handleRegenerateContinue = useCallback(() => {
    regenerateNarrativeHandlers.close();
    generateNarrativeHandlers.open();
  }, [regenerateNarrativeHandlers, generateNarrativeHandlers]);

  const handleGenerateNarrative = useCallback(async (numberOfPages?: number) => {
    const data = await generateNarrativeMutation.mutateAsync({
      proposal_section_uid: sectionUid ?? '',
      page_count: numberOfPages
    });
    return data.new_text;
  }, [generateNarrativeMutation, currentSection, proposalData]);
  const runComplianceMutation = useEnsisMutation(`/app/proposal-sections/${sectionUid}/compliance-analysis`, {
    showSuccessMessage: false,
    queryKeysToInvalidate: [
      `/app/proposals/${proposalUid}/sections/${sectionUid}/requirement-responses`,
      `/app/proposals/${proposalUid}/data`
    ]
  });

  const onGenerateAndSaveNarrativeSuccess = useCallback(() => {
    setNarrativeOverlayState('readyScreen');
    handleShowNarrativeInPane();
    runComplianceMutation.mutate({});
  }, [handleShowNarrativeInPane, runComplianceMutation]);

  const handleGenerateAndSaveNarrative = useCallback(async (numberOfPages?: number) => {
    const newText = await handleGenerateNarrative(numberOfPages);
    updateSectionMutation.mutate({
      content: formatTextForEditor(newText),
      page_count: numberOfPages ?? 0
    }, {
      onSuccess: onGenerateAndSaveNarrativeSuccess,
      onError: handleShowSinglePane
    });
  }, [updateSectionMutation, handleGenerateNarrative, onGenerateAndSaveNarrativeSuccess, handleShowSinglePane]);

  const handleToggleSecondaryPane = useCallback(() => {
    if (rightPaneView !== null) {
      if (getViewTypeHasUnsavedChanges(
        rightPaneView,
        outlineIsDirty,
        narrativeIsDirty
      )) {
        closePaneWarningHandlers.open();
      } else {
        handleShowSinglePane();
      }
    } else {
      handleShowSecondaryPane();
    }
  }, [
    handleShowSecondaryPane,
    handleShowSinglePane,
    rightPaneView,
    outlineIsDirty,
    narrativeIsDirty
  ]);

  const handleSelectPaneType = useCallback((value: string, position: Position) => {
    const oppositePositionValue = position === 'left' ? rightPaneView : leftPaneView;
    if (value === oppositePositionValue) {
      const prevLeftPane = leftPaneView;
      setLeftPaneView(rightPaneView ?? 'outline');
      setRightPaneView(prevLeftPane);
    } else if (position === 'left') {
      setLeftPaneView(value as EditorViewType);
    } else {
      setRightPaneView(value as EditorViewType);
    }
  }, [rightPaneView, leftPaneView]);

  const getViewTypeIsDisabled = useCallback((viewType: EditorViewType) => {
    if ((viewType === 'narrative' || viewType === 'compliance') && !narrativeExists) {
      return true;
    }
    return getViewTypeHasUnsavedChanges(
      viewType,
      outlineIsDirty,
      narrativeIsDirty
    );
  }, [narrativeOverlayState, outlineIsDirty, narrativeIsDirty, narrativeExists]);

  const onSelectRequirement = useCallback((reqUid: string) => {
    if (reqUid === selectedRequirementUid) {
      setSelectedRequirementUid('');
    } else {
      setSelectedRequirementUid(reqUid);
    }
  }, [selectedRequirementUid, setSelectedRequirementUid]);

  const isLoading = proposalLoading || requirementResponseLoading;

  if (isLoading || proposalData === undefined || currentSection === undefined) {
    return <CenteredLoader display="flex" style={{ height: '100vh', width: '100%' }} />;
  }
  const sectionHasUnsavedChanges = outlineIsDirty || narrativeIsDirty;

  const preventLeaveSectionWarning = (
    <PreventNavigationWarning
      title="Leave this section?"
      shouldPreventNavigation={sectionHasUnsavedChanges}
      shouldPreventRefresh
    >
      {'You made changes to this proposal section that haven\'t been saved. Are you sure you want to leave?'}
      <Space h='md'/>
      Save before you go!
    </PreventNavigationWarning>
  );

  const isOnlyTopLevelSection = formattedSections.filter(
    (section) => section.parentSectionUid === undefined
  ).length < 2;
  const saveDisabled = !canEdit ||
    !(outlineIsDirty || narrativeIsDirty);

  const enabledViewTypes: EditorViewType[] = ['outline', 'narrative', 'documents', 'compliance'].filter(
    (type) => !getViewTypeIsDisabled(type as EditorViewType)
  ) as EditorViewType[];

  const editorPaneProps = {
    handleOptionSubmit: handleSelectPaneType,
    section: currentSection,
    proposal: proposalData,
    onClickGenerateNarrative: handleClickGenerateNarrative,
    setNarrativeOverlayState: (state: NarrativeOverlayState) => { setNarrativeOverlayState(state); },
    onComplianceModeClick: handleShowNarrativeInPane,
    complianceCheckIsRunning: runComplianceMutation.isPending,
    focusedRequirementUid: selectedRequirementUid,
    requirementResponses: requirementResponseData?.items ?? [],
    onAddRequirement: handleClickAddRequirement,
    canEdit,
    onSelectRequirement,
    narrativeOverlayState
  };
  return (
    <Box ta='start' ml={8} mr={8} miw={902} flex={1}>
      <Stack gap={0}>
        <Group justify='space-between' mt={16} mr={16} ml={16}>
          <SectionEditorTitle
            sectionTitle={currentSection?.title ?? ''}
            sectionUid={sectionUid ?? ''}
            canEdit={canEdit}
            handleOpenAddRequirement={addRequirementHandlers.open}
            handleOpenDelete={deleteSectionHandlers.open}
            handleOpenRename={renameSectionHandlers.open}
            handleOpenAddSubsection={addSubsectionHandlers.open}
            isOnlyTopLevelSection={isOnlyTopLevelSection}
            parentSection={proposalData.sections?.find(
              (section) => section.uid === currentSection.parent_proposal_section_uid
            )
            }
          />
          <Group>
            <SectionEditorNavigation sections={proposalData.sections ?? []} />
            {narrativeExists
              ? (
                  narrativeOverlayState === null && (
                    <Button disabled={!canEdit} variant='outline' onClick={handleClickGenerateNarrative}>
                      Generate Narrative
                    </Button>
                  )
                )
              : (
                  <CreateNarrativeDropdown
                    canEdit={canEdit}
                    sectionUid={sectionUid ?? ''}
                    onClickGenerateNarrative={handleClickGenerateNarrative}
                  />
                )
            }
            <Button
              disabled={saveDisabled}
              onClick={handleSavePage}
            >
              Save
            </Button>
            <ActionIcon
              size={36}
              p={6}
              bg={rightPaneView !== null ? 'var(--mantine-color-lightPurple-1)' : 'var(--mantine-color-gray-1)'}
              c='var(--mantine-color-darkPurple-9)'
              onClick={handleToggleSecondaryPane}
            >
              <SplitView />
            </ActionIcon>
          </Group>
        </Group>
        <Group ml={16} mb={8}>
          <UserAssignmentDropdown
            sectionUid={currentSection.uid}
          />
        </Group>
      </Stack>
      { preventLeaveSectionWarning }
      <Modal opened={emptyRequirementsOpened} {...defaultModalProps}>
        <EmptyRequirementsGenerateNarrative
          close={emptyRequirementsHandlers.close}
          generateNarrative={generateNarrativeHandlers.open}
        />
      </Modal>
      <Modal opened={addSubsectionOpened} {...defaultModalProps}>
        <AddSection
          sections={formattedSections ?? []}
          close={addSubsectionHandlers.close}
          parentSectionUid={sectionUid}
        />
      </Modal>
      <Modal opened={addRequirementOpened} {...defaultModalProps}>
        <AddRequirement
          sectionToEditUid={sectionUid ?? ''}
          close={addRequirementHandlers.close}
          defaultRequirementText={defaultRequirementText}
          opportunityFileUid={opportunityFileToAddTo}
        />
      </Modal>
      <Modal opened={regenerateNarrativeOpened} {...defaultModalProps}>
        <RegenerateNarrative
          close={regenerateNarrativeHandlers.close}
          onContinue={handleRegenerateContinue}
        />
      </Modal>
      <Modal opened={generateNarrativeOpened} {...defaultModalProps}>
        <GenerateNarrative
          close={generateNarrativeHandlers.close}
          onGenerateNarrative={async (numberOfPages) => { await handleGenerateAndSaveNarrative(numberOfPages); }}
          proposalSpacing={Number(proposalData?.spacing ?? 1)}
          defaultPageCount={currentSection?.page_count ?? 0}
        />
      </Modal>
      <Modal opened={deleteSectionOpened} {...defaultModalProps}>
        <DeleteSection
          sections={formattedSections ?? []}
          close={deleteSectionHandlers.close}
          sectionToEditUid={sectionUid ?? ''}
          />
      </Modal>
      <Modal opened={renameSectionOpened} {...defaultModalProps}>
        <RenameSection
          sections={formattedSections ?? []}
          close={renameSectionHandlers.close}
          sectionToEdit={currentSection}
        />
      </Modal>
      <Modal opened={closePaneWarningOpened} {...defaultModalProps}>
        <CloseRightPaneWarning
          close={closePaneWarningHandlers.close}
          viewName={rightPaneView !== null ? viewTypeToLabelMap[rightPaneView] : ''}
          closeRightPane={handleShowSinglePane}/>
      </Modal>
      <LoadingOverlay visible={saveSectionOutlineMutation.isPending} />
      <Box ml={22} mr={22}>
      <Box style={{ display: 'flex', flexDirection: 'row' }}>
        <Box style={{ flex: 1 }} w={rightPaneView !== null ? '50%' : '100%'}>
          <EditorPane
            {...editorPaneProps}
            currentViewType={leftPaneView}
            isFullScreen={rightPaneView === null}
            viewTypes={['outline', 'narrative', 'documents', 'compliance']}
            position='left'
            enabledViewTypes={getViewTypeIsDisabled(leftPaneView) ? [] : enabledViewTypes}
          />
         </Box>
        {rightPaneView !== null && (
          <Box style={{ flex: 1, borderLeft: '1px var(--mantine-color-gray-2) solid' }} w='50%'>
            <EditorPane
              {...editorPaneProps}
              currentViewType={rightPaneView}
              viewTypes={['outline', 'narrative', 'documents', 'compliance']}
              position="right"
              enabledViewTypes={getViewTypeIsDisabled(rightPaneView) ? [] : enabledViewTypes}
            />
          </Box>
        )}
      </Box>
      </Box>
    </Box>
  );
};

export default SectionEditor;
