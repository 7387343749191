import { Button, Checkbox, Group, Text } from '@mantine/core';

import { type WithoutPermissions, type EnsisUser } from '../../../types/apiTypes';
import classes from '../DropdownMenus.module.css';
import { getUserDisplayName } from '../../../utils/stringUtils';

interface Props {
  user: WithoutPermissions<EnsisUser>
  selected?: boolean
  handleSelect: (assignment: WithoutPermissions<EnsisUser>) => void
}

const UserAssignmentItem: React.FC<Props> = (props: Props) => {
  const { user, handleSelect, selected } = props;
  return (
    <Button
      key={user.uid}
      classNames={{ root: classes.buttonItemRoot, inner: classes.buttonItems }}
      variant='subtle'
      onClick={(event) => {
        handleSelect(user);
        event.stopPropagation();
      }}
    >
      <Group p='0' gap='8' m='0'>
        <Checkbox
          readOnly
          checked={selected}
          styles={{
            input: {
              borderRadius: 4
            }
          }}
          onClick={(event) => {
            handleSelect(user);
            event.stopPropagation();
          }}
        />
        <Text fz='xs' truncate> {getUserDisplayName(user)} </Text>
      </Group>
    </Button>
  );
};

export default UserAssignmentItem;
