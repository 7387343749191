import { Button, Group, Textarea } from '@mantine/core';
import { useForm } from '@mantine/form';

import { useParams } from 'react-router-dom';
import { useCallback } from 'react';

import useEnsisMutation from '../../hooks/useEnsisMutation';
import { type Proposal } from '../../types/apiTypes';

const PLACEHOLDER_TEXT = 'Suggested inputs include win themes and differentiators, ' +
  'including what you will do to address this RFP and the customer’s pain points. ' +
  'Describe the benefits of choosing you over the competition. Ensis AI leverages ' +
  'these inputs to create a customized proposal for your organization, guaranteeing ' +
  'it distinguishes you from your competitors.';

interface Props {
  additionalContext?: string | null
}

export const AdditionalProposalInfo: React.FC<Props> = (props: Props) => {
  const { proposalUid } = useParams();
  const { additionalContext } = props;
  const form = useForm({
    initialValues: {
      text: additionalContext ?? ''
    }
  });

  const onMutationSuccess = useCallback(
    (data: Proposal) => {
      form.setInitialValues({
        text: data.additional_context ?? ''
      });
      form.reset();
    },
    []
  );

  const editProposalAdditionalInfoMutation = useEnsisMutation(`/app/proposals/${proposalUid}/data`, {
    requestType: 'patch',
    successMessage: 'Additional information updated',
    queryKeysToInvalidate: [`/app/proposals/${proposalUid}/data`],
    onSuccess: onMutationSuccess
  });

  const onSubmit = useCallback((values: typeof form.values) => {
    editProposalAdditionalInfoMutation.mutate({
      additional_context: values.text
    });
  }, [editProposalAdditionalInfoMutation.mutate]);

  return (
    <form onSubmit={form.onSubmit(onSubmit)}>
      <Group justify='right'>
        <Button
          variant='outline'
          type='submit'
          disabled={!form.isDirty() || editProposalAdditionalInfoMutation.isPending}
        >
          Save
        </Button>
      </Group>
      <Textarea
        mt={7}
        autosize
        placeholder={PLACEHOLDER_TEXT}
        minRows={4}
        {...form.getInputProps('text')}
      />
    </form>
  );
};
