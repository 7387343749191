import { Modal } from '@mantine/core';
import { useBlocker } from 'react-router-dom';
import { useEffect, useCallback } from 'react';

import { defaultModalProps } from '../../utils/mantineUtils';
import ModalContent from '../ModalContent';

interface Props {
  shouldPreventNavigation: boolean
  title: string
  children?: React.ReactNode | React.ReactNode[]
  shouldPreventRefresh?: boolean
}
const PreventNavigationWarning: React.FC<Props> = (props: Props) => {
  const {
    shouldPreventRefresh,
    shouldPreventNavigation,
    title,
    children
  } = props;

  const blocker = useBlocker(({ currentLocation, nextLocation }) => (
    shouldPreventNavigation &&
    currentLocation.pathname !== nextLocation.pathname
  ));

  useEffect(() => {
    const unloadCallback = (event: BeforeUnloadEvent) => {
      if (shouldPreventNavigation && (shouldPreventRefresh ?? false)) {
        event.preventDefault();
      }
    };

    window.addEventListener('beforeunload', unloadCallback);
    return () => { window.removeEventListener('beforeunload', unloadCallback); };
  }, [shouldPreventNavigation, shouldPreventRefresh]);

  const handleBlockerReset = useCallback(() => {
    blocker.reset !== undefined && blocker.reset();
  }, [blocker.reset]);
  const handleBlockerProceed = useCallback(() => {
    blocker.proceed !== undefined && blocker.proceed();
  }, [blocker.proceed]);

  return (
    <Modal opened={blocker.state === 'blocked'} {...defaultModalProps}>
      <ModalContent
        title={title}
        onClose={handleBlockerReset}
        primaryButton={{ label: 'Leave', onClick: handleBlockerProceed, color: 'red' }}
        secondaryButton={{ label: 'Stay', onClick: handleBlockerReset, color: 'red' }}
      >
        {children}
      </ModalContent>
    </Modal>
  );
};

export default PreventNavigationWarning;
