import { Radio, Group, Box, Text, type BoxProps } from '@mantine/core';

import { getWordsPerPage } from '../../utils/formattingUtils';

type SpacingSelection = '1' | '1.5' | '2';

type Props = {
  value: number
  onSelectValue: (value: number) => void
} & Omit<BoxProps, 'children'>;

const convertSpacingSettingToString = (spacing?: number): SpacingSelection => (
  (spacing ?? 1).toString() as SpacingSelection
);

const convertSpacingSettingToNumber = (spacing?: SpacingSelection): number => (
  Number(spacing ?? '1')
);

const ProposalSpacingSelector: React.FC<Props> = (
  { value, onSelectValue, ...boxProps }: Props
) => {
  const wordsPerPage = getWordsPerPage(Number(value ?? '1'));
  return (
    <Box {...boxProps}>
      <Radio.Group
        name="proposalSpacing"
        mb={24}
        value={convertSpacingSettingToString(value)}
        onChange={(value) => { onSelectValue(convertSpacingSettingToNumber(value as SpacingSelection)); }}
      >
        <Group mt="xs">
          <Radio value="2" label="Double Space" />
          <Radio value="1.5" label="1.5 Space" />
          <Radio value="1" label="Single Space" />
        </Group>
      </Radio.Group>
      <Text fz="sm" c="var(--mantine-color-gray-6)" mb={24}>
        {`Approximately ${wordsPerPage} words per page`}
      </Text>
    </Box>
  );
};

export default ProposalSpacingSelector;
