import { Box, TextInput, Text, Textarea, LoadingOverlay } from '@mantine/core';

import { useNavigate, useParams } from 'react-router-dom';

import { useForm } from '@mantine/form';

import { type FileWithPath } from '@mantine/dropzone';

import { useCallback } from 'react';

import Dropzone from '../../components/Dropzone';
import { type FileWithMetadata } from '../../components/Forms/UploadResources/UploadResources';

import useEnsisMutation from '../../hooks/useEnsisMutation';
import { INVALID_FILE_ERROR_MESSAGE } from '../../utils/apiUtils';
import { DEFAULT_FAILURE_MESSAGE, showFailureNotification } from '../../utils/mantineUtils';
import { logAnalyticsEvent } from '../../utils/analyticsUtils';

import WizardContent from './WizardContent';
const ADDITIONAL_CONTEXT_TEXT =
'Add any additional information relevant to this proposal, ' +
'such as win themes and differentiators (optional)';
const ADDITIONAL_CONTEXT_HELPER_TEXT = 'Suggested inputs include win themes and differentiators, ' +
'including what you will do to address this RFP and the customer’s pain points. ' +
'Describe the benefits of choosing you over the competition. Ensis AI leverages ' +
'these inputs to create a customized proposal for your organization, guaranteeing ' +
'it distinguishes you from your competitors.';

interface FormValues {
  files: FileWithPath[]
  proposalName: string
  additionalContext: string
}

const CreateProposal: React.FC = () => {
  const handleSetFilesOnForm = (files: FileWithMetadata[]) => {
    const filesToAdd = files.map((file) => file.fileObject);
    form.setFieldValue('files', filesToAdd);
  };

  const { organization } = useParams();
  const form = useForm({
    initialValues: {
      files: [] as FileWithPath[],
      proposalName: '',
      additionalContext: ''
    },
    validate: {
      files: (value) => ((value === null || value.length === 0) ? 'Please select file(s) to upload' : null),
      proposalName: (value) => (value.trim().length < 1 ? 'Proposal name is required' : null)
    }
  });
  const navigate = useNavigate();
  const submitProposalMutation = useEnsisMutation(
    '/app/proposals',
    {
      contentType: 'multipart/form-data',
      requestType: 'post',
      showSuccessMessage: false,
      showFailureMessage: false,
      queryKeysToInvalidate: ['/app/proposals'],
      onSuccess: (data) => {
        navigate(`/org/${organization}/proposals/create-proposal/${data.uid}`);
      },
      onError: (error) => {
        let errorMessage = DEFAULT_FAILURE_MESSAGE;
        if (error?.response?.status === 422) {
          errorMessage = INVALID_FILE_ERROR_MESSAGE;
        }
        showFailureNotification(errorMessage);
      }
    }
  );

  const onSubmitForm = useCallback((values: FormValues) => {
    logAnalyticsEvent('CreateProposal');
    submitProposalMutation.mutate({
      name: values.proposalName,
      files: values.files as unknown as string[],
      organization_slug: organization ?? '',
      additional_context: values.additionalContext
    });
  }, [submitProposalMutation.mutate]);

  return (
    <Box pt={24}>
    <WizardContent
      titleCopy="Start your proposal"
      subtitleCopy="First import an opportunity to begin generating your proposal"
      nextButtonDisabled={!form.isValid()}
      onClickNext={() => {
        onSubmitForm(form.values);
      }}
    >
    <LoadingOverlay visible={submitProposalMutation.isPending}/>
    <Box ta='start' display={'flex'} style={{ flexDirection: 'row' }}>
      <Box w='55vw' mr='24'>
      <TextInput
          mb={16}
          placeholder='Your proposal name'
          label='Proposal name'
          styles={{ input: { height: '48px' } }}
          {...form.getInputProps('proposalName')}
          required
        />
        <Box>
          <Dropzone
            files={form.values.files.map((file) => { return { fileObject: file }; })}
            setFiles={handleSetFilesOnForm}
            instructionText='Click or drag and drop to upload your opportunity file(s)'
          />
        </Box>
      </Box>
      <Box
        style={{
          borderRadius: 4,
          border: '1px solid var(--mantine-color-gray-2)',
          minHeight: 'calc(100vh - 290px)'
        }}
      >
        <Box p='6.5px 16px'>
          <Text size='sm'> {ADDITIONAL_CONTEXT_TEXT} </Text>
        </Box>
        <Textarea
          placeholder={ADDITIONAL_CONTEXT_HELPER_TEXT}
          {...form.getInputProps('additionalContext')}
          styles={{
            input: {
              minHeight: 'calc(100vh - 350px)',
              border: 'none',
              backgroundColor: 'var(--mantine-color-lightPurple-0)'
            }
          }}
        />
      </Box>
    </Box>
   </WizardContent>
   </Box>
  );
};

export default CreateProposal;
