import { Text } from '@mantine/core';
import {
  type Row,
  createColumnHelper
} from '@tanstack/react-table';
import { useCallback, useState } from 'react';

import { Pen, Trash } from '../icons';
import type { ProposalRow } from '../types/tableTypes';
import type { ProcessingStatusType, Proposal } from '../types/apiTypes';
import { hasPermissionsForObject } from '../utils/apiUtils';
import usePaginatedTable from './usePaginatedTable';
import { ThreeDotsMenu } from '../components';
import { getDateStringForTableCell } from '../utils/tableUtils';
import { getProposalOwnerName } from '../utils/stringUtils';
import { ProposalStatusItem } from '../components/DropdownMenus';
import { getProposalStatus } from '../utils/formattingUtils';

const PROPOSALS_REFETCH_INTERVAL_MS = 10000;
const DEFAULT_SORTING_FIELD = 'updatedAt';
const DEFAULT_SORTING_DIRECTION = 'DESC';
const DEFAULT_PAGE_SIZE = 8;
const COLUMN_NAME_TO_DB_FIELD_MAP = new Map<string, string>([
  ['uid', 'uid'],
  ['name', 'name'],
  ['updatedAt', 'updated_at'],
  ['owner', 'owner__email'],
  ['processingStatus', 'processing_status'],
  ['status', 'status']
]);
const CLICKABLE_PROCESSING_STATUSES = ['COMPLETE', 'PREPROCESSING'];

const formatProposalData = (
  proposalData: Proposal[]
): ProposalRow[] => proposalData
  ?.map((proposal) => ({
    uid: proposal.uid ?? '',
    name: proposal.name ?? '',
    opportunityName: proposal.name ?? '',
    updatedAt: proposal?.updated_at != null ? new Date(proposal.updated_at) : null,
    owner: getProposalOwnerName(proposal),
    status: getProposalStatus(proposal),
    processingStatus: proposal.opportunity.processing_status as ProcessingStatusType ?? null,
    isClickable: CLICKABLE_PROCESSING_STATUSES.includes(proposal.opportunity.processing_status ?? ''),
    canEdit: hasPermissionsForObject(proposal, 'change'),
    canDelete: hasPermissionsForObject(proposal, 'delete')
  }));

interface ProposalTableConfig {
  organizationSlug?: string
  onRename?: (row: Row<ProposalRow>) => void
  onDelete?: (row: Row<ProposalRow>) => void
}

const useProposalTableData = (config: ProposalTableConfig) => {
  const { organizationSlug, onRename, onDelete } = config;

  const [refetchingEnabled, setRefetchingEnabled] = useState(false);

  const columnHelper = createColumnHelper<ProposalRow>();

  const menuItems = useCallback((row: Row<ProposalRow>) => {
    const itemsList = [];
    if (row.original.canEdit as boolean && onRename !== undefined) {
      itemsList.push({
        label: 'Rename Proposal',
        onClick: () => { onRename(row); },
        shouldRender: true,
        icon: <Pen />
      });
    }
    if (row.original.canDelete as boolean && onDelete !== undefined) {
      itemsList.push({
        label: 'Delete Proposal',
        onClick: () => { onDelete(row); },
        shouldRender: true,
        icon: <Trash />
      });
    }
    return itemsList;
  }, [onRename]);

  const columns = [
    columnHelper.accessor(row => `${row.name} ${row.opportunityName}`, {
      id: 'name',
      header: 'NAME',
      cell: info => (
        <>
          <Text fz='md' fw='600'>
            {info.row.original.name}
          </Text>
        </>
      ),
      sortingFn: 'text',
      size: 50
    }),
    columnHelper.accessor('status', {
      header: 'STATUS',
      cell: info => <ProposalStatusItem status={info.row.original.status} />,
      size: 15,
      maxSize: 15
    }),

    columnHelper.accessor('updatedAt', {
      header: 'LAST EDITED',
      cell: info => <Text> {getDateStringForTableCell(info.getValue())} </Text>,
      size: 15,
      maxSize: 15
    }),
    columnHelper.accessor('owner', {
      header: 'OWNER',
      cell: info => <Text> {info.getValue()} </Text>,
      size: 20
    }),
    columnHelper.display({
      id: 'actions',
      cell: info => {
        const items = menuItems(info.row);
        if (CLICKABLE_PROCESSING_STATUSES.includes(info.row.original.processingStatus ?? '') && items.length > 0) {
          return <ThreeDotsMenu orientation="horizontal" menuItems={items}/>;
        }
        return <></>;
      },
      size: 15
    })
  ];

  const paginatedTable = usePaginatedTable({
    defaultSortingField: DEFAULT_SORTING_FIELD,
    defaultSortDirection: DEFAULT_SORTING_DIRECTION,
    route: '/app/proposals',
    formatData: formatProposalData,
    columnNameToDBFieldMap: COLUMN_NAME_TO_DB_FIELD_MAP,
    columnData: columns,
    queryParams: {
      organization_slug: organizationSlug ?? '',
      exclude_failed_proposals: true
    },
    defaultPageSize: DEFAULT_PAGE_SIZE,
    queryConfig: {
      refetchInterval: refetchingEnabled ? PROPOSALS_REFETCH_INTERVAL_MS : undefined
    },
    tableConfig: {
      autoResetAll: false
    }
  });

  const shouldRefetch = paginatedTable.table.getRowModel().rows.some(
    (row) => row.original.processingStatus === 'PROCESSING'
  );
  if (refetchingEnabled !== shouldRefetch) {
    setRefetchingEnabled(shouldRefetch);
  }

  return paginatedTable;
};

export default useProposalTableData;
