import { useCallback, useState } from 'react';

import { LoadingOverlay, TextInput } from '@mantine/core';

import { useParams } from 'react-router-dom';

import useEnsisMutation from '../../../hooks/useEnsisMutation';
import ModalContent from '../../ModalContent';
import { validateEmail } from '../../../utils/stringUtils';

interface Props {
  close: () => void
}
const InviteUsers: React.FC<Props> = (props: Props) => {
  const { close } = props;
  const { organization } = useParams();
  const [emailAddresses, setEmailAddresses] = useState<string[]>(['']);
  const [hasErrors, setHasErrors] = useState(false);
  const sendInvitesMutation = useEnsisMutation(
    '/app/organization-invites',
    {
      successMessage: emailAddresses.length > 1 ? 'Invites sent' : 'Invite sent',
      queryKeysToInvalidate: ['/app/organization-invites'],
      onSuccess: close
    }
  );

  const onSubmit = useCallback(() => {
    if (emailAddresses.map((email) => validateEmail(email)).includes(false)) {
      setHasErrors(true);
    } else {
      setHasErrors(false);
      sendInvitesMutation.mutate({
        organization_slug: organization ?? '',
        invites: emailAddresses.map((currentEmail) => ({ email: currentEmail }))
      });
    }
  }, [sendInvitesMutation.mutate, emailAddresses]);
  const handleKeyDown = useCallback((event: any) => {
    if (event.key === 'Enter') {
      onSubmit();
    }
  }, [onSubmit]);
  return (
    <ModalContent
      title='Invite a team member'
      subtitle='Collaborate with your team in ensis'
      onClose={close}
      primaryButton={{
        label: 'Confirm',
        onClick: onSubmit
      }}
      secondaryButton={{
        label: 'Cancel',
        onClick: close
      }}
    >
      <LoadingOverlay visible={sendInvitesMutation.isPending} zIndex={1000} />
      <TextInput
        placeholder= 'Email, comma separated'
        w={540}
        onChange= {(event) => { setEmailAddresses(event.currentTarget.value.split(',')); }}
        value={emailAddresses}
        error={hasErrors && 'Please enter valid email(s) separated by commas'}
        onKeyDown={handleKeyDown}
      />
    </ ModalContent>
  );
};
export default InviteUsers;
