import { LoadingOverlay, TextInput } from '@mantine/core';

import { useCallback, useState } from 'react';

import ModalContent from '../ModalContent';
import useEnsisMutation from '../../hooks/useEnsisMutation';
import { isEmpty } from '../../utils/stringUtils';
import type { ProposalRow } from '../../types/tableTypes';

interface Props {
  proposal: ProposalRow
  close: () => void
}
const RenameProposal: React.FC<Props> = (props: Props) => {
  const { close, proposal } = props;
  const { uid, name } = proposal;
  const [proposalName, setProposalName] = useState(name ?? '');

  const updateProposalMutation = useEnsisMutation(
    `/app/proposals/${uid}/data`,
    {
      requestType: 'patch',
      successMessage: 'Proposal name updated',
      onSuccess: close,
      queryKeysToInvalidate: [`/app/proposals/${uid}/data`, '/app/proposals']
    }
  );

  const handleRenameProposal = useCallback(() => {
    updateProposalMutation.mutate({ name: proposalName });
  }, [proposalName]);

  return (
    <ModalContent
      title='Rename Proposal'
      onClose={close}
      primaryButton={{
        label: 'Rename',
        onClick: handleRenameProposal,
        disabled: isEmpty(proposalName) || proposalName === name
      }}
      secondaryButton={{ label: 'Cancel', onClick: close }}
    >
      <LoadingOverlay zIndex={1000} visible={updateProposalMutation.isPending}/>
      <TextInput
        value={proposalName}
        onChange={(event) => { setProposalName(event.currentTarget.value); }}
      />
    </ModalContent>
  );
};

export default RenameProposal;
