import { Text } from '@mantine/core';

import type { OpportunityFile, OpportunityRequirement } from '../types/apiTypes';

const getRequirementCounts = (
  opportunityFiles: OpportunityFile[],
  requirements: OpportunityRequirement[]
): Record<string, number> => {
  const requirementCounts: Record<string, number> = {};
  opportunityFiles.forEach((oppFile) => {
    const oppFileUid = oppFile?.uid ?? '';
    if (oppFileUid !== '') {
      requirementCounts[oppFileUid] = 0;
    }
  });
  requirements.forEach((req) => {
    const oppFileUid = req?.opportunity_file?.uid ?? '';
    if (oppFileUid !== '' && oppFileUid in requirementCounts) {
      requirementCounts[oppFileUid] += 1;
    }
  });
  return requirementCounts;
};

const _sortOpportunityFilesByUid = (opportunityFiles: OpportunityFile[]): OpportunityFile[] => (
  [...opportunityFiles].sort((a, b) => {
    const aUid = a.uid ?? '';
    const bUid = b.uid ?? '';
    if (aUid < bUid) {
      return -1;
    } else if (aUid > bUid) {
      return 1;
    }
    return 0;
  })
);

export const sortOpportunityFilesByRequirementCount = (
  opportunityFiles: OpportunityFile[],
  requirements: OpportunityRequirement[]
): OpportunityFile[] => {
  if (requirements.length > 0) {
    const requirementCounts = getRequirementCounts(opportunityFiles, requirements);
    return [...opportunityFiles].sort(
      (fileA, fileB) => requirementCounts[fileB.uid ?? ''] - requirementCounts[fileA.uid ?? '']
    );
  }
  return _sortOpportunityFilesByUid(opportunityFiles);
};

export const getRequirementIdentifierSpan = (requirementIdentifier: string) => (
  requirementIdentifier !== '' && (
    <Text span c='var(--mantine-color-dark-2)'>
      {`${requirementIdentifier} `}
    </Text>
  )
);
