import { useCallback, useEffect, useState } from 'react';
import { TextInput, Tooltip } from '@mantine/core';

import useEnsisQuery from '../../hooks/useEnsisQuery';
import CenteredLoader from '../CenteredLoader';
import useEnsisMutation from '../../hooks/useEnsisMutation';
import { truncateText } from '../../utils/stringUtils';

const NAME_MAX_CHARACTERS = 50;

interface Props {
  proposalUid: string
}

const ProposalNameTextInput: React.FC<Props> = ({ proposalUid }: Props) => {
  const [currentProposalName, setCurrentProposalName] = useState('');
  const [focused, setFocused] = useState(false);
  const { data, isLoading, isSuccess } = useEnsisQuery(`/app/proposals/${proposalUid}/data`);
  const updateProposalMutation = useEnsisMutation(
    `/app/proposals/${proposalUid}/data`,
    {
      requestType: 'patch',
      successMessage: 'Proposal name updated',
      queryKeysToInvalidate: [`/app/proposals/${proposalUid}/data`]
    }
  );

  useEffect(() => {
    setCurrentProposalName(data?.name ?? '');
  }, [isSuccess]);

  const onFocus = useCallback(() => { setFocused(true); }, []);

  const handleUpdateProposalName = useCallback(() => {
    if (currentProposalName !== data?.name) {
      updateProposalMutation.mutate({
        name: currentProposalName.trim()
      });
    }
    setFocused(false);
  }, [currentProposalName, data]);

  const handleKeyDown = useCallback((event: any) => {
    if (event.key === 'Enter') {
      handleUpdateProposalName();
    }
  }, [handleUpdateProposalName]);

  const nameText = focused ? currentProposalName : truncateText(currentProposalName, NAME_MAX_CHARACTERS);

  if (isLoading) {
    return <CenteredLoader />;
  }

  return (
    <Tooltip
      events={{
        hover: !focused,
        focus: false,
        touch: false
      }}
      position='bottom-start'
      label='Rename'
      arrowOffset={16}
      withArrow
    >
      <TextInput
        disabled={updateProposalMutation.isPending}
        variant={ focused ? undefined : 'unstyled'}
        onFocus={onFocus}
        onBlur={handleUpdateProposalName}
        value={nameText}
        onChange={(event) => { setCurrentProposalName(event.target.value); }}
        style={{ fontWeight: 'bold', width: 500 }}
        size="lg"
        onKeyDown={handleKeyDown}
      />
    </Tooltip>
  );
};

export default ProposalNameTextInput;
