/* eslint-disable max-len */
import React from 'react';

const Close: React.FC = () => (
<svg
  width="20"
  height="20"
  viewBox="0 0 20 20"
  fill="currentColor"
  xmlns="http://www.w3.org/2000/svg">
  <path d="M19.3332 2.5465L17.4532 0.666504L9.99984 8.11984L2.5465 0.666504L0.666504 2.5465L8.11984 9.99984L0.666504 17.4532L2.5465 19.3332L9.99984 11.8798L17.4532 19.3332L19.3332 17.4532L11.8798 9.99984L19.3332 2.5465Z"/>
</svg>
);

export default Close;
