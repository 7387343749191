/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/quotes */
import type { SVGProps } from "react";
const SvgCollapseLeft = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill="#343A40"
      stroke="#343A40"
      d="M11.92 19.213 4.707 12l7.212-7.212.705.71-5.148 5.148-.853.854H21.5v1H6.621l.856.854 5.156 5.146zM3.5 21.5h-1v-19h1v19Z"
    />
  </svg>
);
export default SvgCollapseLeft;
