import { Button, Group, TextInput } from '@mantine/core';

import { type OrganizationResourceTag } from '../../../types/apiTypes';
import { Trash } from '../../../icons';

interface Props {
  tag: OrganizationResourceTag
  handleTagChange: (tagText: string) => void
  handleTagDelete: () => void
}

const ManageOrganizationResourceTagsInput: React.FC<Props> = (props: Props) => {
  const { tag, handleTagChange, handleTagDelete } = props;

  return (
    <Group key={tag.uid} justify='space-between' mb={24}>
      <TextInput
        value={tag.text}
        style={{
          width: '90%'
        }}
        onChange={(event) => {
          handleTagChange(event.target.value);
        }}
      />
      <Button
        variant='outline'
        style={{
          border: 'none',
          padding: 0
        }}
        flex={1}
        onClick={handleTagDelete}
      >
        <Trash />
      </Button>
    </Group>
  );
};

export default ManageOrganizationResourceTagsInput;
