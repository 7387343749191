import { Box, Text } from '@mantine/core';
import { useRichTextEditorContext } from '@mantine/tiptap';

interface Props {
  setRenderEditor: (props: boolean) => void
  handleGenerateContent: () => void

}
const ResponseEditorPlaceholder: React.FC<Props> = (props: Props) => {
  const { setRenderEditor, handleGenerateContent } = props;
  const { editor } = useRichTextEditorContext();
  return (
  <Box
    onClick={() => { setRenderEditor(true); editor?.commands.focus(); }}
    bg='var(--mantine-color-lightPurple-0)'
    p={'17px 16px'}
    style={{ borderRadius: '4px' }}
  >
    <Text c='var(--mantine-color-gray-6)'>
      <Text
        span
        c='var(--mantine-color-gray-6)'
        style={{ borderBottom: '1.5px solid var(--mantine-color-gray-6' }}
      >
        {'Write content'}
      </Text>
      {' or '}
      <Text
        span
        c='var(--mantine-color-lightPurple-5)'
        style={{ borderBottom: '1.5px solid var(--mantine-color-lightPurple-5', cursor: 'pointer' }}
        onClick={handleGenerateContent}
      >
        {'generate with AI'}
      </Text>
    </Text>
  </Box>);
};

export default ResponseEditorPlaceholder;
