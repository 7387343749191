import { Box, Center, Text, Image, Title, Stack, Group } from '@mantine/core';

import { Notifications } from '@mantine/notifications';

import { useAuth0 } from '@auth0/auth0-react';

import Logo from '../assets/ensis-logo.svg';
import useEnsisMutation from '../hooks/useEnsisMutation';

interface Props {
  userEmail?: string
}

const EmailNotVerifiedPage: React.FC<Props> = (props: Props) => {
  const { logout } = useAuth0();

  const resendEmailVerification = useEnsisMutation('/app/users/resend-email-verification', {
    requestType: 'post',
    contentType: 'application/json',
    successMessage: 'Email sent'
  });

  return (
    <Box h='100vh'>
      <Notifications position={'top-center'} w={350}/>
      <Image src={Logo} w={160} />
      <Center>
      <Center h={254} mt='200px' w={900} p={16} style={{ borderRadius: 4 }} bg='var(--mantine-color-greyPurple-0)'>
        <Stack align="center">
          <Title order={4}>
            Almost there! Please verify your email
          </Title>
          <Text maw={555}>
            {`We sent an email to ${props.userEmail ?? 'you'}. Click the link to verify your account and continue to
            Ensis. Don't see the verification email? Please check your spam inbox, and contact info@ensis.ai if
            you're still having issues.`}
          </Text>
          <Group gap="md">
          <Text
            fz='sm'
            td='underline'
            style={{ cursor: 'pointer' }}
            onClick={() => {
              logout({
                logoutParams: { returnTo: window.location.origin }
              }).catch(() => {});
            }}
          >
            Return to login
          </Text>
          <Text
            fz='sm'
            td='underline'
            style={{ cursor: 'pointer' }}
            onClick={() => { resendEmailVerification.mutate({}); }}
          >
            Resend verification email
          </Text>
          </Group>
        </Stack>
      </Center>
      </Center>
    </Box>
  );
};

export default EmailNotVerifiedPage;
