import React, { type SVGProps } from 'react';

const ArrowDropdown: React.FC<Partial<SVGProps<SVGSVGElement>>> = (props: Partial<SVGProps<SVGSVGElement>>) => {
  const { width, height, color } = props;

  return (
        <svg
            width= {width ?? '24' }
            height={height ?? '24' }
            viewBox="0 0 24 24"
            fill={color ?? 'currentColor'}
            xmlns="http://www.w3.org/2000/svg"
        >
            <path d="M7 9.5L12 14.5L17 9.5H7Z"/>
        </svg>
  );
};

export default ArrowDropdown;
