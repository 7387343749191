/* eslint-disable max-len */
import React from 'react';

const FilterOutline: React.FC = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M8 12.5867C8.01206 12.6876 8.00091 12.7899 7.9674 12.8858C7.9339 12.9817 7.87892 13.0686 7.80666 13.14C7.68175 13.2642 7.51279 13.3339 7.33666 13.3339C7.16054 13.3339 6.99157 13.2642 6.86666 13.14L4.2 10.4734C4.12559 10.4019 4.06881 10.3141 4.0341 10.2169C3.99939 10.1197 3.98772 10.0158 4 9.91337V6.50004L0.806663 2.41337C0.698401 2.27439 0.649552 2.09821 0.670788 1.92333C0.692024 1.74844 0.781619 1.58907 0.919996 1.48004C1.03729 1.38593 1.18295 1.33425 1.33333 1.33337H10.6667C10.817 1.33425 10.9627 1.38593 11.08 1.48004C11.2184 1.58907 11.308 1.74844 11.3292 1.92333C11.3504 2.09821 11.3016 2.27439 11.1933 2.41337L8 6.50004V12.5867ZM2.66666 2.66671L5.33333 6.04004V9.72004L6.66666 11.0534V6.03337L9.33333 2.66671M8.66666 10.6667L12 14L15.3333 10.6667H8.66666Z" fill="#343A40"/>
  </svg>

);

export default FilterOutline;
