import { useCallback, useState } from 'react';
import { Text, NumberInput, Anchor, Box, Title } from '@mantine/core';
import { useParams, Link } from 'react-router-dom';

import CenteredLoader from '../CenteredLoader';
import ModalContent from '../ModalContent';
import { getTotalWords } from '../../utils/formattingUtils';
import { getSpacingString } from '../../utils/stringUtils';
import { getProposalManagementRoute } from '../../utils/routerUtils';

interface Props {
  close: () => void
  onGenerateNarrative: (numberOfPages?: number) => Promise<void>
  defaultPageCount: number
  proposalSpacing: number
};

const MAX_NUM_PAGES = 20;
const GenerateNarrativeLoadingState: React.FC = () => (
  <Box ta='center' p={24}>
    <Title order={5} c='var(--mantine-color-darkPurple-5)'> Generating narrative </Title>
    <Text c='var(--mantine-color-darkPurple-5)'> This could take up to a minute. </Text>
    <CenteredLoader m={36} h={150} loaderProps={{ size: 150 }}/>
  </Box>
);

const GenerateNarrative: React.FC<Props> = (props: Props) => {
  const {
    close,
    onGenerateNarrative,
    proposalSpacing,
    defaultPageCount
  } = props;

  const { organization, proposalUid } = useParams();
  const defaultPages = defaultPageCount > 0 ? defaultPageCount : undefined;
  const [numberOfPages, setNumberOfPages] = useState<number | string | undefined>(defaultPages);
  const [loading, setLoading] = useState(false);

  const numberOfPagesIsEmpty = numberOfPages === undefined || numberOfPages === '';
  const numberOfPagesParsed = numberOfPagesIsEmpty ? undefined : Number(numberOfPages);
  const handleGenerateNarrative = useCallback(async () => {
    setLoading(true);
    try {
      await onGenerateNarrative(numberOfPagesParsed);
    } catch {
      setLoading(false);
    }
    close();
  }, [onGenerateNarrative, numberOfPagesParsed, numberOfPagesIsEmpty]);

  const proposalManagementLink = getProposalManagementRoute(organization, proposalUid);
  const proposalSpacingString = getSpacingString(proposalSpacing);

  const isOverPageLimit = (numberOfPagesParsed ?? 0) > MAX_NUM_PAGES;
  const pageLimitErrorText = `Cannot generate more than ${MAX_NUM_PAGES} pages`;

  if (loading) {
    return <GenerateNarrativeLoadingState />;
  }

  return (
    <ModalContent
      title="Set your page limit"
      primaryButton={{
        label: 'Generate',
        onClick: () => { void handleGenerateNarrative(); },
        color: 'red',
        disabled: isOverPageLimit
      }}
      secondaryButton={{
        label: 'Cancel',
        onClick: close,
        color: 'red'
      }}
    >
      <Text fz="sm" mb={32}>
        {'Enter the number of pages you\'d like this section to be. You can leave ' +
        'this blank if you have no preference. Page formatting options can ' +
        'be found under '}
        <Anchor fz="sm" component={Link} to={proposalManagementLink} underline="always">
          Proposal Management
        </Anchor>
      </Text>
      <NumberInput
        mb={8}
        w={158}
        radius={4}
        label="Number of Pages"
        placeholder="Enter number"
        value={numberOfPages}
        onChange={(value) => { setNumberOfPages(value); }}
        decimalScale={2}
        min={1}
        max={MAX_NUM_PAGES}
        error={isOverPageLimit && pageLimitErrorText}
        allowDecimal
      />
      {
        !numberOfPagesIsEmpty && (
          <Text fz="sm" c="var(--mantine-color-gray-6)">
            {`@ ${proposalSpacingString} Spacing.`}
            <br />
            {`Approximately ${getTotalWords(proposalSpacing, numberOfPagesParsed ?? 0)} words`}
          </Text>
        )
      }
    </ModalContent>
  );
};

export default GenerateNarrative;
