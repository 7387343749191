import { LoadingOverlay } from '@mantine/core';

import { useCallback } from 'react';

import ModalContent from '../ModalContent';
import useEnsisMutation from '../../hooks/useEnsisMutation';

interface Props {
  onClose: () => void
  opportunityFileUid: string
  opportunityFileName: string
}

const DeleteOpportunityFile: React.FC<Props> = (props: Props) => {
  const { onClose, opportunityFileName, opportunityFileUid } = props;

  const deleteOpportunityFileMutation = useEnsisMutation(
    `/app/opportunity-files/${opportunityFileUid}/data`,
    {
      requestType: 'delete',
      successMessage: 'Opportunity File deleted',
      queryKeysToInvalidate: ['/app/opportunity-files'],
      onSuccess: onClose
    }
  );

  const handleDeleteOpportunityFile = useCallback(() => {
    deleteOpportunityFileMutation.mutate({});
  }, [deleteOpportunityFileMutation.mutate]);

  return (
    <ModalContent
      title={`Are you sure you want to delete the file ${opportunityFileName}?`}
      secondaryButton={{
        label: 'Cancel',
        onClick: onClose
      }}
      primaryButton={{
        label: 'Delete',
        onClick: handleDeleteOpportunityFile,
        disabled: deleteOpportunityFileMutation.isPending
      }}
      onClose={onClose}
    >
      <LoadingOverlay visible={deleteOpportunityFileMutation.isPending} zIndex={1000} />
    </ModalContent>
  );
};

export default DeleteOpportunityFile;
