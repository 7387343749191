/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/quotes */
import type { SVGProps } from "react";
const SvgExpandRight = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill="#343A40"
      stroke="#343A40"
      d="M14.081 4.788 21.293 12l-7.212 7.212-.705-.71 5.148-5.148.853-.854H3.5v9h-1v-19h1v9h15.877l-.853-.854L13.376 5.5z"
    />
  </svg>
);
export default SvgExpandRight;
