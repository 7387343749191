import { Box, Divider, LoadingOverlay, Text } from '@mantine/core';

import { useCallback } from 'react';

import { useDispatch } from 'react-redux';

import ModalContent from '../ModalContent';
import useEnsisMutation from '../../hooks/useEnsisMutation';
import { deleteRequirements } from '../../redux/WizardSlice';
import type { OpportunityRequirement } from '../../types/apiTypes';

interface Props {
  close: () => void
  requirements: OpportunityRequirement[]
  opportunityUid: string
  setCurrentlyChecked: (uids: string[]) => void
}

const DeleteRequirements: React.FC<Props> = (props: Props) => {
  const { close, requirements, setCurrentlyChecked, opportunityUid } = props;

  const bulkDeleteRequirementsMutation = useEnsisMutation('/app/requirements', {
    requestType: 'delete',
    contentType: 'application/json',
    successMessage: 'Requirements successfully deleted',
    queryKeysToInvalidate: [`/app/opportunities/${opportunityUid}/requirements`],
    awaitRefetch: false
  });

  const dispatch = useDispatch();

  const handleBulkDeleteRequirements = useCallback(() => {
    const requirementUids = requirements.map((req) => req.uid);
    bulkDeleteRequirementsMutation.mutate({
      requirement_uids: requirementUids,
      opportunity_uid: opportunityUid
    }, {
      onSuccess: () => {
        dispatch(deleteRequirements({ requirementUids }));
        setCurrentlyChecked([]);
        close();
      }
    });
  }, [requirements, bulkDeleteRequirementsMutation]);

  return (
    <ModalContent
      title='Delete Requirements'
      subtitle='The following requirements will be deleted:'
      secondaryButton={{
        label: 'Cancel',
        color: 'lightPurple',
        onClick: close
      }}
      primaryButton={{
        label: 'Delete',
        color: 'lightPurple',
        onClick: handleBulkDeleteRequirements,
        disabled: bulkDeleteRequirementsMutation.isPending
      }}
      onClose={close}
    >
      <LoadingOverlay visible={bulkDeleteRequirementsMutation.isPending} zIndex={1000} />
      <Text>{requirements[0]?.text}</Text>
      {
        requirements.slice(1).map((requirement) => (
          <Box key={requirement.uid}>
            <Divider mb={16} mt={16} />
            <Text>{requirement.text}</Text>
          </Box>
        ))
      }
    </ModalContent>
  );
};

export default DeleteRequirements;
