import { forwardRef, useCallback, useImperativeHandle, useRef, useMemo } from 'react';
import { Box, Button, Text, type MantineStyleProp } from '@mantine/core';

import { type DocumentLoadEvent } from '@react-pdf-viewer/core';

import OpportunityFileViewer from './OpportunityFileViewer';
import type { OpportunityFileViewerRef } from './OpportunityFileViewer';
import type { OpportunityFile, OpportunityRequirement } from '../../types/apiTypes';

export interface RequirementMatchData {
  requirementUid: string
  pageNumber: number
}

interface OpportunityDocumentsViewerProps {
  requirements: OpportunityRequirement[]
  opportunityFiles: OpportunityFile[]
  currentFileIndex: number
  onClickFile: (fileIndex: number) => void
  focusedRequirementUid?: string
  onAddRequirement?: (requirementText: string, opportunityFileUid?: string) => void
  onClickHighlightedRequirement?: (requirementUid: string) => void
  onGetMatches?: (matches: RequirementMatchData[]) => void
  style?: MantineStyleProp
  onStartDocumentLoad?: () => void
  onFinishDocumentLoad?: (e?: DocumentLoadEvent) => void
}

export interface OpportunityDocumentsViewerRef {
  jumpToPage: (pageIndex: number) => void
}

const OpportunityDocumentsViewer = forwardRef<
OpportunityDocumentsViewerRef,
OpportunityDocumentsViewerProps
>((
  props: OpportunityDocumentsViewerProps,
  ref
) => {
  const {
    requirements,
    opportunityFiles,
    focusedRequirementUid,
    onClickHighlightedRequirement,
    onGetMatches,
    onAddRequirement,
    currentFileIndex,
    onClickFile,
    style,
    onStartDocumentLoad,
    onFinishDocumentLoad
  } = props;

  const fileViewerRefs = useRef<Array<OpportunityFileViewerRef | null>>(
    new Array<OpportunityFileViewerRef | null>(opportunityFiles.length)
  );

  useImperativeHandle(ref, () => ({
    jumpToPage: (pageIndex: number) => {
      fileViewerRefs?.current[currentFileIndex]?.jumpToPage(pageIndex);
    }
  }));

  const getFileTabs = useCallback(() => (
    opportunityFiles.map((opportunityFile, index) => (
      <Button
        key={opportunityFile.uid}
        style={{
          flex: 1,
          borderRadius: 0,
          backgroundColor: index === currentFileIndex
            ? 'var(--mantine-color-greyPurple-1)'
            : 'var(--mantine-color-greyPurple-3)'
        }}
        onClick={() => { onClickFile(index); }}
      >
        <Text
          truncate='end'
          c={index === currentFileIndex ? 'black' : 'white'}
        >
          {opportunityFile?.file_name}
        </Text>
      </Button>
    ))
  ), [opportunityFiles, currentFileIndex, onClickFile]);

  const selectedOpportunityFile = opportunityFiles[currentFileIndex];

  const currentFileRequirements = useMemo(() => (
    requirements.filter(
      (req) => req?.opportunity_file?.uid === selectedOpportunityFile?.uid
    )
  ), [selectedOpportunityFile, requirements]);

  const viewers = opportunityFiles.map((file, index) => (
    <OpportunityFileViewer
      key={file.uid}
      ref={(el) => { fileViewerRefs.current[index] = el; }}
      requirements={currentFileRequirements}
      opportunityFile={file}
      focusedRequirementUid={focusedRequirementUid ?? ''}
      onAddRequirement={onAddRequirement}
      onClickHighlightedRequirement={onClickHighlightedRequirement}
      onGetMatches={onGetMatches}
      onStartDocumentLoad={onStartDocumentLoad}
      onFinishDocumentLoad={onFinishDocumentLoad}
    />
  ));

  return (
    <Box style={style}>
      <Box style={{ display: 'flex', flexDirection: 'row' }}>
        {getFileTabs()}
      </Box>
      { viewers[currentFileIndex] }
    </Box>
  );
});

OpportunityDocumentsViewer.displayName = 'OpportunityDocumentsViewer';

export default OpportunityDocumentsViewer;
