import { notifications } from '@mantine/notifications';

export const DEFAULT_SUCCESS_MESSAGE = 'Success';
export const DEFAULT_FAILURE_MESSAGE = 'Something went wrong, please refresh and try again.';

export const showSuccessNotification = (message?: string | React.ReactNode) => notifications.show({
  message: message ?? DEFAULT_SUCCESS_MESSAGE,
  bg: 'var(--mantine-color-green-1)',
  color: 'var(--mantine-color-green-1)',
  radius: 4
});

export const showFailureNotification = (message?: string) => notifications.show({
  message: message ?? DEFAULT_FAILURE_MESSAGE,
  bg: 'var(--mantine-color-red-1)',
  color: 'var(--mantine-color-red-1)',
  radius: 4
});

export const showWarningNotification = (message?: string) => notifications.show({
  message: message ?? DEFAULT_FAILURE_MESSAGE,
  bg: 'var(--mantine-color-yellow-1)',
  color: 'var(--mantine-color-yellow-1)',
  radius: 4,
  autoClose: 10000,
  style: { width: 400 }
});

export const defaultModalProps = {
  withCloseButton: false,
  onClose: () => '',
  size: 600
};
