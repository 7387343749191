import React, { useCallback, useState } from 'react';

import { Box, LoadingOverlay, ScrollArea } from '@mantine/core';

import ModalContent from '../../ModalContent';
import useEnsisQuery from '../../../hooks/useEnsisQuery';
import { type OrganizationResourceTag, type GetResponseDataType } from '../../../types/apiTypes';
import useEnsisMutation from '../../../hooks/useEnsisMutation';
import ManageOrganizationResourceTagsInput from './ManageOrganizationResourceTagsInput';
import { showSuccessNotification } from '../../../utils/mantineUtils';
import { AddTagTextInput } from '../../ResourceTableTagsContainer';
import { tagMatchesCaseInsensitive } from '../../../utils/stringUtils';

interface Props {
  onClose: () => void
  organizationSlug: string
}

const NEW_TAG_UID = 'newTag';
const TAG_UID_TO_DELETE = 'deleteTag';

const ManageOrganizationResourceTags: React.FC<Props> = (props: Props) => {
  const { onClose, organizationSlug } = props;

  const [tags, setTags] = useState<OrganizationResourceTag[]>([]);
  const [newTagText, setNewTagText] = useState('');
  const [mutationsLoading, setMutationsLoading] = useState(false);

  const tagAlreadyExists = tagMatchesCaseInsensitive(tags, newTagText);
  const newTags = tags.filter((tag) => tag.uid === NEW_TAG_UID);
  const updatedTags = tags.filter((tag) => tag.uid !== NEW_TAG_UID && tag.uid !== TAG_UID_TO_DELETE);
  const deletedTags = tags.filter((tag) => tag.uid === TAG_UID_TO_DELETE);

  const onQuerySuccess = useCallback(
    (data: GetResponseDataType<'/app/organization-resource-tags'>) => {
      setTags(data.items);
    },
    []
  );

  const { data, isLoading } = useEnsisQuery(
    '/app/organization-resource-tags',
    {
      queryParams: {
        organization_slug: organizationSlug ?? ''
      },
      onSuccess: onQuerySuccess
    }
  );

  const createResourceTagsMutation = useEnsisMutation(
    '/app/organization-resource-tags',
    {
      requestType: 'post',
      showSuccessMessage: false,
      queryKeysToInvalidate: ['/app/organization-resource-tags', '/app/organization-resources'],
      awaitRefetch: true
    }
  );

  const updateResourceTagsMutation = useEnsisMutation(
    '/app/organization-resource-tags',
    {
      requestType: 'patch',
      showSuccessMessage: false,
      queryKeysToInvalidate: ['/app/organization-resource-tags', '/app/organization-resources'],
      awaitRefetch: true
    }
  );

  const deleteTagsMutation = useEnsisMutation(
    '/app/organization-resource-tags',
    {
      requestType: 'delete',
      showSuccessMessage: false,
      queryKeysToInvalidate: ['/app/organization-resource-tags', '/app/organization-resources'],
      awaitRefetch: true
    }
  );

  const getDefaultNewTag = (tagText: string) => ({
    uid: NEW_TAG_UID,
    text: tagText,
    created_at: undefined,
    updated_at: undefined,
    permissions: []
  });

  const handleAddTag = useCallback(() => {
    if (!tagAlreadyExists) {
      setTags([getDefaultNewTag(newTagText), ...tags]);
      setNewTagText('');
    }
  }, [tagAlreadyExists, newTagText, tags]);

  const hasUpdatedOrCreatedTags = tags.some(
    (tag) => data?.items.find((item) => item.text === tag.text) === undefined
  );

  const hasDeletedTags = tags.some(
    (tag) => tag.uid === TAG_UID_TO_DELETE
  );

  const hasChanges = hasUpdatedOrCreatedTags || hasDeletedTags;

  const handleUpdateTags = useCallback(async () => {
    const mutations = [];
    if (newTags.length > 0) {
      mutations.push(
        createResourceTagsMutation.mutateAsync({
          organization_slug: organizationSlug,
          texts: newTags.map((tag) => tag.text ?? '')
        })
      );
    }

    if (deletedTags.length > 0) {
      mutations.push(
        deleteTagsMutation.mutateAsync({
          organization_slug: organizationSlug,
          tag_uids: deletedTags.map(
            (tag) => data?.items.find((t) => t.text === (tag.text ?? ''))?.uid ?? ''
          )
        })
      );
    }

    if (updatedTags.length > 0) {
      mutations.push(
        updateResourceTagsMutation.mutateAsync({
          organization_slug: organizationSlug,
          edits: updatedTags.map((tag) => ({ tag_uid: tag.uid ?? '', new_text: tag.text ?? '' }))
        })
      );
    }

    try {
      setMutationsLoading(true);
      await Promise.all(mutations);
      showSuccessNotification('Tags updated');
      onClose();
    } catch {
      setMutationsLoading(false);
    }
  }, [updateResourceTagsMutation.mutate, createResourceTagsMutation.mutate, deleteTagsMutation.mutate, tags]);

  const handleTagChange = (tagText: string, tag: OrganizationResourceTag) => {
    setTags(
      tags.map((t) => {
        if (t.text === tag.text) {
          return {
            ...t,
            text: tagText
          };
        } else {
          return t;
        }
      })
    );
  };

  const handleTagDelete = (tag: OrganizationResourceTag) => {
    if (tag.uid === NEW_TAG_UID || tag.uid === TAG_UID_TO_DELETE) {
      setTags(tags.filter((t) => t.text !== tag.text));
      return;
    }
    setTags(
      tags.map((t) => {
        if (t.text === tag.text) {
          return {
            ...t,
            uid: TAG_UID_TO_DELETE
          };
        } else {
          return t;
        }
      })
    );
  };

  return (
    <ModalContent
      title='Manage Tags'
      subtitle='Add, remove, or rename tags'
      primaryButton={{
        label: 'Save',
        disabled: !hasChanges || mutationsLoading,
        onClick: () => { void handleUpdateTags(); }
      }}
      secondaryButton={{ label: 'Cancel', onClick: onClose }}
    >
      <LoadingOverlay zIndex={1000} visible={isLoading || mutationsLoading} />
        <Box mb={24} w='90%'>
          <AddTagTextInput
            newTagText={newTagText}
            setNewTagText={setNewTagText}
            handleAddTag={handleAddTag}
            tagAlreadyExists={tagAlreadyExists}
          />
        </Box>
        <ScrollArea.Autosize mah={400}>
          {tags
            .filter((tag) => tag.uid !== TAG_UID_TO_DELETE)
            .map((tag, i) => {
              return (
                <ManageOrganizationResourceTagsInput
                  key={`${tag.uid}${i}`}
                  tag={tag}
                  handleTagChange={(tagText) => { handleTagChange(tagText, tag); }}
                  handleTagDelete={() => { handleTagDelete(tag); }}
                />
              );
            })}
        </ScrollArea.Autosize>
    </ModalContent>
  );
};

export default ManageOrganizationResourceTags;
