import ReactGA from 'react-ga4';

enum AnalyticsEventCategory {
  Proposals = 'PROPOSALS',
  Resources = 'RESOURCES',
  Organization = 'ORGANIZATION'
};

const ANALYTICS_EVENTS = {
  CreateProposal: {
    action: 'CREATE_PROPOSAL',
    category: AnalyticsEventCategory.Proposals
  },
  ResourcesSelected: {
    action: 'RESOURCES_SELECTED',
    category: AnalyticsEventCategory.Proposals
  },
  ExportProposalNarrative: {
    action: 'EXPORT_PROPOSAL_NARRATIVE',
    category: AnalyticsEventCategory.Proposals
  },
  ExportProposalOutline: {
    action: 'EXPORT_PROPOSAL_OUTLINE',
    category: AnalyticsEventCategory.Proposals
  },
  GenerateAllResponses: {
    action: 'GENERATE_ALL_RESPONSES',
    category: AnalyticsEventCategory.Proposals
  },
  GenerateResponse: {
    action: 'GENERATE_RESPONSE',
    category: AnalyticsEventCategory.Proposals
  },
  AcceptResponse: {
    action: 'ACCEPT_RESPONSE',
    category: AnalyticsEventCategory.Proposals
  },
  GenerateNarrative: {
    action: 'GENERATE_NARRATIVE',
    category: AnalyticsEventCategory.Proposals
  },
  CreateBlankNarrative: {
    action: 'CREATE_BLANK_NARRATIVE',
    category: AnalyticsEventCategory.Proposals
  },
  CreateProposalFact: {
    action: 'CREATE_PROPOSAL_FACT',
    category: AnalyticsEventCategory.Proposals
  },
  Regenerate: {
    action: 'REGENERATE',
    category: AnalyticsEventCategory.Proposals
  },
  AcceptRegenerate: {
    action: 'ACCEPT_REGENERATE',
    category: AnalyticsEventCategory.Proposals
  },
  AddResource: {
    action: 'ADD_RESOURCE',
    category: AnalyticsEventCategory.Resources
  },
  CreateTag: {
    action: 'CREATE_TAG',
    category: AnalyticsEventCategory.Resources
  },
  AddTag: {
    action: 'ADD_TAG',
    category: AnalyticsEventCategory.Resources
  },
  CreateOrganizationFact: {
    action: 'CREATE_ORGANIZATION_FACT',
    category: AnalyticsEventCategory.Organization
  }
} as const;

export type AnalyticsEventName = keyof typeof ANALYTICS_EVENTS;

export const logAnalyticsEvent = (eventName: AnalyticsEventName) => {
  ReactGA.event(ANALYTICS_EVENTS[eventName]);
};

export function withAnalyticsEvent<TParams, TReturn> (
  fn: (...args: TParams[]) => TReturn,
  eventName: AnalyticsEventName
): (...args: TParams[]) => TReturn {
  return (...args: TParams[]): TReturn => {
    logAnalyticsEvent(eventName);
    return fn(...args);
  };
}
