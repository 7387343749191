import { Box, Loader, Text, LoadingOverlay, Modal } from '@mantine/core';
import { useCallback, useMemo, useState, useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { useDisclosure } from '@mantine/hooks';
import { useNavigate, useParams } from 'react-router-dom';

import ReviewRequirements from './ReviewRequirements';
import SelectResources from './SelectResources';
import useEnsisMutation from '../../hooks/useEnsisMutation';
import useEnsisQuery from '../../hooks/useEnsisQuery';
import { CenteredLoader } from '../../components';
import ReviewSections from './ReviewSections';
import ReviewFullOutline from './ReviewFullOutline';
import WizardOpportunityDocumentsViewer from './WizardOpportunityDocumentsViewer';
import { formatSectionsMetadata } from '../../utils/apiUtils';
import { selectWizardState } from '../../redux/store';
import { AddRequirement, AddRequirementWithoutResponse } from '../../components/Modals';
import {
  sortOpportunityFilesByRequirementCount
} from '../../utils/requirementUtils';
import { defaultModalProps, showFailureNotification } from '../../utils/mantineUtils';
import {
  addRequirement,
  selectRequirement,
  addAllRequirements,
  addAllOpportunityFiles,
  selectOpportunityFile,
  resetWizardState
} from '../../redux/WizardSlice';
import type { OpportunityRequirement } from '../../types/apiTypes';
import { logAnalyticsEvent } from '../../utils/analyticsUtils';
import SelectRequirementPages from './SelectRequirementPages';
import ProcessingScreen from './ProcessingScreen';
import { ProposalStatusType } from '../../utils/constants';

const DATA_REFETCH_INTERVAL_MS = 10000;
const STEPS_WITHOUT_DOCUMENT_VIEWER = [1, 5];
const REQUIREMENTS_REVIEW_STEP = 2;
const SECTIONS_REVIEW_STEP = 3;

export const ProposalWizard: React.FC = () => {
  const { proposalUid, organization: organizationSlug } = useParams();
  const [loading, setLoading] = useState(false);
  const [currentStep, setCurrentStep] = useState(0);
  const [refetchingEnabled, setRefetchingEnabled] = useState(false);
  const [outlineGeneratedFromOpportunity, setOutlineGeneratedFromOpportunity] = useState(false);
  const {
    opportunityFiles
  } = useSelector(selectWizardState);
  const navigate = useNavigate();

  const { data: proposal, isLoading: proposalLoading } = useEnsisQuery(
    `/app/proposals/${proposalUid}/data`,
    {
      refetchInterval: refetchingEnabled ? DATA_REFETCH_INTERVAL_MS : undefined,
      staleTime: 0
    }
  );

  const { data: requirementResponseData, isLoading: requirementResponseLoading } = useEnsisQuery(
    '/app/requirement-responses',
    {
      queryParams: { proposal_uid: proposalUid },
      refetchInterval: refetchingEnabled ? DATA_REFETCH_INTERVAL_MS : undefined
    }
  );

  const sections = useMemo(
    () => (formatSectionsMetadata(proposal?.sections) ?? []),
    [proposal]
  );

  const dispatch = useDispatch();

  const {
    data: requirementsQuery,
    isLoading: requirementsLoading,
    isSuccess: requirementsQuerySuccess
  } = useEnsisQuery(
    `/app/opportunities/${proposal?.opportunity?.uid}/requirements`,
    {
      refetchInterval: refetchingEnabled ? DATA_REFETCH_INTERVAL_MS : undefined,
      enabled: proposal !== undefined && currentStep >= REQUIREMENTS_REVIEW_STEP
    }
  );

  const sortRequirementsMutation = useEnsisMutation(
    `/app/proposals/${proposalUid}/sort-requirements`,
    {
      showSuccessMessage: false,
      queryKeysToInvalidate: [`/app/proposals/${proposalUid}/data`, '/app/requirement-responses']
    }
  );

  const editProposalMutation = useEnsisMutation(
    `/app/proposals/${proposalUid}/data`,
    {
      showSuccessMessage: false,
      requestType: 'patch',
      queryKeysToInvalidate: [`/app/proposals/${proposalUid}/data`]
    }
  );

  const generateOutlineMutation = useEnsisMutation(`/app/proposals/${proposalUid}/generate-outline`, {
    showSuccessMessage: false,
    queryKeysToInvalidate: [`/app/proposals/${proposalUid}/data`, '/app/requirement-responses'],
    onSuccess: (data) => {
      setOutlineGeneratedFromOpportunity(data.sourced_from_opportunity);
    }
  });
  const extractRequirementsMutation = useEnsisMutation(
    `/app/opportunities/${proposal?.opportunity?.uid ?? ''}/extract-requirements`, {
      showSuccessMessage: false,
      queryKeysToInvalidate: [`/app/proposals/${proposalUid}/data`, '/app/requirement-responses']
    }
  );

  const [defaultRequirementText, setDefaultRequirementText] = useState('');

  const [opportunityFileToAddTo, setOpportunityFileToAddTo] = useState<string | undefined>(undefined);

  const {
    data: opportunityFilesQuery,
    isLoading: opportunityFilesLoading,
    isSuccess: opportunityFilesSuccess
  } = useEnsisQuery(
    '/app/opportunity-files',
    {
      queryParams: { opportunity_uid: proposal?.opportunity?.uid ?? '', generate_download_url: true }
    }
  );
  const [addRequirementOpened, addRequirementHandlers] = useDisclosure();

  const requirements = useMemo(() => requirementsQuery?.items ?? [], [requirementsQuery]);
  const opportunityFilesData = useMemo(() => opportunityFilesQuery?.items ?? [], [opportunityFilesQuery]);

  const isLoading = proposalLoading ||
  requirementResponseLoading ||
  requirementsLoading ||
  opportunityFilesLoading ||
  proposal === undefined ||
  opportunityFiles === undefined;

  const sortAndPopulateOpportunityFiles = useCallback(() => {
    const sortedOppFiles = sortOpportunityFilesByRequirementCount(
      opportunityFilesData,
      requirements
    );
    dispatch(addAllOpportunityFiles(sortedOppFiles));
  }, [opportunityFilesData, requirements]);

  // Reset redux state on mount
  useEffect(() => {
    dispatch(resetWizardState());
  }, []);

  useEffect(() => {
    if (requirementsQuerySuccess) {
      dispatch(addAllRequirements(requirements));
      sortAndPopulateOpportunityFiles();
    }
  }, [requirementsQuerySuccess]);

  useEffect(() => {
    if (opportunityFilesSuccess && opportunityFilesData.length > 0) {
      sortAndPopulateOpportunityFiles();
    }
  }, [opportunityFilesSuccess]);

  useEffect(() => {
    if (proposal?.opportunity?.processing_status === 'FAILED') {
      showFailureNotification('Proposal processing failed. Check your email for more information.');
      navigate(`/org/${organizationSlug}/proposals`);
    }
    if (proposal?.opportunity?.processing_status === 'PROCESSING') {
      setCurrentStep(1);
    } else if (proposal?.opportunity?.processing_status === 'COMPLETE') {
      setCurrentStep(2);
    }
  }, [proposal?.opportunity?.processing_status]);

  const handleFinishSelectPageRanges = useCallback(() => {
    setCurrentStep(1);
    extractRequirementsMutation.mutate({});
    dispatch(selectOpportunityFile({ opportunityFileIndex: 0 }));
  }, [extractRequirementsMutation]);

  const handleAddRequirement = useCallback((requirementText: string, opportunityFileUid?: string) => {
    setDefaultRequirementText(requirementText);
    setOpportunityFileToAddTo(opportunityFileUid);
    addRequirementHandlers.open();
  }, [addRequirementHandlers]);

  const handleClickRequirement = useCallback((requirementUid: string) => {
    dispatch(selectRequirement({ requirementUid }));
  }, []);

  const handleAddRequirementComplete = useCallback((requirement: OpportunityRequirement) => {
    dispatch(addRequirement({ requirement }));
    dispatch(selectRequirement({ requirementUid: requirement.uid }));
  }, []);

  const handleOnAddRequirementResponse = useCallback((requirementUid: string) => {
    dispatch(selectRequirement({ requirementUid }));
  }, []);

  const handleFinishRequirementReview = useCallback(async () => {
    if (sections.length === 0) {
      setLoading(true);
      await generateOutlineMutation.mutateAsync([]);
      setLoading(false);
    };
    setCurrentStep(3);
    dispatch(selectRequirement({ requirementUid: '' }));
  }, [generateOutlineMutation, sections]);

  const handleWizardDone = useCallback(async () => {
    setLoading(true);
    await editProposalMutation.mutateAsync({ status: ProposalStatusType.AUTHORING }, {
      onSuccess: () => { navigate(`/org/${organizationSlug}/proposals/${proposalUid}`); }
    });
    setLoading(false);
  }, [editProposalMutation, navigate, organizationSlug, proposalUid]);

  const handleFinishSectionReview = useCallback(async () => {
    setLoading(true);
    await sortRequirementsMutation.mutateAsync({});
    setCurrentStep(4);
    setLoading(false);
  }, [sortRequirementsMutation]);

  const handleFinishFullOutlineReview = useCallback(() => {
    setCurrentStep(5);
  }, []);

  const shouldRefetch = proposal?.opportunity?.processing_status === 'PROCESSING';
  if (refetchingEnabled !== shouldRefetch) {
    setRefetchingEnabled(shouldRefetch);
  }

  if (isLoading) {
    return <CenteredLoader style={{ height: '100vh', width: '100%' }} />;
  }

  const steps: React.ReactElement[] = [
    <SelectRequirementPages
      onDone={handleFinishSelectPageRanges}
      key="select-requirement-pages"
    />,
    <ProcessingScreen key='processing-screen'/>,
    <ReviewRequirements
      handleClickRequirement={handleClickRequirement}
      handleAddRequirement={handleAddRequirement}
      key="review-requirements"
      onClickNext={() => { void handleFinishRequirementReview(); }}
      proposal={proposal}
    />,
    <ReviewSections
      sections={sections}
      key="review-sections"
      showCopyForGeneratedSections={outlineGeneratedFromOpportunity}
      onClickNext={() => { void handleFinishSectionReview(); }}
      onClickPrevious={() => { setCurrentStep(2); }}
    />,
    <ReviewFullOutline
      handleClickRequirement={handleClickRequirement}
      sections={sections}
      opportunityUid={proposal?.opportunity?.uid}
      requirementResponses={requirementResponseData?.items ?? []}
      key="review-full-outline"
      onClickNext={handleFinishFullOutlineReview}
      onClickPrevious={() => { setCurrentStep(3); dispatch(selectRequirement({ requirementUid: '' })); }}
    />,
    <SelectResources
      key="select-resources"
      onClickNext={() => {
        logAnalyticsEvent('ResourcesSelected');
        void handleWizardDone();
      }}
      organizationSlug={organizationSlug ?? ''}
      proposal={proposal}
      onClickPrevious={() => { setCurrentStep(4); }}
    />
  ];

  const loader = (
    <Box>
      <Loader />
      {sortRequirementsMutation.isPending &&
      <Text w={400}>
        {'Ensis is sorting your requirements into sections. This process may take a few minutes.'}
      </Text>}
    </Box>
  );

  const canAddRequirement = currentStep >= REQUIREMENTS_REVIEW_STEP;

  return (
      <>
      <Modal opened={addRequirementOpened} {...defaultModalProps}>
      {currentStep > SECTIONS_REVIEW_STEP
        ? <AddRequirement
          close={addRequirementHandlers.close}
          defaultRequirementText={defaultRequirementText}
          opportunityUid={proposal?.opportunity?.uid ?? ''}
          opportunityFileUid={opportunityFileToAddTo}
          onAddRequirement={handleOnAddRequirementResponse}
          isOutline={true}
        />
        : <AddRequirementWithoutResponse
          close={addRequirementHandlers.close}
          defaultRequirementText={defaultRequirementText}
          opportunityUid={proposal?.opportunity?.uid ?? ''}
          opportunityFileUid={opportunityFileToAddTo}
          onAddRequirement={handleAddRequirementComplete}
        />
      }
      </Modal>
      <Box display={'flex'} style={{ flexDirection: 'row' }}>
        <LoadingOverlay visible={loading} loaderProps={{ children: loader }}/>
        <Box mr={16}>
          {steps[currentStep]}
        </Box>
        {!STEPS_WITHOUT_DOCUMENT_VIEWER.includes(currentStep) &&
          <Box w="43vw" h='calc(100vh - 200px)'>
          <WizardOpportunityDocumentsViewer
            onAddRequirement={canAddRequirement ? handleAddRequirement : undefined}
          />
        </Box>}
      </Box>
      </>
  );
};
