import { Button, Chip, Text, Group, Popover, ScrollArea } from '@mantine/core';
import React, { useCallback } from 'react';

import { FilterOutline, FilterFilled } from '../../icons';
import { type OrganizationResourceTag } from '../../types/apiTypes';
import { RESOURCE_TYPE_TO_STRING_MAP } from '../../utils/constants';
import { type ResourceType } from '../../types/miscTypes';

interface Props {
  tags: OrganizationResourceTag[]
  selectedTags: string[]
  setSelectedTags: (tagTexts: string[]) => void
  selectedResourceTypes: ResourceType[]
  setSelectedResourceTypes: (types: ResourceType[]) => void
  filterByInUse: boolean
  setFilterByInUse?: (inUse: boolean) => void
}

const ResourceFilter: React.FC<Props> = (props: Props) => {
  const {
    tags,
    selectedTags: searchTags,
    setSelectedTags: setSearchTags,
    filterByInUse: filterByProposal,
    setFilterByInUse: setFilterByProposal,
    setSelectedResourceTypes: setResourceTypes,
    selectedResourceTypes: resourceTypes
  } = props;

  const hasSelectedFilters = searchTags.length > 0 || resourceTypes.length > 0 || filterByProposal;

  const inUseChip = (
    <Chip
      key={'inUse'}
      variant='outline'
      checked={filterByProposal}
      onClick={() => {
        if (setFilterByProposal !== undefined) {
          setFilterByProposal(!filterByProposal);
        }
      }}
    >
      Yes
    </Chip>
  );

  const resourceTypeChips = Object.keys(RESOURCE_TYPE_TO_STRING_MAP).map((type) => {
    const isChecked = resourceTypes.includes(type as ResourceType);
    const updatedTypes = isChecked
      ? resourceTypes.filter((t) => t !== type)
      : resourceTypes.concat(type as ResourceType);
    return (
      <Chip
        key={type}
        variant='outline'
        checked={isChecked}
        onClick={() => {
          setResourceTypes(updatedTypes);
        }}
      >
        {RESOURCE_TYPE_TO_STRING_MAP[type as ResourceType]}
      </Chip>
    );
  });

  const handleResetTags = useCallback(() => {
    setResourceTypes([]);
    setSearchTags([]);
    if (setFilterByProposal !== undefined) {
      setFilterByProposal(false);
    }
  }, [setResourceTypes, setFilterByProposal, setSearchTags]);

  const resourceTagChips = tags.map((tag) => {
    const isChecked = searchTags.includes(tag.text ?? '');
    const updatedTags = isChecked
      ? searchTags.filter(t => t !== tag.text)
      : searchTags.concat(tag.text ?? '');
    return (
      <Chip
        key={tag.uid}
        variant='outline'
        checked={isChecked}
        onClick={() => {
          setSearchTags(updatedTags);
        }}
      >
        <Text truncate="end" maw={272} fz={14} styles={{
          root: {
            color: 'var(--mantine-color-lightPurple-5)'
          }
        }}>
          {tag.text}
        </Text>
      </Chip>
    );
  });

  const getFilterTypeText = () => {
    if (resourceTypes.length > 0) {
      return (
        <Text
          span
          fw={600}
          fz='sm'
        >
          Type <Text span fz='sm'> {resourceTypes.map(type => RESOURCE_TYPE_TO_STRING_MAP[type]).join(', ')} </Text>
        </Text>
      );
    }
  };

  const getFilterInUseText = () => {
    if (filterByProposal) {
      return (
        <Text
          span
          fw={600}
          fz='sm'
        >
          In Use <Text span fz='sm'> Yes </Text>
        </Text>
      );
    }
  };
  const getFilterTagsText = () => {
    if (searchTags.length > 0) {
      return (
        <Text
          span
          fw={600}
          fz='sm'
        >
          Tags <Text span fz='sm'> {searchTags.join(', ')} </Text>
        </Text>
      );
    }
  };

  const getFilterText = () => {
    if (searchTags.length === 0 && resourceTypes.length === 0 && !filterByProposal) {
      return <Text fz='sm' fw={600}> Filter </Text>;
    }
    return <Text fz='sm' fw={600}> Filter: {getFilterInUseText()} {getFilterTypeText()} {getFilterTagsText()} </Text>;
  };

  const defaultFilterButtonStyle = {
    border: 'none',
    color: 'black'
  };

  return (
    <Group justify='flex-start' pb={16} pt={16} style={{
      borderBottom: '1px solid var(--mantine-color-greyPurple-3)'
    }}>
      <Popover
        width={400}
        shadow='md'
        position='bottom-start'
      >
        <Popover.Target>
          <Button
            style={[{
              alignSelf: 'flex-start'
            }, !hasSelectedFilters ? defaultFilterButtonStyle : {}]}
            pl={16}
            leftSection={!hasSelectedFilters ? <FilterOutline /> : <FilterFilled />}
            variant='outline'
          >
            {getFilterText()}
          </Button>
        </Popover.Target>
        {hasSelectedFilters && <Button
          variant='outline'
          fw='bold'
          style={{
            border: 'none'
          }}
          onClick={handleResetTags}
        >
          Clear Filters
        </Button>}
        <Popover.Dropdown>
         { setFilterByProposal !== undefined &&
         <>
          <Text fz='sm' mb={8} fw={600}>
            In Use
          </Text>
          {inUseChip}
          </>
          }
          <Text fz='sm' mt={setFilterByProposal !== undefined ? 16 : 8} mb={8} fw={600}>
            Types
          </Text>
          <Chip.Group multiple>
            <Group pb={8} pr={14} pl={14}>
              {resourceTypeChips}
            </Group>
          </Chip.Group>
          <Text fz='sm' fw={600} mt={8}>
            Tags
          </Text>
          <ScrollArea.Autosize mah='400px'>
            <Chip.Group multiple>
              <Group pt={8} pr={14} pl={14}>
                  {resourceTagChips}
              </Group>
            </Chip.Group>
          </ScrollArea.Autosize>
        </Popover.Dropdown>
      </Popover>
    </Group>
  );
};

export default ResourceFilter;
