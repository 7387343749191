import { useCallback, useMemo } from 'react';
import { LoadingOverlay, Text } from '@mantine/core';

import { useNavigate, useParams } from 'react-router-dom';

import { type AxiosError } from 'axios';

import useEnsisMutation from '../../hooks/useEnsisMutation';
import ModalContent from '../ModalContent';
import { type SectionData } from '../../Pages/Editor/SectionEditor';
import { handlePotentialLockError } from '../../utils/apiUtils';

interface Props {
  sectionToEditUid: string
  close: () => void
  sections: SectionData[]
  onDeleteSuccess?: (section: SectionData) => void
}

const DeleteSection: React.FC<Props> = (props: Props) => {
  const { sectionToEditUid, sections, onDeleteSuccess, close } = props;
  const { proposalUid, organization, sectionUid } = useParams();
  const navigate = useNavigate();

  const onDeleteSectionError = useCallback((error: AxiosError) => {
    handlePotentialLockError(error, 'Unable to delete section: A teammate is currently editing this section');
    close();
  }, []);

  if (sections === undefined) {
    close();
    return <></>;
  }

  const sectionToEdit = sections.find((section) => section.uid === sectionToEditUid);
  const childSectionUids = sectionToEdit?.childSections?.map((childSection) => childSection.uid) ?? [];
  const topLevelSectionToNavigateTo = useMemo(() => {
    const isEditingFirstSection = sectionToEdit?.ordinal === 1;
    const sortedTopLevelSections = sections.filter(
      (section) => section.parentSectionUid === undefined
    ).sort((a, b) => (a.ordinal ?? 0) - (b.ordinal ?? 0));
    return isEditingFirstSection ? sortedTopLevelSections[1].uid : sortedTopLevelSections[0].uid;
  }, [sectionToEdit, sections]);

  const handleNavigateOnDelete = useCallback(() => {
    const shouldNavigateOnDelete = sectionUid === sectionToEditUid || childSectionUids.includes(sectionUid ?? '');
    if (shouldNavigateOnDelete) {
      const sectionUidToNavigateTo = sectionToEdit?.parentSectionUid ?? topLevelSectionToNavigateTo;
      navigate(`/org/${organization}/proposals/${proposalUid}/edit/${sectionUidToNavigateTo}`);
    }
  }, [sectionUid, sectionToEditUid, sectionToEdit, childSectionUids, topLevelSectionToNavigateTo]);

  const handleOnDeleteSuccess = useCallback(() => {
    if (onDeleteSuccess !== undefined && sectionToEdit !== undefined) {
      onDeleteSuccess(sectionToEdit);
    }
    handleNavigateOnDelete();
    close();
  }, [handleNavigateOnDelete, onDeleteSuccess, sectionToEdit]);

  const deleteSectionMutation = useEnsisMutation(`/app/proposal-sections/${sectionToEditUid}/data`, {
    requestType: 'delete',
    queryKeysToInvalidate: [`/app/proposals/${proposalUid}/data`],
    successMessage: 'Section deleted',
    onSuccess: handleOnDeleteSuccess,
    onError: onDeleteSectionError
  });

  const handleDeleteSection = useCallback(() => {
    deleteSectionMutation.mutate({});
  }, [deleteSectionMutation]);
  const sectionCopy = sectionToEdit?.parentSectionUid === undefined ? 'section' : 'subsection';
  return (
  <ModalContent
    title={`Delete ${sectionCopy}`}
    primaryButton={{
      color: 'red',
      label: 'Delete',
      onClick: handleDeleteSection,
      disabled: deleteSectionMutation.isPending
    }}
    secondaryButton={{ color: 'red', label: 'Cancel', onClick: close }}
  >
    <LoadingOverlay visible={deleteSectionMutation.isPending} zIndex={1000}/>
    <Text>
     {` Are you sure you want to delete this ${sectionCopy}? If there are requirements in the section,
      they will be archived.`}
    </Text>
  </ModalContent>);
};

export default DeleteSection;
