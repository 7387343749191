import { Group, Box, Button, Title, LoadingOverlay } from '@mantine/core';

import { useEffect, useMemo } from 'react';

import { useNavigate, useParams } from 'react-router-dom';

import useOrgPermissions from '../../hooks/useOrgPermissions';

import UserProfile from '../../components/Forms/UserProfile/UserProfile';

import classes from './Settings.module.css';
import CompanyDetails from '../../components/Forms/CompanyDetails/CompanyDetails';
import CurrentUsers from './CurrentUsers';
import InvitedUsers from './InvitedUsers';
import PageFormattingSettings from './PageFormattingSettings';
import FactsTable from '../../components/FactsTable';

const Settings: React.FC = () => {
  const { organization, section } = useParams();
  const { userHasPermission, isLoading, isSuccess } = useOrgPermissions();
  const navigate = useNavigate();

  const tabs = [
    {
      label: 'My Profile',
      section: 'profile',
      element: <UserProfile />,
      shouldShowSection: true
    },
    {
      label: 'Company Details',
      section: 'company-details',
      element: <CompanyDetails />,
      shouldShowSection: true
    },
    {
      label: 'Current users',
      section: 'org-users',
      element: <CurrentUsers />,
      shouldShowSection: userHasPermission('view_users')
    },
    {
      label: 'Invites',
      section: 'invited-users',
      element: <InvitedUsers />,
      shouldShowSection: userHasPermission('add_user')
    },
    {
      label: 'Page Formatting',
      section: 'page-formatting',
      element: <PageFormattingSettings />,
      shouldShowSection: userHasPermission('change')
    },
    {
      label: 'Global Facts',
      section: 'global-facts',
      element: <FactsTable />,
      shouldShowSection: userHasPermission('add_memory_items')
    }
  ];

  const currentTab = tabs.find((tab) => tab.section === section);
  // redirect to profile tab if param doesn't exist or user doesn't have permission
  useEffect(() => {
    if (isSuccess && (currentTab === undefined || !currentTab?.shouldShowSection)) {
      navigate(`/org/${organization}/settings/profile`);
    }
  }, [currentTab, isLoading]);

  const tabButtons = useMemo(() => (
    tabs.filter((tab) => tab.shouldShowSection).map((tab, index) => (
      <Box
        key={+index}
        className={classes.boxRoot}
        data-active={tab.section === section || undefined}
      >
        <Button
          radius={4}
          variant='subtle'
          data-active={tab.section === section || undefined}
          className={classes.root}
          onClick={() => { navigate(`/org/${organization}/settings/${tab.section}`); }}
        >
        {tab.label.toUpperCase()}
        </ Button>
      </Box>
    ))), [section, userHasPermission]);

  return (
    <Box w="100vw" px={48} pt={24} ta={'start'}>
      <Title ta={'start'} order={4} pb={32}> Settings </Title>
      <LoadingOverlay visible={isLoading} />
      <Group pb={32}>
        {tabButtons}
      </Group>
      {currentTab?.element}
    </Box>
  );
};

export default Settings;
