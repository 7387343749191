import { type Proposal } from '../types/apiTypes';
import { ProposalStatusType } from './constants';

const WORDS_PER_PAGE_SINGLE_SPACING = 500;

export const getWordsPerPage = (spacing: number): number => (
  Math.ceil(WORDS_PER_PAGE_SINGLE_SPACING / spacing)
);

export const getTotalWords = (spacing: number, numberOfPages: number): number => (
  Math.ceil(getWordsPerPage(spacing) * numberOfPages)
);

export const getProposalStatus = (proposal?: Proposal): ProposalStatusType => {
  if (proposal?.opportunity?.processing_status === 'PROCESSING' &&
      proposal?.status === ProposalStatusType.SETUP) {
    return ProposalStatusType.PROCESSING;
  }
  return proposal?.status as ProposalStatusType;
};
