import { useState } from 'react';

import { type SortDirection } from '../types/tableTypes';

export const useSorting = (
  initialField: string,
  initialOrder: SortDirection = 'ASC'
): {
  sorting: Array<{ id: string, desc: boolean }>
  onSortingChange: React.Dispatch<React.SetStateAction<Array<{
    id: string
    desc: boolean
  }>>>
} => {
  const [sorting, setSorting] = useState([
    {
      id: initialField,
      desc: initialOrder === 'DESC'
    }
  ]);

  return {
    sorting,
    onSortingChange: setSorting
  };
};
