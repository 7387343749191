/* eslint-disable max-len */

const AddFile: React.FC = () => {
  return (
    <svg width="17" height="19" viewBox="0 0 17 19" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
      <path d="M16.1667 14.0001H13.6667V11.5001H12V14.0001H9.49999V15.6667H12V18.1667H13.6667V15.6667H16.1667M1.99999 0.666748C1.07499 0.666748 0.333328 1.41675 0.333328 2.33341V15.6667C0.333328 16.5917 1.07499 17.3334 1.99999 17.3334H8.50833C8.20833 16.8167 7.99999 16.2501 7.89999 15.6667H1.99999V2.33341H7.83333V6.50008H12V9.90008C12.275 9.85841 12.5583 9.83341 12.8333 9.83341C13.1167 9.83341 13.3917 9.85841 13.6667 9.90008V5.66675L8.66666 0.666748M3.66666 9.00008V10.6667H10.3333V9.00008M3.66666 12.3334V14.0001H7.83333V12.3334H3.66666Z"/>
    </svg>
  );
};

export default AddFile;
