import { Button, Text, Group, Popover, Divider } from '@mantine/core';

import { useParams } from 'react-router-dom';

import { useCallback, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { ArrowDropdown, CrosshairsGps, Crosshairs, CheckCircle } from '../../icons';
import classes from './DropdownMenus.module.css';
import useEnsisMutation from '../../hooks/useEnsisMutation';
import { type ComplianceStatus } from '../ComplianceModeRequirement/ComplianceModeRequirement';
import { selectNarrativeCompliance } from '../../redux/store';
import { resetSelection } from '../../redux/NarrativeComplianceSlice';

interface Props {
  requirementResponseUid: string
  disabled: boolean
  complianceStatus: ComplianceStatus
}
interface MenuConfig {
  status: string
  actionLabel: string
  icon: React.ReactNode
  onClick: () => void
};

const ComplianceStatusMenu: React.FC<Props> = (props: Props) => {
  const { complianceStatus, requirementResponseUid, disabled } = props;
  const { proposalUid, sectionUid } = useParams();
  const selectedResponseUid = useSelector(selectNarrativeCompliance).selectedResponseUid;
  const dispatch = useDispatch();

  const editRequirementComplianceMutation = useEnsisMutation('/app/requirement-responses', {
    requestType: 'patch',
    showSuccessMessage: false,
    queryKeysToInvalidate: [`/app/proposals/${proposalUid}/sections/${sectionUid}/requirement-responses`]
  });
  const handleEditRequirementCompliance = useCallback(
    (isCompliant: boolean,
      sectionTextReferences: string[] | null) => {
      editRequirementComplianceMutation.mutate({
        proposal_uid: proposalUid ?? '',
        edits: [{
          requirement_response_uid: requirementResponseUid,
          section_text_references: sectionTextReferences,
          is_compliant: isCompliant
        }]
      }, {
        onSuccess: () => {
          if (selectedResponseUid === requirementResponseUid) {
            dispatch(resetSelection());
          }
        }
      });
    }, [editRequirementComplianceMutation, selectedResponseUid, proposalUid, requirementResponseUid]
  );

  const statusToMenuConfigMap: Record<ComplianceStatus, MenuConfig> = {
    linked: {
      status: 'Linked',
      onClick: () => { handleEditRequirementCompliance(false, []); setOpened(false); },
      actionLabel: 'Remove Link',
      icon: <CrosshairsGps />
    },
    noLink: {
      status: 'No Link Found',
      onClick: () => { handleEditRequirementCompliance(true, null); setOpened(false); },
      actionLabel: 'Mark as Compliant',
      icon: <Crosshairs/>
    },
    markedCompliant: {
      status: 'Marked Compliant',
      onClick: () => { handleEditRequirementCompliance(false, null); setOpened(false); },
      actionLabel: 'Remove Compliance',
      icon: <CheckCircle/>
    }
  };
  const [opened, setOpened] = useState(false);
  const buttonClassNames = {
    inner: classes.popoverMenuButtonItem,
    section: classes.popoverMenuButtonLeftSection,
    root: classes.popoverMenuButtonItemRoot
  };

  const dropdownConfig = statusToMenuConfigMap[complianceStatus];

  return (
  <Popover classNames={{ dropdown: classes.popoverDropdown }} opened={opened} onClose={() => { setOpened(false); }}>
    <Popover.Target >
      <Button
        onClick={(event) => { setOpened(!opened); event.stopPropagation(); }}
        disabled={disabled}
        w={44}
        h={26}
        p='0px 0px 0px 2px'
        variant="outline"
      >
        <Group gap={0}> {dropdownConfig.icon} <ArrowDropdown width={16} height={16}/> </Group>
      </Button>
    </Popover.Target>
    <Popover.Dropdown>
      <Text m='12px 24px' fz={16} fw={400} c={'var(--mantine-color-dark-3)'}>
        {`Status: ${dropdownConfig.status}`}
      </Text>
      <Divider/>
      <Button
        variant='subtle'
        classNames={buttonClassNames}
        w='100%'
        onClick={(event) => { dropdownConfig.onClick(); event?.stopPropagation(); }}
      >
        {dropdownConfig.actionLabel}
      </Button>
    </Popover.Dropdown>
  </Popover>
  );
};

export default ComplianceStatusMenu;
