import { Box, Modal } from '@mantine/core';
import { useAuth0 } from '@auth0/auth0-react';
import ReactGA from 'react-ga4';
import './App.css';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { useEffect } from 'react';

import { useDisclosure } from '@mantine/hooks';

import { Notifications } from '@mantine/notifications';

import { CenteredLoader, Header } from './components';
import useEnsisQuery from './hooks/useEnsisQuery';
import Onboarding from './components/Forms/Onboarding/Onboarding';
import EmailNotVerifiedPage from './Pages/EmailNotVerifiedPage';
import { defaultModalProps } from './utils/mantineUtils';
import NoOrganizationPage from './Pages/NoOrganizationPage';

const App: React.FC = () => {
  const { isLoading: auth0Loading, isAuthenticated, user, loginWithRedirect } = useAuth0();
  const { data: orgData, isLoading: queryLoading } = useEnsisQuery('/app/organizations');
  const { data: meData, isLoading: meLoading } = useEnsisQuery('/app/users/me');
  const [opened, { open, close }] = useDisclosure();
  const location = useLocation();
  const navigate = useNavigate();
  const isLoading = auth0Loading || queryLoading || meLoading;
  useEffect(() => {
    if (isLoading) {
      return;
    }
    if (!isAuthenticated) {
      loginWithRedirect({ appState: { returnTo: window.location.pathname } }).catch(() => {});
    } else if (location.pathname === '/') {
      const orgDataItems = orgData?.items;
      const defaultOrg = orgDataItems != null && orgDataItems.length > 0 ? orgDataItems[0] : null;
      const defaultOrgSlug = defaultOrg?.slug;
      if (defaultOrgSlug != null) {
        navigate(`/org/${defaultOrgSlug}/proposals`);
      }
    };
  }, [orgData, isAuthenticated, isLoading, loginWithRedirect, location, navigate]);

  useEffect(() => {
    if (isAuthenticated) {
      ReactGA.set({ userId: user?.sub });
    }
  }, [isAuthenticated]);

  const shouldShowLoading = isLoading || !isAuthenticated;

  useEffect(() => {
    if (meData !== undefined && !meData.onboarding_complete) {
      open();
    }
  }, [meData]);

  if (shouldShowLoading) {
    return <CenteredLoader style={{ height: '100vh' }} display='flex' loaderProps={{ size: 'xl' }}/>;
  } else if (user?.email_verified === false) {
    return <EmailNotVerifiedPage userEmail={user?.email} />;
  } else if (orgData?.items?.length === 0) {
    return <NoOrganizationPage />;
  }

  return (
    <div className="App">
      <Notifications position={'top-center'} w={350} />
      <Modal opened={opened} {...defaultModalProps}>
        <Onboarding close={close}/>
      </Modal>
      <Header
        email={meData?.email ?? ''}
        firstName={meData?.first_name ?? '' }
        lastName={ meData?.last_name ?? ''}
      />
      <Box display='flex'>
        <Outlet />
      </Box>
    </ div>
  );
};

export default App;
