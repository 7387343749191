import { Button, Group, Modal, Text, Space, ScrollArea, type MantineStyleProp } from '@mantine/core';

import { useCallback, useEffect, useState } from 'react';

import { useDisclosure } from '@mantine/hooks';

import { useParams } from 'react-router-dom';

import type { Row } from '@tanstack/react-table';

import { CenteredLoader, EditResource, Pagination, ResourceFilter, Table, UploadResources } from '../../components';

import { defaultModalProps } from '../../utils/mantineUtils';

import useEnsisMutation from '../../hooks/useEnsisMutation';
import { type ResourceRow } from '../../types/tableTypes';
import { ManageOrganizationResourceTags, PreventNavigationWarning } from '../../components/Modals';
import useOrgPermissions from '../../hooks/useOrgPermissions';
import TableEmptyState, { type TableEmptyStateProps } from '../../components/Table/TableEmptyState';
import DeleteResource from '../../components/Modals/DeleteResource/DeleteResource';
import useResourceTableData from '../../hooks/useResourceTableData';
import useEnsisQuery from '../../hooks/useEnsisQuery';
import { type ResourceType } from '../../types/miscTypes';

const ADMIN_EMPTY_STATE_TEXT = 'You haven\'t imported any resources.';
const EMPTY_FILTER_STATE_TEXT = 'No matching resources found for current filter.';
// eslint-disable-next-line max-len
const NON_ADMIN_EMPTY_STATE_TEXT = 'Your organization\'s administator has not imported any resources. Contact your administrator or info@ensis.ai for support.';
const EDIT_MODE_SELECTED_COPY = 'Resources selected';
const VIEW_MODE_SELECTED_COPY = 'Resources are being used in this proposal';

const selectedUidsAreEqual = (referenceUids: string[], currentReferenceUids: string[]) => {
  if (referenceUids.length !== currentReferenceUids.length) {
    return false;
  }
  return !referenceUids.some((id) => !currentReferenceUids.includes(id));
};

interface Props {
  referenceUids: string[]
  showButtons: boolean
  updateSelectedRows?: (string: string[]) => void
  tableContainerStyle?: MantineStyleProp
}
const SelectProposalResources: React.FC<Props> = (props: Props) => {
  const { referenceUids, showButtons, updateSelectedRows, tableContainerStyle } = props;
  const [editMode, setEditMode] = useState(!showButtons);
  const [opened, { open, close }] = useDisclosure();
  const { proposalUid } = useParams();
  const { organization } = useParams();
  const [editOpened, editHandlers] = useDisclosure(false);
  const [deleteOpened, deleteHandlers] = useDisclosure(false);
  const [editResourceTagsOpened, editResourceTagsHandlers] = useDisclosure(false);
  const [currentResource, setCurrentResource] = useState<ResourceRow>();
  const [searchTags, setSearchTags] = useState<string[]>([]);
  const [resourceTypes, setResourceTypes] = useState<ResourceType[]>([]);
  const [filterByProposal, setFilterByProposal] = useState(false);
  const { isLoading: permissionsLoading, userHasPermission } = useOrgPermissions();

  const handleOpenEdit = (row: Row<ResourceRow>) => {
    setCurrentResource(row.original);
    editHandlers.open();
  };

  const handleOpenDelete = (row: Row<ResourceRow>) => {
    setCurrentResource(row.original);
    deleteHandlers.open();
  };

  const handleEditResourceTags = () => {
    editResourceTagsHandlers.open();
  };

  const userCanUploadResource = userHasPermission('upload_resource');
  const handleDownload = (row: Row<ResourceRow>) => {
    setCurrentResource(row.original);
    window.open(row?.original?.url ?? '', '_blank', 'noreferrer');
  };

  const { data, isLoading: tagsLoading } = useEnsisQuery(
    '/app/organization-resource-tags',
    {
      queryParams: {
        organization_slug: organization ?? ''
      }
    }
  );

  const {
    table,
    isLoading: tableLoading,
    currentReferenceUids,
    totalNumberOfResources,
    handleToggleReferenceUid,
    setCurrentReferenceUids
  } = useResourceTableData({
    organizationSlug: organization ?? '',
    onEdit: handleOpenEdit,
    onDelete: handleOpenDelete,
    onDownload: handleDownload,
    onEditResourceTags: handleEditResourceTags,
    searchTags,
    resourceTypes,
    organizationResourceTags: data?.items ?? [],
    selectMode: editMode ? 'edit' : 'view',
    referenceUids,
    proposalUid: filterByProposal ? proposalUid : ''
  });

  const isDirty = !selectedUidsAreEqual(referenceUids ?? [], currentReferenceUids);

  useEffect(
    () => {
      if (updateSelectedRows !== undefined) {
        updateSelectedRows(currentReferenceUids);
      }
    }, [currentReferenceUids]
  );

  const isLoading = permissionsLoading || tableLoading || tagsLoading;

  const emptyStateProps: TableEmptyStateProps = (totalNumberOfResources ?? 0) > 0
    ? {
        text: EMPTY_FILTER_STATE_TEXT
      }
    : {
        text: userCanUploadResource ? ADMIN_EMPTY_STATE_TEXT : NON_ADMIN_EMPTY_STATE_TEXT,
        actionButton: (userCanUploadResource)
          ? {
              label: 'Import',
              onClick: open
            }
          : undefined
      };

  const editProposalResources = useEnsisMutation(`/app/proposals/${proposalUid}/data`, {
    requestType: 'patch',
    onSuccess: () => {
      setEditMode(false);
    },
    successMessage: 'Resources updated',
    queryKeysToInvalidate: ['/app/organization-resources', `/app/proposals/${proposalUid}/data`]
  });

  const handleEditProposalResources = useCallback(() => {
    editProposalResources.mutate({
      reference_uids: currentReferenceUids
    });
  }, [editProposalResources, currentReferenceUids]);

  const viewModeButtons = (
    <Group>
      <Button
        onClick={() => {
          setEditMode(true);
        }}
        variant='outline'
      >
        Select Resources
      </Button>
      <Button onClick={open} variant='outline'>
        Import Resources
      </Button>
    </Group>
  );

  const toggleOnRowClick = useCallback((row: Row<ResourceRow>) => {
    if (editMode) {
      handleToggleReferenceUid(row.original.uid);
    }
  }, [editMode, handleToggleReferenceUid]);

  const editModeButtons = (
    <Group>
      <Button
        variant='subtle'
        onClick={() => {
          setCurrentReferenceUids(referenceUids ?? []);
          setEditMode(false);
        }}
      >
        Cancel
      </Button>
      <Button
        disabled={!isDirty || currentReferenceUids.length === 0}
        loading={editProposalResources.isPending}
        onClick={handleEditProposalResources}
      >
        Save
      </Button>
    </Group>
  );

  if (isLoading) {
    return <CenteredLoader h={100}/>;
  };

  const setResourcesSelected = (uids: string[]) => {
    uids.forEach((uid) => {
      handleToggleReferenceUid(uid);
    });
    setEditMode(true);
  };

  const preventNavigationWarning = (
    <PreventNavigationWarning
      title="Leave this page?"
      shouldPreventNavigation={editMode && isDirty}
      shouldPreventRefresh
    >
      {'You made changes that haven\'t been saved. Are you sure you want to leave?'}
      <Space h='md'/>
      Save before you go!
    </PreventNavigationWarning>
  );

  return (
  <>
    <Modal opened={opened} {...defaultModalProps}>
      <UploadResources onClose={close} setSelected={setResourcesSelected}/>
    </Modal>
    { preventNavigationWarning }
    <Group justify="space-between">
      <Text c='var(--mantine-color-darkPurple-5)' fz='sm' fw='700'>
        {`${currentReferenceUids.length}/${totalNumberOfResources}
        ${editMode ? EDIT_MODE_SELECTED_COPY : VIEW_MODE_SELECTED_COPY}`}
      </Text>
      {showButtons && (editMode ? editModeButtons : viewModeButtons)}
    </Group>
    <Modal
      opened={editOpened}
      {...defaultModalProps}
    >
      <EditResource
        resourceUid={currentResource?.uid}
        name={currentResource?.resourceName}
        description={currentResource?.description}
        resourceType={currentResource?.resourceType}
        onClose={editHandlers.close}
      />
    </Modal>
    <Modal
      opened={deleteOpened}
      {...defaultModalProps}
    >
      <DeleteResource
        resourceUid={currentResource?.uid}
        name={currentResource?.resourceName}
        onClose={deleteHandlers.close}
      />
    </Modal>
    <Modal
      opened={editResourceTagsOpened}
      {...defaultModalProps}
    >
      <ManageOrganizationResourceTags
        organizationSlug={organization ?? ''}
        onClose={editResourceTagsHandlers.close}
      />
    </Modal>
    <ResourceFilter
      tags={data?.items ?? []}
      filterByInUse={filterByProposal}
      setFilterByInUse={setFilterByProposal}
      selectedResourceTypes={resourceTypes}
      selectedTags={searchTags}
      setSelectedTags={setSearchTags}
      setSelectedResourceTypes={setResourceTypes}
    />
    <ScrollArea.Autosize style={tableContainerStyle}>
    {
      table.getRowModel().rows.length === 0
        ? <TableEmptyState {...emptyStateProps}/>
        : <Table table={table} onRowClick={toggleOnRowClick} tableStyle='withTags' />
    }
    </ScrollArea.Autosize>
    <Pagination table={table}/>
    </>
  );
};

export default SelectProposalResources;
