import { Text, Box, Group, Title, Button } from '@mantine/core';
import { useParams } from 'react-router-dom';

import { RouterNavLink } from '../../components';
import { Exit } from '../../icons';

interface Props {
  titleCopy: string
  subtitleCopy: string
  children: React.ReactNode
  onClickNext: () => void
  onClickPrevious?: () => void
  nextButtonCopy?: string
  nextButtonDisabled?: boolean
}

const WizardContent: React.FC<Props> = (props: Props) => {
  const { onClickNext, nextButtonCopy, children, nextButtonDisabled, titleCopy, subtitleCopy, onClickPrevious } = props;
  const { organization } = useParams();
  return (
    <Box w='100%' p='0 16 16 16' h='calc(100vh - 110px)'>
    <Box ta='start'>
      <Title order={6}> {titleCopy}</Title>
      <Text mih={50}>
        {subtitleCopy}
      </Text>
    </Box>
    <Box h='calc(100vh - 250px)'>
      {children}
    </Box>
    <Box>
      <Group justify="space-between">
        <RouterNavLink
          w={200}
          key="exit"
          styles={{
            label: {
              fontWeight: 600,
              color: 'var(--mantine-color-lightPurple-5)'
            }
          }}
          to={`/org/${organization}/proposals/`}
          label={ <Group> <Exit /> Exit Proposal </Group>}
        />
        <Group>
          {onClickPrevious !== undefined && (
            <Button variant='outline' onClick={onClickPrevious}>
              Previous
            </Button>
          )}
          <Button disabled={nextButtonDisabled} onClick={onClickNext}>
            {nextButtonCopy ?? 'Next'}
          </Button>
        </Group>
      </Group>
    </Box>
  </Box>
  );
};
export default WizardContent;
