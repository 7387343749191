import React from 'react';

const ArrowUp: React.FC = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M17 14.5L12 9.5L7 14.5L17 14.5Z"/>
  </svg>
);

export default ArrowUp;
