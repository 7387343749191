import { Button, Divider, Popover, Stack } from '@mantine/core';
import { useCallback, useState } from 'react';

import { useParams } from 'react-router-dom';

import useEnsisMutation from '../../hooks/useEnsisMutation';

import classes from './DropdownMenus.module.css';
import { ArrowDropdown } from '../../icons';
import { withAnalyticsEvent } from '../../utils/analyticsUtils';

interface Props {
  canEdit: boolean
  sectionUid: string
  onClickGenerateNarrative: () => void
}
const CreateNarrativeDropdown: React.FC<Props> = (props: Props) => {
  const { proposalUid } = useParams();
  const [opened, setOpened] = useState(false);

  const {
    onClickGenerateNarrative,
    sectionUid,
    canEdit
  } = props;

  const buttonClassNames = {
    inner: classes.popoverMenuButtonItem,
    section: classes.popoverMenuButtonLeftSection,
    root: classes.popoverMenuButtonItemRoot
  };

  const createNarrativeMutation = useEnsisMutation(
    `/app/proposal-sections/${sectionUid}/data`,
    {
      requestType: 'patch',
      queryKeysToInvalidate: [`/app/proposals/${proposalUid}/data`]
    }
  );

  const handleCreateBlankNarrative = useCallback(() => {
    createNarrativeMutation.mutate({
      content: ''
    });
    setOpened(false);
  }, [createNarrativeMutation.mutate]);

  const handleGenerateNarrative = useCallback(() => {
    onClickGenerateNarrative();
    setOpened(false);
  }, [onClickGenerateNarrative]);
  return (
    <>
      <Popover classNames={{ dropdown: classes.popoverDropdown }} opened={opened} onChange={setOpened}>
        <Popover.Target>
          <Button
            disabled={!canEdit}
            variant='outline'
            onClick={() => { setOpened(!opened); }}
          >
            Create Narrative
            <ArrowDropdown/>
          </Button>
        </Popover.Target>
        <Popover.Dropdown>
          <Stack gap={0}>
            <Button variant="subtle" classNames={buttonClassNames} onClick={handleGenerateNarrative}>
              Generate Narrative
            </Button>
            <Divider />
            <Button
              variant="subtle"
              classNames={buttonClassNames}
              onClick={withAnalyticsEvent(handleCreateBlankNarrative, 'CreateBlankNarrative')}
            >
              Create Blank Narrative
            </Button>
          </Stack>
        </Popover.Dropdown>
      </Popover>
    </>
  );
};

export default CreateNarrativeDropdown;
