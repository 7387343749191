import { Text } from '@mantine/core';

import ModalContent from '../ModalContent';

interface Props {
  close: () => void
  closeRightPane: () => void
  viewName: string
}

const CloseRightPaneWarning: React.FC<Props> = (props: Props) => {
  const { close, closeRightPane, viewName } = props;
  return (
  <ModalContent
    primaryButton={{ label: 'Close Without Saving', onClick: () => { closeRightPane(); close(); }, color: 'red' }}
    secondaryButton={{ label: 'Cancel', onClick: close, color: 'red' }}
    title='Discard Changes?'
  >
    <Text>
      {`You have unsaved changes in your ${viewName}. Are you sure you want to close without saving?`}
    </Text>
  </ModalContent>);
};

export default CloseRightPaneWarning;
