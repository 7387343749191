import { Box, Button, CloseButton, Group, TextInput } from '@mantine/core';
import { useCallback } from 'react';

interface Props {
  handleAddTag: () => void
  newTagText: string
  setNewTagText: (newText: string) => void
  tagAlreadyExists: boolean
}
const AddTagTextInput: React.FC<Props> = (props: Props) => {
  const { handleAddTag, newTagText, setNewTagText, tagAlreadyExists } = props;

  const inputIsEmpty = newTagText === '';
  const addTagInlineButton = (
    <Group mr={0} pr={0} gap={2} align='center'>
      <Box w={25}>
        {!inputIsEmpty && <CloseButton mt={4} onClick={() => { setNewTagText(''); }}/>}
      </Box>
      <Button
        h={38}
        variant='subtle'
        radius='0px 5px 5px 0px'
        w={100}
        onClick={handleAddTag}
        disabled={inputIsEmpty || tagAlreadyExists}
      >
        Add Tag
      </Button>
    </Group>
  );

  const inputBorderStyle = !tagAlreadyExists
    ? (inputIsEmpty
        ? '1px solid var(--mantine-color-gray-1)'
        : '1px solid var(--mantine-color-lightPurple-5)'
      )
    : '';

  const handleKeyDown = useCallback((event: any) => {
    if (event === 'Enter') {
      handleAddTag();
    }
  }, [handleAddTag]);
  return (
    <TextInput
      variant='outline'
      placeholder='Create a tag'
      value={newTagText}
      onChange={(event) => {
        setNewTagText(event.currentTarget.value);
      }}
      rightSectionWidth={127}
      rightSection={addTagInlineButton}
      onKeyDown={handleKeyDown}
      error={tagAlreadyExists ? 'Tag already exists' : undefined}
      styles={{
        wrapper: {
          border: inputBorderStyle,
          borderRadius: 5
        }
      }}
    />
  );
};

export default AddTagTextInput;
