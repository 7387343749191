import { useParams } from 'react-router-dom';
import { LoadingOverlay, Text } from '@mantine/core';
import { useCallback } from 'react';

import useEnsisMutation from '../../../hooks/useEnsisMutation';
import ModalContent from '../../ModalContent';

interface Props {
  userUid?: string
  name: string
  close: () => void
}
const RemoveUser: React.FC<Props> = (props: Props) => {
  const { name, close, userUid } = props;
  const { organization } = useParams();

  const removeUserMutation = useEnsisMutation(
    `/app/users/${userUid}/remove-from-org`,
    {
      contentType: 'application/json',
      requestType: 'patch',
      successMessage: 'User successfully removed',
      onSuccess: close,
      queryKeysToInvalidate: ['/app/users']
    }
  );
  const onRemove = useCallback(() => {
    removeUserMutation.mutate({
      organization_slug: organization ?? ''
    });
  }, []);

  return (
    <ModalContent
      title='Remove a team member'
      subtitle='Remove a user from your team in Ensis'
      onClose={close}
      primaryButton={{
        label: 'Confirm',
        onClick: onRemove,
        color: 'red'
      }}
      secondaryButton={{
        label: 'Cancel',
        onClick: close,
        color: 'red'
      }}
    >
      <LoadingOverlay visible={removeUserMutation.isPending} zIndex={1000} />
      <Text>
        This will revoke <Text span fw={700}> {`${name}'s`} </Text> access to your team in Ensis.
         <Text span fw={700}> {`${name}'s`} </Text> generated proposals will remain available to your team anonymously.
      </Text>
    </ ModalContent>
  );
};

export default RemoveUser;
