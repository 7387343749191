import { useParams } from 'react-router-dom';
import { Box, Text, Title, Group, Button } from '@mantine/core';
import { useCallback, useState } from 'react';

import { CenteredLoader, ProposalSpacingSelector } from '../../components';
import useEnsisQuery from '../../hooks/useEnsisQuery';
import useEnsisMutation from '../../hooks/useEnsisMutation';

const PageFormattingSettings: React.FC = () => {
  const { organization } = useParams();

  const [selectedValue, setSelectedValue] = useState<number>(1);

  const { data, isLoading } = useEnsisQuery(
    `/app/organizations/${organization}/data`,
    {
      onSuccess: (data) => {
        setSelectedValue(data?.default_proposal_spacing ?? 1);
      }
    }
  );
  const updateOrgMutation = useEnsisMutation(
    `/app/organizations/${organization}/data`,
    {
      successMessage: 'Settings Updated',
      requestType: 'patch',
      queryKeysToInvalidate: [`/app/organizations/${organization}/data`]
    }
  );

  const handleSubmit = useCallback(() => {
    updateOrgMutation.mutate({ default_proposal_spacing: selectedValue });
  }, [selectedValue]);

  const handleCancel = useCallback(() => {
    setSelectedValue(data?.default_proposal_spacing ?? 1);
  }, [data?.default_proposal_spacing]);

  if (isLoading) {
    return <CenteredLoader />;
  }

  const settingUnchanged = data?.default_proposal_spacing === selectedValue;

  return (
    <Box maw={850}>
      <Title order={5} mb={12}>Default Page Length</Title>
      <Text mb={24}>
        The default values are based upon standard government requirements.
        Updating these values will not affect existing Proposals.
      </Text>
      <ProposalSpacingSelector
        mb={24}
        value={selectedValue}
        onSelectValue={(value) => { setSelectedValue(value); }}
      />
      <Group>
        <Button
          disabled={settingUnchanged}
          onClick={handleCancel}
        >
          Cancel
        </Button>
        <Button
          disabled={settingUnchanged || updateOrgMutation?.isPending}
          onClick={handleSubmit}
        >
          Save
        </Button>
      </Group>
    </Box>
  );
};

export default PageFormattingSettings;
