import { LoadingOverlay, Text, TextInput } from '@mantine/core';
import { useNavigate, useParams } from 'react-router-dom';

import { useCallback, useState } from 'react';

import ModalContent from '../ModalContent';
import useEnsisMutation from '../../hooks/useEnsisMutation';

interface Props {
  close: () => void
  proposalName: string
  proposalUid: string
}

const DeleteProposal: React.FC<Props> = (props: Props) => {
  const { close, proposalName, proposalUid } = props;
  const navigate = useNavigate();
  const { organization } = useParams();
  const [proposalNameInput, setProposalNameInput] = useState<string>('');

  const namesMatch = proposalNameInput === proposalName;

  const deleteProposalMutation = useEnsisMutation(`/app/proposals/${proposalUid}/data`, {
    requestType: 'delete',
    successMessage: 'Proposal deleted',
    onSuccess: () => {
      close();
      navigate(`/org/${organization}/proposals/`);
    },
    queryKeysToInvalidate: ['/app/proposals']
  });

  const handleDeleteProposal = useCallback(() => {
    deleteProposalMutation.mutate({});
  }, [deleteProposalMutation.mutate]);

  const handleKeyDown = useCallback((event: any) => {
    if (event.key === 'Enter' && namesMatch) {
      handleDeleteProposal();
    }
  }, [namesMatch, proposalNameInput]);

  return (
    <ModalContent
    title={`Are you sure you want to delete "${proposalName}"?`}
    secondaryButton={{
      label: 'Cancel',
      onClick: close
    }}
    primaryButton={{
      label: 'Delete',
      onClick: handleDeleteProposal,
      disabled: deleteProposalMutation.isPending || !namesMatch
    }}
    onClose={close}
  >
    <LoadingOverlay visible={deleteProposalMutation.isPending} zIndex={1000} />
    <Text> Deleting this proposal will result in losing all data forever </Text>
      <TextInput
        label={`To confirm, type "${proposalName}" below`}
        required
        pt={16}
        value={proposalNameInput}
        onChange={(event) => { setProposalNameInput(event.currentTarget.value); }}
        onKeyDown={handleKeyDown}
      />
  </ModalContent>
  );
};

export default DeleteProposal;
