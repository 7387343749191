import { Text } from '@mantine/core';

import ModalContent from '../ModalContent';

interface Props {
  close: () => void
  onContinue: () => void
};

const RegenerateNarrative: React.FC<Props> = ({ close, onContinue }: Props) => (
  <ModalContent
    title="Generate Narrative"
    primaryButton={{
      label: 'Continue',
      onClick: onContinue,
      color: 'red'
    }}
    secondaryButton={{
      label: 'Cancel',
      onClick: close,
      color: 'red'
    }}
  >
    <Text>
      This will erase your current version. Are you sure you want to proceed?
    </Text>
  </ModalContent>
);

export default RegenerateNarrative;
