import { Badge, CheckIcon, Group, Text } from '@mantine/core';

import { PROPOSAL_STATUS_TO_STRING_MAP, type ProposalStatusType } from '../../../utils/constants';

interface Props {
  status: ProposalStatusType
  showCheckmark?: boolean
}

const PROPOSAL_STATUS_TO_COLOR_MAP: Record<ProposalStatusType, string> = {
  SETUP: '#F3B33E',
  PROCESSING: '#EC6B2C',
  AUTHORING: '#CA3A7D',
  IN_REVIEW: '#745FE8',
  FINALIZING: '#6D8EF7',
  DONE: '#29E047'
};

const ProposalStatusItem: React.FC<Props> = (props: Props) => {
  const { status, showCheckmark = false } = props;
  const color = PROPOSAL_STATUS_TO_COLOR_MAP[status];
  const statusText = PROPOSAL_STATUS_TO_STRING_MAP[status];

  return (
      <Group p='0' gap='8' m='0'>
        {showCheckmark && <CheckIcon size={12} color='var(--mantine-color-gray-6)' radius={0}/>}
        <Badge size='8' color={color} circle></Badge>
        <Text fz='xs'>{statusText}  </Text>
      </Group>
  );
};

export default ProposalStatusItem;
