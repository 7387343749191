import { useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { type SortDirection } from '../types/tableTypes';

const DEFAULT_PAGE_SIZE = 9;

/**
 * Tries to parse pagination params from the URL search params
 */
export const usePaginationParams = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const pageSize = searchParams.get('pageSize');
  const sortBy = searchParams.get('sortBy') ?? undefined;
  const pageIndex = searchParams.get('pageIndex');
  const sortDirection = searchParams.get('sortDirection');

  const parsedPageSize = pageSize === null ? undefined : Number.parseInt(pageSize);
  const parsedPageIndex = pageIndex === null ? undefined : Number.parseInt(pageIndex);

  const updateSearchParams = (
    newPageSize: number,
    newSortBy: string,
    newPageIndex: number,
    newSortDirection: SortDirection
  ) => {
    setSearchParams(
      `sortBy=${newSortBy ?? ''}&pageSize=${newPageSize.toString()}` +
      `&pageIndex=${newPageIndex.toString()}&sortDirection=${newSortDirection}`
    );
  };

  return {
    parsedPageSize,
    parsedSortBy: sortBy,
    parsedPageIndex,
    parsedSortDirection: sortDirection,
    updateSearchParams
  };
};

export const usePagination = (pageSize = DEFAULT_PAGE_SIZE, pageIndex: number) => {
  const [pagination, setPagination] = useState({
    pageSize,
    pageIndex
  });

  return {
    onPaginationChange: setPagination,
    pagination,
    limit: pageSize,
    offset: pageSize * pageIndex
  };
};
