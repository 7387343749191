import { useCallback, useState } from 'react';
import { useParams } from 'react-router-dom';
import { LoadingOverlay, Textarea } from '@mantine/core';

import ModalContent from '../ModalContent';
import useEnsisMutation from '../../hooks/useEnsisMutation';
import { type PaginatedRoute } from '../../types/apiTypes';

interface Props {
  close: () => void
  fact: string
  factUid: string
  proposalUid?: string
}

const EditFact: React.FC<Props> = ({ close, fact, factUid, proposalUid }: Props) => {
  const [editedFact, setEditedFact] = useState<string>(fact);
  const { organization } = useParams();
  const route: PaginatedRoute = proposalUid !== undefined
    ? `/app/proposals/${proposalUid}/memory-items`
    : `/app/organizations/${organization}/memory-items`;

  const updateFactMutation = useEnsisMutation(
    `/app/memory-items/${factUid}/data`,
    {
      requestType: 'patch',
      successMessage: 'Fact updated',
      queryKeysToInvalidate: [route],
      onSuccess: close
    }
  );

  const handleUpdateFact = useCallback(() => {
    updateFactMutation.mutate({
      text: editedFact
    });
  }, [updateFactMutation, editedFact]);

  return (
    <ModalContent
      title='Edit fact'
      onClose={close}
      secondaryButton={{
        label: 'Cancel',
        onClick: close
      }}
      primaryButton={{
        label: 'Save',
        onClick: handleUpdateFact,
        disabled: editedFact === fact
      }}
    >
      <LoadingOverlay zIndex={1000} visible={updateFactMutation.isPending}/>
      <Textarea
        autosize
        minRows={1}
        maxRows={4}
        value={editedFact}
        onChange={(event) => { setEditedFact(event.currentTarget.value); }}
      />
    </ModalContent>
  );
};

export default EditFact;
