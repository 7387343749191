import { Box, Button, Group, Modal, ScrollArea } from '@mantine/core';
import { useSelector, useDispatch } from 'react-redux';
import { useDisclosure } from '@mantine/hooks';
import { useCallback, useState } from 'react';

import { type RequirementResponse } from '../../types/apiTypes';
import EditOutlineDnD, { type RequirementType } from '../Editor/EditOutlineDnd';
import { type SectionData } from '../Editor/SectionEditor';
import WizardContent from './WizardContent';
import { defaultModalProps } from '../../utils/mantineUtils';
import { AddParagraph } from '../../icons';
import { selectRequirement } from '../../redux/WizardSlice';
import { selectWizardState } from '../../redux/store';
import { AddSection } from '../../components/Modals';
import { addSection } from '../../redux/OutlineSlice';
import { formatSectionDataForRedux } from '../../utils/dndUtils';
import { BulkRequirementButtonGroup } from '../../components';

interface Props {
  sections: SectionData[]
  requirementResponses: RequirementResponse[]
  onClickNext: () => void
  onClickPrevious: () => void
  opportunityUid: string
  handleClickRequirement: (requirementUid: string) => void
}
const ReviewFullOutline: React.FC<Props> = (props: Props) => {
  const {
    onClickNext,
    opportunityUid,
    requirementResponses,
    sections,
    onClickPrevious,
    handleClickRequirement
  } = props;

  const [addSectionOpened, addSectionHandlers] = useDisclosure();
  const { selectedRequirementUid } = useSelector(selectWizardState);
  const dispatch = useDispatch();

  const [checkedRequirements, setCheckedRequirements] = useState<RequirementType[]>([]);
  const onCheckRequirement = useCallback((requirement: RequirementType) => {
    if (checkedRequirements.includes(requirement)) {
      setCheckedRequirements(checkedRequirements.filter((_requirement) => _requirement !== requirement));
    } else {
      setCheckedRequirements([...checkedRequirements, requirement]);
    }
  }, [checkedRequirements]);
  const toggleClickRequirement = useCallback((requirementUid: string) => {
    if (requirementUid === selectedRequirementUid) {
      dispatch(selectRequirement({ requirementUid: '' }));
    } else {
      handleClickRequirement(requirementUid);
    }
  }, [handleClickRequirement, selectedRequirementUid]);

  const [parentSectionUid, setParentSectionUid] = useState<string | undefined>(undefined);

  const inBulkEditMode = checkedRequirements.length > 0;

  const onOpenAddSection = useCallback((parentSectionUid?: string) => {
    setParentSectionUid(parentSectionUid);
    addSectionHandlers.open();
  }, []);

  const onAddSectionSuccess = useCallback((data: any) => {
    const formattedData = formatSectionDataForRedux(data);
    dispatch(addSection(formattedData));
  }, []);

  const onClearCheckedRequirements = useCallback(() => {
    setCheckedRequirements([]);
  }, []);

  return (
    <>
      <Modal {...defaultModalProps} opened={addSectionOpened}>
        <AddSection
          onAddSectionSuccess={onAddSectionSuccess}
          parentSectionUid={parentSectionUid}
          close={addSectionHandlers.close}
          sections={sections}
        />
      </Modal>
      <Box w='55vw' h='calc(100vh - 92px)' pt={24}>
        <WizardContent
          subtitleCopy="Now, organize your requirements into sections. Drag and drop to arrange them as needed."
          titleCopy="Finalize your structure"
          onClickNext={onClickNext}
          onClickPrevious={onClickPrevious}
        >
          <Group justify='space-between' mr={6} mb={8}>
            {inBulkEditMode
              ? (
              <BulkRequirementButtonGroup
                checkedRequirements={checkedRequirements}
                onClearCheckedRequirements={onClearCheckedRequirements}
                sections={sections}
              />
                )
              : (
            <Button pl={16} leftSection={<AddParagraph/>} variant='outline' onClick={() => { onOpenAddSection(); }}>
              Add Section
            </Button>)
          }
          </Group>
          <ScrollArea h='calc(100vh - 300px)' type='hover'>
            <EditOutlineDnD
              onOpenAddSection={onOpenAddSection}
              focusedRequirementUid={selectedRequirementUid}
              opportunityUid={opportunityUid}
              sections={sections}
              requirementResponses={requirementResponses}
              onRowClick={toggleClickRequirement}
              sectionWidth='calc(55vw - 340px)'
              onCheckRequirement={onCheckRequirement}
              checkedRequirements={checkedRequirements}
            />
          </ScrollArea>
        </WizardContent>
      </Box>
    </>
  );
};

export default ReviewFullOutline;
