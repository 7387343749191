import React, { type SVGProps } from 'react';

const Star: React.FC<Partial<SVGProps<SVGSVGElement>>> = (props: Partial<SVGProps<SVGSVGElement>>) => {
  const { width, height } = props;

  return (
        <svg
            width= {width ?? '24' }
            height= {height ?? '24'}
            fill= "currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
        >

<path d="M12 17.77L18.18 21.5L16.54 14.47L22 9.74L14.81 9.13L12 2.5L9.19 9.13L2 9.74L7.46 14.47L5.82 21.5L12 17.77Z"/>
</svg>

  );
};

export default Star;
