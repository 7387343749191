import { RichTextEditor } from '@mantine/tiptap';

import classes from './GenerationFlow.module.css';

interface Props {
  onClickGenerate: () => void
}

const GenerateControl: React.FC<Props> = (props: Props) => (
    <RichTextEditor.Control
      p={0}
      onClick={props.onClickGenerate}
      w={100}
      className={classes.customControls}
      style={{ borderRadius: '4px' }}
    >
      Generate
    </RichTextEditor.Control>
);

export default GenerateControl;
