import { useCallback, useState } from 'react';

import { useParams } from 'react-router-dom';

import { LoadingOverlay, Textarea } from '@mantine/core';

import ModalContent from '../ModalContent';
import useEnsisMutation from '../../hooks/useEnsisMutation';
import { isEmpty } from '../../utils/stringUtils';
import { type OpportunityRequirement } from '../../types/apiTypes';

interface Props {
  close: () => void
  defaultRequirementText?: string
  defaultRequirementIdentifier?: string
  opportunityUid: string
  opportunityFileUid?: string
  onAddRequirement?: (requirement: OpportunityRequirement) => void
}

const AddRequirementWithoutResponse: React.FC<Props> = (props: Props) => {
  const {
    close,
    defaultRequirementText,
    defaultRequirementIdentifier,
    opportunityFileUid,
    onAddRequirement,
    opportunityUid
  } = props;
  const { proposalUid } = useParams();
  const [requirementIdentifier, setRequirementIdentifier] = useState(defaultRequirementIdentifier ?? '');
  const [requirementText, setRequirementText] = useState(defaultRequirementText ?? '');

  const addRequirementMutation = useEnsisMutation(
    '/app/requirements',
    {
      contentType: 'application/json',
      requestType: 'post',
      successMessage: 'Requirement successfully added',
      onSuccess: (data) => {
        close();
        if (onAddRequirement !== undefined) {
          onAddRequirement(data);
        }
      },
      queryKeysToInvalidate: [
        `/app/proposals/${proposalUid}/data`,
        `/app/opportunities/${opportunityUid}/requirements`
      ],
      awaitRefetch: false
    }
  );

  const handleAddRequirement = useCallback(() => {
    addRequirementMutation.mutate({
      text: requirementText,
      identifier: requirementIdentifier ?? '',
      opportunity_uid: opportunityUid ?? '',
      opportunity_file_uid: opportunityFileUid ?? null
    });
  }, [
    addRequirementMutation.mutate,
    requirementText,
    requirementIdentifier,
    opportunityFileUid,
    proposalUid
  ]);

  return (
    <ModalContent
      title='Add a requirement'
      subtitle="Enter your requirement below (you can change it later)."
      onClose={close}
      secondaryButton={{
        label: 'Cancel',
        onClick: close
      }}
      primaryButton={{
        label: 'Add',
        onClick: handleAddRequirement,
        disabled: isEmpty(requirementText)
      }}
    >
      <LoadingOverlay zIndex={1000} visible={addRequirementMutation.isPending}/>
      <Textarea
        autosize
        mb={24}
        label='Identifier'
        placeholder='Leave blank if none'
        minRows={1}
        maxRows={4}
        value={requirementIdentifier}
        onChange={(event) => { setRequirementIdentifier(event.currentTarget.value); }}
      />
      <Textarea
        autosize
        label='Requirement'
        placeholder='New requirement'
        minRows={1}
        maxRows={4}
        value={requirementText}
        onChange={(event) => { setRequirementText(event.currentTarget.value); }}
      />
    </ModalContent>
  );
};

export default AddRequirementWithoutResponse;
