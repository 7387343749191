import { AspectRatio, Text } from '@mantine/core';

import img from '../../../assets/fact-management.png';

import ModalContent from '../../ModalContent';

interface Props {
  onClose: () => void
}

const WHATS_THIS_TEXT = `
  Fact management will store information to apply for future updates 
  to this proposal or all future proposals. You can access these facts 
  under ‘Proposal Management’. You can access ‘global’ facts from User Settings.
`;

const FactManagementHelp: React.FC<Props> = (props: Props) => {
  const { onClose } = props;

  return (
    <ModalContent
      title='Fact Management'
      primaryButton={{ label: 'Got it', onClick: onClose }}
    >
      <Text>
        {WHATS_THIS_TEXT}
      </Text>
      <AspectRatio ratio={4}>
        <img
          style={{
            margin: '16px 0 16px 0',
            border: '1px solid var(--mantine-color-gray-5)',
            borderRadius: '4px'
          }}
          src={img}
          alt='fact management screenshot'
        />
      </AspectRatio>
    </ModalContent>
  );
};

export default FactManagementHelp;
