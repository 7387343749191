import { LoadingOverlay, Radio, RadioGroup } from '@mantine/core';

import { useCallback, useState } from 'react';

import { useParams } from 'react-router-dom';

import ModalContent from '../ModalContent';
import useEnsisMutation from '../../hooks/useEnsisMutation';
import { OutlineOutputFormatType } from '../../utils/constants';

interface Props {
  onClickNext: () => void
  defaultOutlineOutputFormat: string
  onClose: () => void
}
const OUTLINE_OUTPUT_FROMAT_TYPE_TO_STRING: Record<OutlineOutputFormatType, string> = {
  [OutlineOutputFormatType.BULLET_POINTS]: 'Bullet Points',
  [OutlineOutputFormatType.PARAGRAPH]: 'Paragraph'
};

const SelectOutlineOutputFormat: React.FC<Props> = (props: Props) => {
  const { onClickNext, defaultOutlineOutputFormat, onClose } = props;
  const { proposalUid } = useParams();

  const [selectedFormatValue, setSelectedFormatValue] = useState(defaultOutlineOutputFormat);
  const updateProposalMutation = useEnsisMutation(`/app/proposals/${proposalUid}/data`, {
    showSuccessMessage: false,
    requestType: 'patch',
    queryKeysToInvalidate: [`/app/proposals/${proposalUid}/data`],
    onSuccess: onClickNext
  });

  const handleClickNext = useCallback(() => {
    updateProposalMutation.mutate(
      { outline_output_format: selectedFormatValue }
    );
  }, [updateProposalMutation, selectedFormatValue]);

  return (
    <ModalContent
      title='Response format type'
      onClose={onClose}
      primaryButton={{
        label: 'Next',
        onClick: handleClickNext
      }}
      secondaryButton={{
        label: 'Cancel',
        onClick: onClose
      }}
    >
      <LoadingOverlay visible={updateProposalMutation.isPending} />
      <RadioGroup
        value={selectedFormatValue}
        onChange={(value) => { setSelectedFormatValue(value as OutlineOutputFormatType); }}
      >
        <Radio
          value={OutlineOutputFormatType.BULLET_POINTS}
          label={OUTLINE_OUTPUT_FROMAT_TYPE_TO_STRING[OutlineOutputFormatType.BULLET_POINTS]}
        />
        <Radio
          mt='md'
          value={OutlineOutputFormatType.PARAGRAPH}
          label={OUTLINE_OUTPUT_FROMAT_TYPE_TO_STRING[OutlineOutputFormatType.PARAGRAPH]}
        />
      </RadioGroup>
    </ModalContent>
  );
};
export default SelectOutlineOutputFormat;
