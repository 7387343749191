/* eslint-disable max-len */

const AddParagraph: React.FC = () => {
  return (
    <svg width="17" height="12" viewBox="0 0 17 12" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
      <path d="M0.5 8.33333H6.33333V6.66667H0.5M13 6.66667V3.33333H11.3333V6.66667H8V8.33333H11.3333V11.6667H13V8.33333H16.3333V6.66667M9.66667 0H0.5V1.66667H9.66667M9.66667 3.33333H0.5V5H9.66667V3.33333Z"/>
    </svg>
  );
};

export default AddParagraph;
