/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/quotes */
import type { SVGProps } from "react";
const FormatListChecks = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill="#343A40"
      d="M3 5h6v6H3zm2 2v2h2V7zm6 0h10v2H11zm0 8h10v2H11zm-6 5-3.5-3.5 1.41-1.41L5 17.17l4.59-4.58L11 14z"
    />
  </svg>
);
export default FormatListChecks;
