import { Popover, ActionIcon, Button, Divider, Stack, Tooltip } from '@mantine/core';

import { useState } from 'react';

import { useSelector } from 'react-redux';

import { Pen, Trash, ThreeDotsVertical, Plus, AddSubsection } from '../../icons';
import classes from './DropdownMenus.module.css';
import { selectOutlineIsDirty } from '../../redux/store';
import { type SectionData } from '../../Pages/Editor/SectionEditor';

interface Props {
  onOpenDelete: (sectionUid: string) => void
  onOpenRename: (sectionUid: string) => void
  onOpenAddRequirement?: (sectionUid: string) => void
  onOpenAddSubsection: (parentSectionUid: string) => void
  isCurrentPage: boolean
  sections: SectionData[]
  sectionUid: string
  isSubsection: boolean
}

interface SectionDropdownItemProps {
  openDelete: () => void
  openRename: () => void
  openAddRequirement?: () => void
  openAddSubsection: () => void
  isCurrentPage: boolean
  isOnlyTopLevelSection: boolean
  isSubsection: boolean
}
const buttonClassNames = {
  inner: classes.popoverMenuButtonItem,
  section: classes.popoverMenuButtonLeftSection,
  root: classes.popoverMenuButtonItemRoot
};

export const SectionDropdownItems: React.FC<SectionDropdownItemProps> = (props: SectionDropdownItemProps) => {
  const {
    openDelete,
    isOnlyTopLevelSection,
    isCurrentPage,
    isSubsection,
    openRename,
    openAddSubsection,
    openAddRequirement
  } = props;
  const outlineIsDirty = useSelector(selectOutlineIsDirty);
  const currentPageUnsaved = isCurrentPage && outlineIsDirty;

  const deleteDisabled = (isOnlyTopLevelSection && !isSubsection) || currentPageUnsaved;
  const sectionCopy = isSubsection ? 'Subsection' : 'Section';

  return (
    <Stack gap={0}>
    {openAddRequirement !== undefined && <Tooltip
        events={{
          hover: currentPageUnsaved,
          focus: false,
          touch: false
        }}
        label= 'Save this section before adding a new requirement'
      >
        <Button
          variant="subtle"
          classNames={buttonClassNames}
          leftSection={<Plus />}
          disabled={currentPageUnsaved}
          onClick={(event) => { openAddRequirement(); event.stopPropagation(); }}
        >
          Add Requirement
        </Button>
      </Tooltip>}
      <Tooltip
        events={{
          hover: currentPageUnsaved,
          focus: false,
          touch: false
        }}
        label= 'Save this section before renaming'
      >
        <Button
          variant="subtle"
          classNames={buttonClassNames}
          leftSection={<Pen/>}
          disabled={currentPageUnsaved}
          onClick={(event) => { openRename(); event.stopPropagation(); }}
        >
          {`Rename ${sectionCopy}`}
        </Button>
      </Tooltip>
      <Divider />
      {!isSubsection && (
      <>
        <Button
          variant="subtle"
          classNames={buttonClassNames}
          leftSection={<AddSubsection/>}
          onClick={(event) => { openAddSubsection(); event.stopPropagation(); }}
        >
          Add Subsection
        </Button>
        <Divider/>
      </>
      )}
      <Tooltip
        events={{
          hover: deleteDisabled,
          focus: false,
          touch: false
        }}
        label= {isOnlyTopLevelSection ? 'Proposal must have one section' : 'Save this section before deleting'}
        >
          <Button
            onClick={(event) => { openDelete(); event.stopPropagation(); }}
            variant="subtle"
            classNames={{ ...buttonClassNames, root: classes.popoverMenuButtonItemRootRed }}
            leftSection={<Trash/>}
            disabled={deleteDisabled}
          >
            {`Delete ${sectionCopy}`}
          </Button>
      </Tooltip>
    </Stack>
  );
};

const SectionDropdown: React.FC<Props> = (props: Props) => {
  const { onOpenRename, onOpenAddRequirement, onOpenAddSubsection, sectionUid, onOpenDelete, sections } = props;
  const [opened, setOpened] = useState(false);
  const isOnlyTopLevelSection = sections.filter((section) => section.parentSectionUid === undefined).length < 2;
  return (
  <Popover
    opened={opened}
    onChange={setOpened}
    position="bottom-start"
    classNames={{ dropdown: classes.popoverDropdown }}
  >
    <Popover.Target>
      <ActionIcon
        onClick={(event) => { setOpened((o) => !o); event.stopPropagation(); }}
        c='var(--mantine-color-gray-5)'
        variant='subtle'
      >
        <ThreeDotsVertical/>
      </ActionIcon>
    </Popover.Target>
    <Popover.Dropdown>
      <SectionDropdownItems
      {...props}
        openAddRequirement={ onOpenAddRequirement !== undefined
          ? () => { onOpenAddRequirement(sectionUid); setOpened(false); }
          : undefined}
        openDelete={() => { onOpenDelete(sectionUid); setOpened(false); }}
        openRename={() => { onOpenRename(sectionUid); setOpened(false); }}
        openAddSubsection={() => { onOpenAddSubsection(sectionUid); setOpened(false); }}
        isOnlyTopLevelSection={isOnlyTopLevelSection}
      />
    </Popover.Dropdown>
  </Popover>
  );
};

export default SectionDropdown;
