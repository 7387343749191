/* eslint-disable @typescript-eslint/unbound-method */
import { type DocumentLoadEvent, Viewer, type PdfJs } from '@react-pdf-viewer/core';
import type { Match, RenderHighlightsProps } from '@react-pdf-viewer/search';
import { highlightPlugin } from '@react-pdf-viewer/highlight';
import type { RenderHighlightTargetProps } from '@react-pdf-viewer/highlight';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
import type { ToolbarSlot, ToolbarProps } from '@react-pdf-viewer/default-layout';
import { useCallback, forwardRef, useImperativeHandle, useEffect, useState, useMemo } from 'react';
import { Box, Button, Text } from '@mantine/core';

import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/highlight/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import './styles/index.css';
import type { OpportunityFile, OpportunityRequirement } from '../../types/apiTypes';
import { flipKeysAndValues } from '../../utils/objectUtils';
import { cleanPDFText } from '../../utils/stringUtils';
import { canRenderFile } from '../../utils/pdfUtils';
import CannotRenderFileView from './CannotRenderFileView';

export interface RequirementMatchData {
  requirementUid: string
  pageNumber: number
}

interface OpportunityFileViewerProps {
  requirements: OpportunityRequirement[]
  opportunityFile: OpportunityFile
  focusedRequirementUid: string
  onAddRequirement?: (requirementText: string, opportunityFileUid?: string) => void
  onClickHighlightedRequirement?: (requirementUid: string) => void
  onGetMatches?: (matches: RequirementMatchData[]) => void
  onStartDocumentLoad?: () => void
  onFinishDocumentLoad?: (e?: DocumentLoadEvent) => void
}

export interface OpportunityFileViewerRef {
  jumpToPage: (pageIndex: number) => void
}

const renderError = () => (
  <Box
      style={{
        alignItems: 'center',
        display: 'flex',
        height: '100%',
        justifyContent: 'center',
        backgroundColor: 'var(--mantine-color-gray-1)'
      }}
  >
      <Box
        mr={48}
        ml={48}
        p={16}
        style={{
          backgroundColor: 'var(--mantine-color-red-5)',
          borderRadius: 4
        }}
      >
        <Text c='white'>
          Failed to load document. Please refresh the page and email info@ensis.ai if the problem persists.
        </Text>
      </Box>
  </Box>
);

// https://react-pdf-viewer.dev/examples/create-a-toolbar-with-different-slots-for-the-default-layout/
const renderToolbar = (Toolbar: (props: ToolbarProps) => React.ReactElement) => (
  <Toolbar>
      {(slots: ToolbarSlot) => {
        const {
          CurrentPageInput,
          GoToNextPage,
          GoToPreviousPage,
          NumberOfPages,
          Zoom,
          ZoomIn,
          ZoomOut,
          Download
        } = slots;
        return (
            <div
                style={{
                  alignItems: 'center',
                  display: 'flex',
                  width: '100%',
                  backgroundColor: 'var(--mantine-color-greyPurple-1)'
                }}
            >
                <div style={{ padding: '0px 2px' }}>
                    <ZoomOut />
                </div>
                <div style={{ padding: '0px 2px' }}>
                    <Zoom />
                </div>
                <div style={{ padding: '0px 2px' }}>
                    <ZoomIn />
                </div>
                <div style={{ padding: '0px 2px', marginLeft: 'auto' }}>
                    <Download />
                </div>
                <div style={{ padding: '0px 2px' }}>
                    <GoToPreviousPage />
                </div>
                <div style={{ padding: '0px 2px', width: 50 }}>
                    <CurrentPageInput />
                </div>
                <div style={{ padding: '0px 2px' }}>
                  <Text>{' / '}</Text>
                </div>
                <div style={{ padding: '0px 2px' }}>
                    <NumberOfPages />
                </div>
                <div style={{ padding: '0px 2px' }}>
                    <GoToNextPage />
                </div>
            </div>
        );
      }}
  </Toolbar>
);

const MAX_REGEX_CHARS = 2000;

export const convertToRegEx = (text: string) => (
  text
    .substring(0, MAX_REGEX_CHARS)
    .replace(/[^A-Za-z0-9]+/gi, ' ')
    .trim()
    .split(' ')
    .join('([^A-Za-z0-9]*)')
);

const getRegexToUidMap = (requirements: OpportunityRequirement[]): Record<string, string> => {
  const result: Record<string, string> = {};
  requirements.forEach((req) => {
    const regExStr = convertToRegEx(req.source_text);
    result[regExStr] = req.uid;
  });
  return result;
};

const renderHighlightTarget = (
  renderProps: RenderHighlightTargetProps,
  onAddRequirement?: (selectedText: string, opportunityFileUid?: string) => void,
  opportunityFileUid?: string
) => onAddRequirement !== undefined
  ? (
      <div
          style={{
            display: 'flex',
            position: 'absolute',
            left: `${renderProps.selectionRegion.left}%`,
            top: `${renderProps.selectionRegion.top + renderProps.selectionRegion.height}%`,
            transform: 'translate(0, 8px)',
            zIndex: 10000
          }}
      >
        <Button
          style={{ zIndex: 10000 }}
          onClick={() => {
            renderProps.cancel();
            if (onAddRequirement !== undefined) {
              const cleanedText = cleanPDFText(renderProps.selectedText);
              onAddRequirement(cleanedText, opportunityFileUid);
            }
          }}
        >
          + Add Requirement
        </Button>
      </div>
    )
  : <></>;

const renderHighlights = (
  renderProps: RenderHighlightsProps,
  focusedKeyword: string,
  onClickHighlight: ((highlightedKeyword: string) => void)
) => (
    <>
        {renderProps.highlightAreas.map((area, index) => {
          const { keywordStr } = area;
          const isFocused = focusedKeyword === keywordStr;
          const highlightClassName = isFocused
            ? 'rpv-search__highlight--current_custom'
            : 'rpv-search__highlight';
          return (
            <div
                key={`${area.pageIndex}-${index}`}
                style={{
                  ...renderProps.getCssProperties(area),
                  position: 'absolute'
                }}
            >
              <div
                  className={highlightClassName}
                  data-index={index}
                  style={{
                    left: 0,
                    position: 'absolute',
                    top: 0,
                    height: '100%',
                    width: '100%',
                    cursor: 'pointer'
                  }}
                  onClick={() => { onClickHighlight(keywordStr); }}
              />
            </div>
          );
        })}
    </>
);

const matchSortingFn = (matchA: Match, matchB: Match): number => {
  if (matchA.pageIndex === matchB.pageIndex) {
    return matchA.startIndex - matchB.startIndex;
  }
  return matchA.pageIndex - matchB.pageIndex;
};

const OpportunityFileViewer = forwardRef<
OpportunityFileViewerRef,
OpportunityFileViewerProps
>((
  props: OpportunityFileViewerProps,
  ref
) => {
  const {
    requirements,
    focusedRequirementUid,
    onClickHighlightedRequirement,
    onGetMatches,
    onAddRequirement,
    opportunityFile,
    onStartDocumentLoad,
    onFinishDocumentLoad
  } = props;

  const [documentLoaded, setDocumentLoaded] = useState(false);
  const [shouldGetMatches, setShouldGetMatches] = useState(false);

  const regExToUidMap = getRegexToUidMap(requirements);
  const uidToRegExMap = flipKeysAndValues(regExToUidMap);
  const regExList = Object.keys(regExToUidMap).map((regexStr) => new RegExp(regexStr, 'gi'));
  const onClickHighlight = useCallback((keywordStr: string) => {
    if (onClickHighlightedRequirement !== undefined) {
      onClickHighlightedRequirement(regExToUidMap[keywordStr]);
    }
  }, [regExToUidMap]);
  const focusedKeyword = uidToRegExMap[focusedRequirementUid];

  const renderHighlightsFunc = useCallback(
    (renderProps: RenderHighlightsProps) => renderHighlights(renderProps, focusedKeyword, onClickHighlight),
    [onClickHighlight, focusedKeyword]
  );
  const renderHighlightTargetFunc = useCallback((renderProps: RenderHighlightTargetProps) => (
    renderHighlightTarget(renderProps, onAddRequirement, opportunityFile.uid ?? undefined)),
  [onAddRequirement]);

  const defaultLayoutPluginInstance = defaultLayoutPlugin({
    toolbarPlugin: {
      searchPlugin: {
        renderHighlights: renderHighlightsFunc
      }
    },
    sidebarTabs: (defaultTabs) => [],
    renderToolbar
  });

  const { toolbarPluginInstance } = defaultLayoutPluginInstance;
  const {
    searchPluginInstance,
    pageNavigationPluginInstance
  } = toolbarPluginInstance;
  const highlightPluginInstance = highlightPlugin({
    renderHighlightTarget: renderHighlightTargetFunc
  });

  useImperativeHandle(ref, () => ({
    jumpToPage: (pageIndex: number) => {
      pageNavigationPluginInstance.jumpToPage(pageIndex);
    }
  }));

  const fileIsRenderable = useMemo(
    () => canRenderFile(opportunityFile.file_name),
    [opportunityFile.file_name]
  );

  const handleGetMatches = useCallback(async () => {
    const matches = await searchPluginInstance.highlight(regExList);
    if (onGetMatches !== undefined) {
      // sort by location in PDF
      matches.sort(matchSortingFn);
      const matchData = matches.map((match) => ({
        requirementUid: regExToUidMap[match.keyword.source],
        pageNumber: match.pageIndex
      }));
      onGetMatches(matchData);
    }
  }, [regExList, searchPluginInstance, onGetMatches]);

  const handleViewerFinishedLoading = useCallback((e: DocumentLoadEvent) => {
    setShouldGetMatches(true);
    setDocumentLoaded(true);
    if (onFinishDocumentLoad !== undefined) {
      onFinishDocumentLoad(e);
    }
  }, []);

  // We want to reset shouldGetMatches to false so that highlights will
  // be re-fetched if document has to reload
  const handleDocumentLoaded = useCallback(async () => {
    await handleGetMatches();
    setShouldGetMatches(false);
  }, [handleGetMatches]);

  useEffect(() => {
    if (!fileIsRenderable && onFinishDocumentLoad !== undefined) {
      onFinishDocumentLoad();
    } else if (fileIsRenderable && onStartDocumentLoad !== undefined) {
      onStartDocumentLoad();
    }
  }, []);

  // Get matches on document load
  useEffect(() => {
    if (shouldGetMatches) {
      void handleDocumentLoaded();
    }
  }, [shouldGetMatches]);

  // Get matches on requirements change
  useEffect(() => {
    if (documentLoaded) {
      void handleGetMatches();
    }
  }, [requirements]);

  const pdfPlugins = [
    defaultLayoutPluginInstance,
    highlightPluginInstance
  ];

  if (!fileIsRenderable) {
    return <CannotRenderFileView downloadUrl={opportunityFile.url ?? ''} />;
  }

  return (
    <Viewer
      key={opportunityFile.uid}
      fileUrl={opportunityFile.pdf_url ?? ''}
      plugins={pdfPlugins}
      onDocumentLoad={handleViewerFinishedLoading}
      renderError={renderError}
      transformGetDocumentParams={(options: PdfJs.GetDocumentParams) =>
        Object.assign({}, options, {
          isEvalSupported: false
        })
      }
    />
  );
});

OpportunityFileViewer.displayName = 'OpportunityFileViewer';

export default OpportunityFileViewer;
