/* eslint-disable max-len */

const Plus: React.FC = () => (
  <svg width="18" height="18" viewBox="0 0 18 18" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
  <path d="M9.00008 1.41675V16.5834M1.41675 9.00008H16.5834" stroke='currentColor' strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>

);

export default Plus;
