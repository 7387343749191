import { useCallback, useState } from 'react';

import { Button, Chip, ChipGroup, Divider, Group, Pill, Popover, ScrollArea, Text } from '@mantine/core';

import { type CellContext } from '@tanstack/react-table';

import useEnsisMutation from '../../hooks/useEnsisMutation';
import { type ResourceRow } from '../../types/tableTypes';

import { type WithoutPermissions, type OrganizationResourceTag } from '../../types/apiTypes';
import { GreyPlus } from '../../icons';
import AddTagTextInput from './AddTagTextInput';
import { logAnalyticsEvent } from '../../utils/analyticsUtils';
import { tagMatchesCaseInsensitive } from '../../utils/stringUtils';

interface Props {
  info: CellContext<ResourceRow, string>
  organizationSlug: string
  tags: OrganizationResourceTag[]
  onEditResourceTags: () => void
}

const ResourceTableTags: React.FC<Props> = (props: Props) => {
  const { info, organizationSlug, tags, onEditResourceTags } = props;
  const [newTagText, setNewTagText] = useState('');
  const [tagUpdates, setTagUpdates] = useState<Array<WithoutPermissions<OrganizationResourceTag>>>(
    info.row.original.tags
  );
  const tagAlreadyExists = tagMatchesCaseInsensitive(tags, newTagText);

  const updateResourceTagsMutation = useEnsisMutation(
    `/app/organization-resources/${info.row.original.uid}/data`,
    {
      requestType: 'patch',
      queryKeysToInvalidate: ['/app/organization-resources', '/app/organization-resource-tags'],
      showSuccessMessage: false,
      awaitRefetch: true
    }
  );

  const createResourceTagMutation = useEnsisMutation(
    '/app/organization-resource-tags',
    {
      requestType: 'post',
      queryKeysToInvalidate: ['/app/organization-resources', '/app/organization-resource-tags'],
      successMessage: 'Resource tag created',
      awaitRefetch: true
    }
  );

  const handleAddTag = useCallback(async () => {
    if (!tagAlreadyExists && !createResourceTagMutation.isPending &&
      !updateResourceTagsMutation.isPending) {
      logAnalyticsEvent('CreateTag');
      const newTags = await createResourceTagMutation.mutateAsync({
        organization_slug: organizationSlug,
        texts: [newTagText]
      });
      updateResourceTagsMutation.mutate({
        tag_uids: tagUpdates.map(t => t.uid ?? '').concat(newTags[0].uid ?? '')
      });
    }
  }, [
    createResourceTagMutation.mutateAsync,
    updateResourceTagsMutation.mutate,
    createResourceTagMutation.isPending,
    updateResourceTagsMutation.isPending,
    newTagText,
    tagUpdates
  ]);

  const handleUpdateTags = useCallback(() => {
    updateResourceTagsMutation.mutate({
      tag_uids: tagUpdates.map(t => t.uid ?? '')
    });
  }, [updateResourceTagsMutation.mutate, tagUpdates]);

  const onRemoveTag = useCallback((tagUid: string) => {
    updateResourceTagsMutation.mutate({
      tag_uids: tagUpdates
        .filter(t => t.uid !== tagUid)
        .map(t => t.uid ?? '')
    });
  }, [updateResourceTagsMutation.mutate, tagUpdates]);

  const resourceTagChips = (
    <ChipGroup multiple>
      <Group mt={0} mb={16} pt={16} pb={16} pr={14} pl={14}>
        {tags?.map((tag) => {
          const isChecked = tagUpdates.some(t => t.uid === tag.uid);
          const tagUpdatesWithToggledTag = isChecked
            ? tagUpdates.filter(t => t.uid !== tag.uid)
            : tagUpdates.concat(tag);
          return (
            <Chip
              key={tag.uid}
              variant='outline'
              icon={<></>}
              styles={{
                iconWrapper: {
                  display: 'none'
                },
                label: {
                  backgroundColor: isChecked ? 'var(--mantine-color-greyPurple-1)' : 'transparent',
                  padding: '0px 20px'
                }
              }}
              checked={isChecked}
              onClick={() => {
                logAnalyticsEvent('AddTag');
                setTagUpdates(tagUpdatesWithToggledTag);
              }}
            >
              <Text truncate='end' maw={272} fz={14} styles={{
                root: {
                  color: 'var(--mantine-color-lightPurple-5)'
                }
              }}>
                {tag.text}
              </Text>
            </Chip>
          );
        })}
      </Group>
    </ChipGroup>
  );

  const inputIsEmpty = newTagText === '';
  const popover = (
    <Popover
      onClose={() => {
        handleUpdateTags();
      }}
      width={400}
      position='bottom-start'
      shadow='md'
    >
      <Popover.Target>
        <Button
          variant='outline'
          fz={16}
          fw={400}
          leftSection={<GreyPlus />}
          styles={{
            label: {
              color: 'var(--mantine-color-greyPurple-3)'
            },
            root: {
              backgroundColor: 'transparent',
              border: '1px solid var(--mantine-color-greyPurple-3)',
              borderRadius: 20,
              height: 28
            }
          }}>
          Add Tag
        </Button>
      </Popover.Target>
      <Popover.Dropdown>
        <AddTagTextInput
          handleAddTag={() => { void handleAddTag(); }}
          setNewTagText={setNewTagText}
          newTagText={newTagText}
          tagAlreadyExists={tagAlreadyExists}
        />
        <Divider mt={16}/>
        <ScrollArea.Autosize mah={400}>
          {resourceTagChips}
        </ScrollArea.Autosize>
        <Divider />
        <Button disabled={!inputIsEmpty} fullWidth mt={16} onClick={onEditResourceTags}>
          Manage Tags
        </Button>
      </Popover.Dropdown>
    </Popover>
  );

  const resourceTagPills = info.row.original.tags.map(
    (tag) => (
      <Pill
        key={tag.uid}
        onRemove={() => { onRemoveTag(tag.uid ?? ''); }}
      >
        <Text truncate="end" maw="30vw" styles={{
          root: {
            color: 'var(--mantine-color-lightPurple-5)'
          }
        }}>
          {tag.text}
        </Text>
      </Pill>
    )
  );

  return (
    <Pill.Group
      p={8}
    >
      {popover}
      {resourceTagPills}
    </Pill.Group>
  );
};

export default ResourceTableTags;
