import { useParams } from 'react-router-dom';

import FactsTable from '../../components/FactsTable';

const ProposalFactsSection: React.FC = () => {
  const { proposalUid } = useParams();
  return (
    <FactsTable proposalUid={proposalUid} />
  );
};

export default ProposalFactsSection;
