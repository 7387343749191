import { useDispatch } from 'react-redux';
import { useCallback } from 'react';

import {
  updateText as updateOutlineText
} from '../../../redux/OutlineEditorSlice';
import ResponseTextEditor from './ResponseTextEditor';
import classes from './ResponseEditor.module.css';
import { type RequirementResponse } from '../../../types/apiTypes';
import { withAnalyticsEvent } from '../../../utils/analyticsUtils';

interface Props {
  proposalUid: string
  canEdit: boolean
  responseData: RequirementResponse
  shouldShowWarning: boolean
  outlineOutputFormat: string
  setShouldShowWarningFalse: () => void
  onGenerateText: () => void
};
const OutlineResponseEditor: React.FC<Props> = (props: Props) => {
  const dispatch = useDispatch();
  const { responseData, onGenerateText } = props;

  const onEditorUpdate = useCallback((newContent: string) => {
    dispatch(updateOutlineText({
      uid: responseData?.uid ?? '',
      content: newContent,
      originalContent: responseData?.content ?? undefined
    }));
  }, [responseData?.content, responseData?.uid]);

  return (
    <>
      <ResponseTextEditor
        {...props}
        requirementResponseUid={responseData?.uid ?? ''}
        initialContent={responseData?.content ?? ''}
        proposalSectionUid={responseData?.proposal_section?.uid ?? ''}
        onEditorUpdate={onEditorUpdate}
        contentStyleName={classes.content}
        onGenerateText={withAnalyticsEvent(onGenerateText, 'GenerateResponse')}
      />
    </>
  );
};

export default OutlineResponseEditor;
