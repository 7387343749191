import { Text } from '@mantine/core';
import {
  type ColumnDef,
  createColumnHelper,
  type Row
} from '@tanstack/react-table';
import { useAuth0 } from '@auth0/auth0-react';

import { ThreeDotsMenu } from '../components';
import { type TableRows, type CurrentUserRow } from '../types/tableTypes';
import { type components } from '../generated/schema';
import useOrgPermissions from './useOrgPermissions';
import { formatRole } from '../utils/stringUtils';
import { SwitchUser, Minus as MinusIcon } from '../icons';
import usePaginatedTable from './usePaginatedTable';

const DEFAULT_SORTING_FIELD = 'lastName';
const COLUMN_NAME_TO_DB_FIELD_MAP = new Map<string, string>([
  ['uid', 'uid'],
  ['firstName', 'first_name'],
  ['lastName', 'last_name'],
  ['email', 'email'],
  ['role', 'role']
]);
const DEFAULT_SORT_DIRECTION = 'ASC';

const formatCurrentUserData = (
  currentUserData: Array<components['schemas']['UserSchema']>
): CurrentUserRow[] => currentUserData?.map((user) => ({
  uid: user.uid ?? '',
  firstName: (user.first_name === '' ||
    user.first_name === null ||
    user.first_name === undefined)
    ? '-'
    : user.first_name,
  lastName: (user.last_name === '' ||
    user.last_name === null ||
    user.last_name === undefined)
    ? '-'
    : user.last_name,
  email: user.email ?? '',
  role: user.role ?? '-',
  isClickable: false
}));

const useCurrentUserTableData = (
  organizationSlug: string,
  openDelete: (row: Row<CurrentUserRow>) => void,
  openEditRole: (row: Row<CurrentUserRow>) => void
) => {
  const { userHasPermission } = useOrgPermissions();
  const { user } = useAuth0();

  const getMenuItems = (row: Row<CurrentUserRow>) => ([
    {
      label: 'Remove',
      onClick: () => { openDelete(row); },
      shouldRender: userHasPermission('remove_user'),
      icon: <MinusIcon />
    },
    {
      label: 'Edit role',
      onClick: () => { openEditRole(row); },
      shouldRender: userHasPermission('change_user_role'),
      icon: <SwitchUser />
    }
  ]);

  const columnHelper = createColumnHelper<CurrentUserRow>();

  const columns = [
    columnHelper.accessor('firstName', {
      header: 'FIRST NAME',
      cell: info => <Text> {info.getValue()} </Text>,
      size: 30
    }),
    columnHelper.accessor('lastName', {
      header: 'LAST NAME',
      cell: info => <Text> {info.getValue()} </Text>,
      size: 30
    }),
    columnHelper.accessor('email', {
      header: 'EMAIL',
      cell: info => <Text> {info.getValue()} </Text>,
      size: 37
    }),
    columnHelper.accessor('role', {
      header: 'ROLE',
      enableSorting: false,
      cell: info => <Text> {formatRole(info.getValue())}</Text>
    }),
    columnHelper.display({
      id: 'actions',
      cell: info => {
        const isMe = info.row.original.uid === user?.sub;
        const menuItems = getMenuItems(info.row).filter((item) => item.shouldRender);
        const shouldShowMenu = menuItems.length > 0 && !isMe;
        return shouldShowMenu && (
          <ThreeDotsMenu orientation='horizontal' menuItems={menuItems}/>
        );
      },
      size: 3
    })
  ] as Array<ColumnDef<TableRows>>;

  return usePaginatedTable({
    defaultSortingField: DEFAULT_SORTING_FIELD,
    defaultSortDirection: DEFAULT_SORT_DIRECTION,
    route: '/app/users',
    formatData: formatCurrentUserData,
    columnNameToDBFieldMap: COLUMN_NAME_TO_DB_FIELD_MAP,
    columnData: columns,
    queryParams: {
      organization_slug: organizationSlug ?? ''
    }
  });
};

export default useCurrentUserTableData;
