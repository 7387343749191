import { Group, Text, Box, Tooltip } from '@mantine/core';
import { useHover } from '@mantine/hooks';

import { useSelector } from 'react-redux';

import { useCallback, useMemo } from 'react';

import { type RequirementResponse } from '../../types/apiTypes';
import { selectNarrativeCompliance } from '../../redux/store';
import { getRequirementText } from '../../utils/stringUtils';
import { ComplianceStatusMenu } from '../DropdownMenus';
import { getRequirementIdentifierSpan } from '../../utils/requirementUtils';

interface Props {
  response: RequirementResponse
  onTextClick: () => void
  sectionIsEmpty: boolean
  complianceCheckIsRunning: boolean
}
export type ComplianceStatus = 'linked' | 'noLink' | 'markedCompliant';

const ComplianceModeRequirement: React.FC<Props> = (props: Props) => {
  const { response, onTextClick, complianceCheckIsRunning, sectionIsEmpty } = props;
  const { hovered, ref: hoverRef } = useHover();

  const complianceSlice = useSelector(selectNarrativeCompliance);
  const selectedResponseUid = complianceSlice.selectedResponseUid;

  const isSelected = selectedResponseUid === response.uid;

  const complianceStatus: ComplianceStatus = useMemo(() => {
    if (!(response.is_compliant ?? false)) {
      return 'noLink';
    } else if (response.section_text_references === undefined || response.section_text_references.length === 0) {
      return 'markedCompliant';
    }
    return 'linked';
  }, [response]);

  const canClickText = complianceStatus === 'linked' && !complianceCheckIsRunning;

  const onClick = useCallback(() => {
    if (complianceStatus === 'linked') {
      onTextClick();
    }
  }, [complianceStatus, onTextClick]);

  return (
    <Box
      ref={hoverRef}
      p='6.5px 16px'
      bg={isSelected ? 'var(--mantine-color-green-1)' : ''}
      onClick={onClick}
    >
      <Tooltip label='View in Narrative' events={{ hover: hovered && canClickText, focus: false, touch: false }}>
        <Group justify="space-between">
          <Text maw='calc(100% - 60px)' td={(hovered && canClickText) ? 'underline' : undefined}>
            {getRequirementIdentifierSpan(response.requirement?.identifier ?? '')}
            {getRequirementText(response.requirement?.text ?? '', sectionIsEmpty)}
          </Text>
          <ComplianceStatusMenu
            complianceStatus={complianceStatus}
            requirementResponseUid={response.uid}
            disabled={complianceCheckIsRunning}
          />
        </Group>
      </Tooltip>
    </Box>
  );
};

export default ComplianceModeRequirement;
