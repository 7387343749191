import { Box, Center, Text, Image, Title, Stack } from '@mantine/core';
import { useAuth0 } from '@auth0/auth0-react';

import Logo from '../assets/ensis-logo.svg';

const NoOrganizationPage = () => {
  const { logout } = useAuth0();
  return (
    <Box h='100vh'>
      <Image src={Logo} w={160} />
      <Center>
      <Center h={254} mt='200px' w={900} p={16} style={{ borderRadius: 4 }} bg='var(--mantine-color-greyPurple-0)'>
        <Stack align="center">
          <Title order={4}>
          You do not currently belong to an organization.
          </Title>
          <Text maw={555}>
            Please contact your organization admin to be
            added to your organization, or contact info@ensis.ai for more information.
          </Text>
          <Text fz='sm' td='underline' style={{ cursor: 'pointer' }} onClick={() => {
            logout({
              logoutParams: { returnTo: window.location.origin }
            }).catch(() => {});
          }}
          >
            Return to login
          </Text>
        </Stack>
      </Center>
      </Center>
    </Box>
  );
};

export default NoOrganizationPage;
