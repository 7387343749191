import { useCallback } from 'react';
import { isNotEmpty, useForm } from '@mantine/form';
import { Title, Button, TextInput, LoadingOverlay, Tooltip, Modal, ScrollArea } from '@mantine/core';

import { useDisclosure } from '@mantine/hooks';

import classes from './UserProfile.module.css';
import useEnsisQuery from '../../../hooks/useEnsisQuery';
import type { GetResponseDataType } from '../../../types/apiTypes';
import useEnsisMutation from '../../../hooks/useEnsisMutation';
import useOrgPermissions from '../../../hooks/useOrgPermissions';
import { formatRole } from '../../../utils/stringUtils';
import { defaultModalProps } from '../../../utils/mantineUtils';
import ModalContent from '../../ModalContent';

interface FormValues {
  firstName: string
  lastName: string
  email: string
}

const UserProfile: React.FC = () => {
  const { role } = useOrgPermissions();
  const [opened, { close, open }] = useDisclosure();

  const form = useForm({
    initialValues: {
      firstName: '',
      lastName: '',
      email: ''
    },
    validate: {
      firstName: isNotEmpty('Please input first name'),
      lastName: isNotEmpty('Please input last name'),
      email: isNotEmpty()
    }
  });

  const onQuerySuccess = useCallback(
    (data: GetResponseDataType<'/app/users/me'>) => {
      form.setInitialValues({
        email: data.email,
        firstName: data.first_name ?? '',
        lastName: data.last_name ?? ''
      });
      form.reset();
    },
    []
  );

  const { isLoading } = useEnsisQuery('/app/users/me', { onSuccess: onQuerySuccess });
  const editUserMutation = useEnsisMutation(
    '/app/users/edit',
    {
      contentType: 'application/json',
      requestType: 'patch',
      showSuccessMessage: false,
      onSuccess: open,
      queryKeysToInvalidate: ['/app/users/me']
    }
  );

  const onSubmit = useCallback((values: FormValues) => {
    editUserMutation.mutate({
      first_name: values.firstName,
      last_name: values.lastName
    });
  }, [editUserMutation.mutate]);

  const roleName = formatRole(role);
  return (
    <ScrollArea w={650} type='hover' style={{ height: 'calc(100vh - 250px)' }} >
    <Modal opened={opened} {...defaultModalProps}>
      <ModalContent
        title='Profile updated'
        primaryButton={{
          label: 'Continue',
          onClick: close
        }}
      >
      <TextInput
        classNames={{ input: classes.modalTextInput }}
        placeholder='First Name'
        label='First Name'
        c={'black'}
        disabled
        w={548}
        mb={16}
        {...form.getInputProps('firstName')}
      />
      <TextInput
        classNames={{ input: classes.modalTextInput }}
        placeholder='Last Name'
        label='Last Name'
        disabled
        w={548}
        mb={16}
        {...form.getInputProps('lastName')}
      />

      </ModalContent>
    </Modal>
    <LoadingOverlay visible={isLoading} />
    <Title order={6} mb={16}>
      Basic Information
    </Title>
    <form onSubmit={form.onSubmit(onSubmit)}>
      <TextInput
        className={classes.root}
        placeholder='First Name'
        label='First Name'
        w={548}
        mb={16}
        {...form.getInputProps('firstName')}
      />
      <TextInput
        placeholder='Last Name'
        label='Last Name'
        w={548}
        mb={16}
        {...form.getInputProps('lastName')}
      />
      <Tooltip
        events={{
          hover: true,
          focus: false,
          touch: false
        }}
        label='Need to edit? Contact us at info@ensis.ai'
      >
        <TextInput
          className={classes.root}
          label='Email'
          w={548}
          mb={16}
          disabled
          {...form.getInputProps('email')}
        />
      </Tooltip>
      <Title order={6} mb={16}>
        User Role
      </Title>
      <Tooltip
        events={{
          hover: true,
          focus: false,
          touch: false
        }}
        label='To change your role, contact an admin in your organization.'
      >
        <TextInput
          className={classes.root}
          w={188}
          mb={16}
          disabled
          value={roleName}
        />
      </Tooltip>
      <Button
        className={classes.root}
        w={138}
        loading={editUserMutation.isPending}
        disabled={!form.isDirty()}
        type="submit"
      >
        Save profile
      </Button>
    </form>
  </ScrollArea>);
};

export default UserProfile;
