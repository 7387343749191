import { isNotEmpty, useForm } from '@mantine/form';

import { Checkbox, TextInput, LoadingOverlay, Text, Anchor } from '@mantine/core';

import { useCallback } from 'react';

import Lottie from 'lottie-react';

import ModalContent from '../../ModalContent';
import useEnsisMutation from '../../../hooks/useEnsisMutation';
import { profileConfirmAnimation } from '../../../assets/lottie';

interface Props {
  close: () => void
}

interface FormValues {
  firstName: string
  lastName: string
  termsOfUse: boolean
}
const Onboarding: React.FC<Props> = (props: Props) => {
  const editUserMutation = useEnsisMutation(
    '/app/users/edit',
    {
      contentType: 'application/json',
      requestType: 'patch',
      successMessage: 'User profile updated',
      queryKeysToInvalidate: ['/app/users/me']
    }
  );

  const form = useForm({
    initialValues: {
      firstName: '',
      lastName: '',
      termsOfUse: false
    },
    validate: {
      firstName: isNotEmpty(),
      lastName: isNotEmpty(),
      termsOfUse: (value) => !value
    }
  });

  const onSubmitForm = useCallback((values: FormValues) => {
    editUserMutation.mutate({
      first_name: values.firstName,
      last_name: values.lastName
    });
  }, [editUserMutation.mutate]);

  const checkBoxLabel = (
    <Text fz='xs'>
      By checking this box, you consent to the Ensis{' '}
      <Anchor fz='xs' href="https://www.ensis.ai/terms-conditions" target="_blank" inherit>
        Terms & Conditions
      </Anchor>
      {' and '}
      <Anchor fz='xs' href="https://www.ensis.ai/privacy-policy" target="_blank" inherit>
        Privacy Policy
      </Anchor>
      <Text span inherit fz='xs' c='red'> * </Text>
    </Text>
  );

  if (editUserMutation.isSuccess) {
    return (
    <ModalContent
      title={`Welcome to Ensis, ${form.values.firstName} ${form.values.lastName}`}
      primaryButton={{ label: 'Continue', onClick: props.close }}
    >
      <Lottie animationData={profileConfirmAnimation} style={{ height: 300 }}/>
    </ModalContent>);
  }

  return (
  <ModalContent
    title="Complete your profile"
    subtitle="Add your name, so your team knows it’s you"
    primaryButton={{
      label: 'Save profile',
      onClick: form.onSubmit(onSubmitForm),
      disabled: !form.isValid()
    }}
  >
    <LoadingOverlay visible={editUserMutation.isPending} zIndex={1000}/>
    <TextInput
      placeholder="First name"
      label="First name"
      h={42}
      mb={50}
      w={462}
      required
      {...form.getInputProps('firstName')}
    />
    <TextInput
      placeholder="Last name"
      label="Last name"
      h={42}
      w={462}
      mb={50}
      required
      {...form.getInputProps('lastName')}
    />
    <Checkbox
    styles={{
      input: {
        borderRadius: 4
      }
    }}
      {...form.getInputProps('termsOfUse')}
      label={checkBoxLabel}
    />
  </ ModalContent>);
};

export default Onboarding;
