import { Group, Button, Text } from '@mantine/core';
import { type Table as TanstackTable } from '@tanstack/react-table';

import { type TableRows } from '../../types/tableTypes';
import classes from './Pagination.module.css';

interface PaginationProps <T> {
  table: TanstackTable<T>
};

const Pagination = <T extends TableRows > (props: PaginationProps<T>) => {
  const { table } = props;
  return (
    <>
    {table.getRowModel().rows.length === 0
      ? <></>
      : <Group justify='center' gap='100px'>
      <Button
        disabled={!props.table.getCanPreviousPage()}
        onClick={() => { props.table.previousPage(); }}
        classNames= {{ root: classes.paginationButton }}
        variant='subtle'
      > {'< Back'}
      </Button>
      <Text fz='sm'>
       {`${props.table.getState().pagination.pageIndex + 1}/${props.table.getPageCount()}`}
      </Text>
      <Button
        disabled={!props.table.getCanNextPage()}
        onClick={() => { props.table.nextPage(); }}
        variant='subtle'
        classNames= {{ root: classes.paginationButton }}
      >
          { 'Next >' }
      </Button>
    </Group>
  }
    </>
  );
};

export default Pagination;
