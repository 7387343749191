import { RichTextEditor, useRichTextEditorContext } from '@mantine/tiptap';

import GenerateControl from '../GenerationFlow/GenerateControl';
import RevisionControl from '../GenerationFlow/RevisionControl';

interface Props {
  regenerateDisabled: boolean
  onClickRegenerate: () => void
  onClickGenerate: () => void
}
const ResponseEditorToolbar: React.FC<Props> = (props: Props) => {
  const { editor } = useRichTextEditorContext();
  const {
    onClickGenerate,
    onClickRegenerate,
    regenerateDisabled
  } = props;

  const firstItemBorderRadius = { borderRadius: '4px 0px 0px 4px' };
  const lastItemBorderRadius = { borderRadius: '0px 4px 4px 0px' };

  return (
    <RichTextEditor.Toolbar>
      <RichTextEditor.ControlsGroup>
        <RichTextEditor.Bold style={firstItemBorderRadius}/>
        <RichTextEditor.Italic />
        <RichTextEditor.Underline />
        <RichTextEditor.Strikethrough style={lastItemBorderRadius}/>
      </RichTextEditor.ControlsGroup>
        <RichTextEditor.ControlsGroup>
        <RichTextEditor.H1 style={firstItemBorderRadius}/>
        <RichTextEditor.H2 />
        <RichTextEditor.H3 />
        <RichTextEditor.H4 style={lastItemBorderRadius}/>
      </RichTextEditor.ControlsGroup>
      <RichTextEditor.ControlsGroup>
        <RichTextEditor.BulletList style={firstItemBorderRadius}/>
        <RichTextEditor.OrderedList style={lastItemBorderRadius}/>
      </RichTextEditor.ControlsGroup>
      <RichTextEditor.ControlsGroup>
        <RichTextEditor.AlignLeft style={firstItemBorderRadius}/>
        <RichTextEditor.AlignCenter />
        <RichTextEditor.AlignRight style={lastItemBorderRadius}/>
      </RichTextEditor.ControlsGroup>
      <RichTextEditor.ControlsGroup>
        <RichTextEditor.Superscript style={firstItemBorderRadius}/>
        <RichTextEditor.Subscript style={lastItemBorderRadius}/>
      </RichTextEditor.ControlsGroup>
      <RichTextEditor.ControlsGroup>
        {editor?.getText().length === 0
          ? <GenerateControl
              onClickGenerate={onClickGenerate}
            />
          : <RevisionControl
              onClickRegenerate={onClickRegenerate}
              disabled={regenerateDisabled}
            />
        }
      </RichTextEditor.ControlsGroup>
    </RichTextEditor.Toolbar>
  );
};
export default ResponseEditorToolbar;
