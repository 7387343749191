/* eslint-disable max-len */
import React from 'react';

const MinusIcon: React.FC = () => (
  <svg
    width="24"
    height="25"
    viewBox="0 0 24 25"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M19 13.5H5V11.5H19V13.5Z" />
  </svg>
);

export default MinusIcon;
