import { LoadingOverlay, TextInput } from '@mantine/core';

import { useCallback, useState } from 'react';

import { type AxiosError } from 'axios';

import { useParams } from 'react-router-dom';

import { useDispatch } from 'react-redux';

import ModalContent from '../ModalContent';
import useEnsisMutation from '../../hooks/useEnsisMutation';
import { isEmpty } from '../../utils/stringUtils';
import { handlePotentialLockError } from '../../utils/apiUtils';
import { type SectionData } from '../../Pages/Editor/SectionEditor';
import { editSectionName } from '../../redux/OutlineSlice';

interface Props {
  isOutline?: boolean
  sectionToEdit?: SectionData
  sections: SectionData[]
  close: () => void
}
const RenameSection: React.FC<Props> = (props: Props) => {
  const { sectionToEdit, isOutline = false, close, sections } = props;
  const [sectionTitle, setSectionTitle] = useState(sectionToEdit?.title ?? '');
  const { proposalUid } = useParams();
  const dispatch = useDispatch();

  const onRenameSectionError = useCallback((error: AxiosError) => {
    handlePotentialLockError(error, 'Unable to rename section: A teammate is currently editing this section');
  }, []);

  const renameSectionMutation = useEnsisMutation(`/app/proposal-sections/${sectionToEdit?.uid}/data`, {
    requestType: 'patch',
    successMessage: 'Section renamed',
    onSuccess: close,
    onError: onRenameSectionError,
    queryKeysToInvalidate: [`/app/proposals/${proposalUid}/data`]
  });

  const handleRenameSection = useCallback(() => {
    renameSectionMutation.mutate({
      title: sectionTitle
    }, {
      onSuccess: (data) => {
        if (isOutline) {
          const formattedData: SectionData = {
            uid: data.uid,
            ordinal: data.ordinal,
            slug: data.slug,
            title: data.title,
            parentSectionUid: data.parent_proposal_section_uid ?? undefined
          };
          dispatch(editSectionName(formattedData));
        }
      }
    });
  }, [renameSectionMutation, sectionTitle]);

  const sectionTitles = sections
    .filter((section) => section.parentSectionUid === sectionToEdit?.parentSectionUid).filter(
      (section) => section.uid !== sectionToEdit?.uid
    )
    .map((section) => section.title.toLowerCase());

  const isDuplicateTitle = sectionTitles.includes(sectionTitle.toLowerCase());
  const sectionCopy = sectionToEdit?.parentSectionUid !== undefined ? 'subsection' : 'section';
  return (
    <ModalContent
      title={`Rename ${sectionCopy}`}
      subtitle={`Enter a new title for this ${sectionCopy}`}
      onClose={close}
      primaryButton={{
        label: 'Confirm',
        onClick: handleRenameSection,
        disabled: isEmpty(sectionTitle) || sectionTitle === sectionToEdit?.title || isDuplicateTitle
      }}
      secondaryButton={{ label: 'Cancel', onClick: close }}
    >
      <LoadingOverlay zIndex={1000} visible={renameSectionMutation.isPending}/>
      <TextInput
        value={sectionTitle}
        onChange={(event) => { setSectionTitle(event.currentTarget.value); }}
        error={isDuplicateTitle && `${sectionCopy} with this title already exists`}
      />
    </ModalContent>
  );
};

export default RenameSection;
