import { Checkbox, Text } from '@mantine/core';
import {
  createColumnHelper,
  type Row
} from '@tanstack/react-table';
import { useState, useCallback, useEffect } from 'react';

import { ResourceTableTagsContainer, ThreeDotsMenu } from '../components';
import { type ResourceRow } from '../types/tableTypes';
import { Trash, Pen, Download, Checkmark } from '../icons';
import type {
  ProcessingStatusType,
  OrganizationResource,
  GetRequestQueryParamsType,
  OrganizationResourceTag
} from '../types/apiTypes';
import { hasPermissionsForObject } from '../utils/apiUtils';
import { RESOURCE_TYPE_TO_STRING_MAP } from '../utils/constants';
import { type ResourceType } from '../types/miscTypes';
import usePaginatedTable from './usePaginatedTable';
import { getDateStringForTableCell } from '../utils/tableUtils';
import useEnsisQuery from './useEnsisQuery';

const RESOURCE_REFETCH_INTERVAL_MS = 10000;

const DEFAULT_SORTING_FIELD = 'dateAdded';
const DEFAULT_SORTING_DIRECTION = 'DESC';
const DEFAULT_PAGE_SIZE = 8;
const COLUMN_NAME_TO_DB_FIELD_MAP = new Map<string, string>([
  ['uid', 'uid'],
  ['fullResourceName', 'name'],
  ['dateAdded', 'created_at'],
  ['description', 'description'],
  ['processingStatus', 'processing_status']
]);

interface resourceTableConfig {
  organizationSlug: string
  onEdit?: (row: Row<ResourceRow>) => void
  onDelete?: (row: Row<ResourceRow>) => void
  onDownload?: (row: Row<ResourceRow>) => void
  onEditResourceTags: () => void
  searchTags?: string[]
  proposalUid?: string
  resourceTypes?: ResourceType[]
  organizationResourceTags: OrganizationResourceTag[]
  selectMode: 'hidden' | 'view' | 'edit'
  referenceUids?: string[]
}

const formatResourceData = (
  resourceData: OrganizationResource[],
  selectMode: 'hidden' | 'view' | 'edit'
): ResourceRow[] => resourceData
  ?.filter((resource) => resource.processing_status !== 'FAILED')
  ?.map((resource) => ({
    uid: resource.uid ?? '',
    resourceName: resource.name ?? '',
    dateAdded: resource.created_at != null ? new Date(resource.created_at) : null,
    description: resource.description ?? '-',
    tags: resource.tags ?? [],
    processingStatus: resource.processing_status as ProcessingStatusType ?? null,
    isClickable: selectMode === 'edit',
    url: resource.url ?? '',
    resourceType: resource.resource_type as ResourceType ?? 'DEFAULT',
    canEdit: hasPermissionsForObject(resource, 'change'),
    canDelete: hasPermissionsForObject(resource, 'delete')
  }));

const useResourceTableData = (config: resourceTableConfig) => {
  const {
    organizationSlug,
    onEdit,
    onDelete,
    onDownload,
    onEditResourceTags,
    searchTags,
    resourceTypes,
    proposalUid,
    organizationResourceTags,
    selectMode,
    referenceUids
  } = config;

  const shouldShowMenu = selectMode !== 'edit';

  const getQueryParams = useCallback(() => {
    const queryParams: GetRequestQueryParamsType<'/app/organization-resources'> = {
      organization_slug: organizationSlug ?? '',
      generate_download_url: true,
      exclude_failed_resources: true
    };
    if (searchTags !== undefined && searchTags.length > 0) {
      queryParams.search_tags = searchTags.join('|');
    }
    if (resourceTypes !== undefined && resourceTypes.length > 0) {
      queryParams.resource_types = resourceTypes.join('|');
    }
    if (proposalUid !== undefined && proposalUid !== '') {
      queryParams.proposal_uid = proposalUid;
    }
    return queryParams;
  }, [searchTags, resourceTypes, organizationSlug, proposalUid]);

  const [totalNumberOfResources, setTotalNumberOfResources] = useState<number | null>(null);
  const [refetchingEnabled, setRefetchingEnabled] = useState(false);

  const organizationTagsSorted = [...organizationResourceTags].sort((a, b) => a.text.localeCompare(b.text));

  const menuItems = useCallback((row: Row<ResourceRow>) => {
    const itemsList = [];
    if (onDownload !== undefined) {
      itemsList.push({
        label: 'Download',
        onClick: () => { onDownload(row); },
        shouldRender: true,
        icon: <Download />
      });
    }
    if (row.original.canEdit && onEdit !== undefined) {
      itemsList.push({
        label: 'Edit',
        onClick: () => { onEdit(row); },
        shouldRender: true,
        icon: <Pen />
      });
    }
    if (row.original.canDelete && onDelete !== undefined) {
      itemsList.push({
        label: 'Remove',
        onClick: () => { onDelete(row); },
        shouldRender: true,
        icon: <Trash />
      });
    }
    return itemsList;
  }, [onDelete, onEdit]);

  const columnHelper = createColumnHelper<ResourceRow>();

  const columns = [
    columnHelper.accessor(row => `${row.resourceName}`, {
      id: 'fullResourceName',
      header: 'NAME',
      cell: info => (
        <Text fz='md' fw='600'>
          {info.row.original.resourceName}
        </Text>
      ),
      sortingFn: 'text',
      size: selectMode !== 'view' ? 35 : 27
    }),
    columnHelper.accessor('description', {
      header: 'DESCRIPTION',
      cell: info => <Text> {info.getValue()} </Text>,
      sortingFn: 'text',
      size: selectMode !== 'view' ? 34 : 27
    }),
    columnHelper.accessor('dateAdded', {
      header: 'DATE ADDED',
      cell: info => <Text> {getDateStringForTableCell(info.getValue())} </Text>,
      sortingFn: 'datetime',
      size: 15
    }),
    columnHelper.accessor('resourceType', {
      id: 'bottomResourceType',
      header: '',
      cell: info => (
        <Text>
          {RESOURCE_TYPE_TO_STRING_MAP[info.getValue() as ResourceType]}
        </Text>
      ),
      size: 0
    }),
    columnHelper.accessor(row => 'tags', {
      id: 'bottomTags',
      header: '',
      cell: info => (
        <ResourceTableTagsContainer
          info={info}
          organizationSlug={organizationSlug}
          tags={organizationTagsSorted ?? []}
          onEditResourceTags={onEditResourceTags}
        />
      ),
      size: 0
    })
  ];

  if (shouldShowMenu) {
    columns.push(columnHelper.display({
      id: 'actions',
      cell: info => {
        const items = menuItems(info.row);
        if (info.row.original.processingStatus === 'PROCESSING') {
          return <Text>Processing</Text>;
        }
        if (info.row.original.processingStatus === 'COMPLETE' && items.length > 0) {
          return <ThreeDotsMenu orientation="horizontal" menuItems={items}/>;
        }

        return <></>;
      },
      size: 15
    }));
  }

  const [currentReferenceUids, setCurrentReferenceUids] = useState<string[]>(referenceUids ?? []);

  const handleToggleReferenceUid = useCallback((uid: string) => {
    if (currentReferenceUids.includes(uid)) { // remove UID from list
      const updatedUids = [...currentReferenceUids].filter((_uid) => _uid !== uid);
      setCurrentReferenceUids(updatedUids);
    } else { // add UID to list
      const updatedUids = [...currentReferenceUids];
      updatedUids.push(uid);
      setCurrentReferenceUids(updatedUids);
    }
  }, [currentReferenceUids]);

  const isSelectedColumn = columnHelper.accessor('uid', {
    id: 'isSelected',
    header: 'IN USE',
    cell: (info) => {
      if (selectMode === 'view') {
        if (currentReferenceUids?.includes(info.row.original.uid)) {
          return <Checkmark />;
        }
        return '';
      }
      return (
        <Checkbox
          styles={{
            input: {
              cursor: 'pointer'
            }
          }}
          radius={4}
          checked={currentReferenceUids?.includes(info.row.original.uid)}
          onChange={() => { handleToggleReferenceUid(info.row.original.uid); }}
        />
      );
    },
    size: 15,
    enableSorting: false,
    minSize: 15,
    maxSize: 15
  });

  if (selectMode !== 'hidden') {
    columns.unshift(
      isSelectedColumn
    );
  }

  const { table, isLoading } = usePaginatedTable({
    defaultSortingField: DEFAULT_SORTING_FIELD,
    defaultSortDirection: DEFAULT_SORTING_DIRECTION,
    route: '/app/organization-resources',
    formatData: (data) => formatResourceData(data as OrganizationResource[], selectMode),
    columnNameToDBFieldMap: COLUMN_NAME_TO_DB_FIELD_MAP,
    columnData: columns,
    queryParams: getQueryParams(),
    defaultPageSize: DEFAULT_PAGE_SIZE,
    queryConfig: {
      refetchInterval: refetchingEnabled ? RESOURCE_REFETCH_INTERVAL_MS : undefined
    },
    tableConfig: {
      autoResetAll: false
    }
  });

  // second resource query that doesn't include filters to always be up to date with total number of resources
  const totalNumOfResources = useEnsisQuery('/app/organization-resources', {
    queryParams: {
      organization_slug: organizationSlug ?? '',
      exclude_failed_resources: true
    }
  }).data?.total ?? 0;

  useEffect(() => {
    setTotalNumberOfResources(totalNumOfResources);
  }, [totalNumOfResources]);

  const shouldRefetch = table.getRowModel().rows.some(
    (row) => row.original.processingStatus === 'PROCESSING'
  );
  if (refetchingEnabled !== shouldRefetch) {
    setRefetchingEnabled(shouldRefetch);
  }

  return {
    table,
    isLoading,
    currentReferenceUids,
    totalNumberOfResources,
    setCurrentReferenceUids,
    handleToggleReferenceUid
  };
};

export default useResourceTableData;
