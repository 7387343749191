import { Box, Title, Button, Group, Stack, Text } from '@mantine/core';
import { useState, useCallback } from 'react';

import useEnsisMutation from '../../hooks/useEnsisMutation';
import { ProposalSpacingSelector } from '../../components';
import { formatDateFromString } from '../../utils/stringUtils';
import type { Proposal } from '../../types/apiTypes';

interface Props {
  proposal: Proposal
}

const DetailsSection: React.FC<Props> = ({ proposal }: Props) => {
  const [selectedSpacingValue, setSelectedSpacingValue] = useState<number>(proposal?.spacing ?? 1);

  const updateProposalMutation = useEnsisMutation(
    `/app/proposals/${proposal?.uid}/data`,
    {
      successMessage: 'Settings Updated',
      requestType: 'patch',
      queryKeysToInvalidate: [`/app/proposals/${proposal?.uid}/data`]
    }
  );

  const handleSubmit = useCallback(() => {
    updateProposalMutation.mutate({ spacing: selectedSpacingValue });
  }, [selectedSpacingValue]);

  const handleCancel = useCallback(() => {
    setSelectedSpacingValue(proposal?.spacing ?? 1);
  }, [proposal?.spacing]);

  const settingsIsDirty = proposal?.spacing !== selectedSpacingValue;
  return (
    <Box ml={16} pb={16}>
      <Title order={6}> Details </Title>
      <Group justify='space-between' mt={8} mb={10}>
        <Stack gap={8}>
          <Text fz='sm' fw={600}> NAME </Text>
          <Text fw={500}> {proposal?.name} </Text>
        </Stack>
        <Stack gap={8}>
          <Text fz='sm' fw={600}> DATE CREATED </Text>
          <Text> {formatDateFromString(proposal?.created_at ?? undefined) ?? 'Date created unknown'} </Text>
        </Stack>
        <Stack gap={8}>
          <Text fz='sm' fw={600}> LAST EDITED </Text>
          <Text> {formatDateFromString(proposal?.created_at ?? undefined) ?? 'Last edited unknown'} </Text>
        </Stack>
      </Group>
      <Text fz="md" mb={8}><b>Page Length Settings</b></Text>
      <Text fz="sm" mb={16}>{'Default Settings can be changed on an organizational level under \'Settings\''}</Text>
      <ProposalSpacingSelector
        value={selectedSpacingValue}
        onSelectValue={(value) => { setSelectedSpacingValue(value); }}
      />
      <Group>
        <Button
          disabled={!settingsIsDirty}
          onClick={handleCancel}
        >
          Cancel
        </Button>
        <Button
          disabled={!settingsIsDirty || updateProposalMutation?.isPending}
          onClick={handleSubmit}
        >
          Save
        </Button>
      </Group>
    </Box>
  );
};

export default DetailsSection;
