import { useCallback, useEffect } from 'react';
import { Box, Text, Group, Button, Loader, Center } from '@mantine/core';
import { useDispatch, useSelector } from 'react-redux';

import { useParams } from 'react-router-dom';

import type { Proposal, RequirementResponse, ProposalSection, WithoutPermissions } from '../../../types/apiTypes';
import { type NarrativeOverlayState } from './EditorPane';
import { ComplianceModeRequirement, EditorPaneViewSelector, type ViewSelectorProps } from '../../../components';
import { selectNarrativeCompliance } from '../../../redux/store';
import { resetSelection, setMatches, updateSelectedUid } from '../../../redux/NarrativeComplianceSlice';
import useEnsisMutation from '../../../hooks/useEnsisMutation';
import { Replace as ReplaceIcon } from '../../../icons';

type Props = {
  section: WithoutPermissions<ProposalSection>
  proposal: Proposal
  requirementResponses: RequirementResponse[]
  canEdit: boolean
  narrativeOverlayState?: NarrativeOverlayState
  complianceCheckIsRunning: boolean
  focusedRequirementUid: string
  onSelectRequirement?: (reqUid: string) => void
  onComplianceModeClick: () => void
} & ViewSelectorProps;

/* eslint-disable max-len */
const EMPTY_STATE_COPY = 'This section has no requirements. Add requirements if needed, or generate narrative to draft content using AI.';
const ComplianceView: React.FC<Props> = (props: Props) => {
  const {
    section,
    complianceCheckIsRunning,
    requirementResponses,
    onComplianceModeClick
  } = props;

  const { proposalUid, sectionUid } = useParams();
  const dispatch = useDispatch();
  const sectionIsEmpty = (requirementResponses?.length ?? 0) === 0;

  useEffect(() => {
    const reqMap: Record<string, string[]> = {};
    requirementResponses.forEach(
      (response) => { reqMap[response.uid] = response.section_text_references as string[] ?? []; }
    );
    dispatch(setMatches(
      { requirementToCompliantText: reqMap }
    ));
  }, [requirementResponses, sectionUid]);

  const runComplianceMutation = useEnsisMutation(`/app/proposal-sections/${sectionUid}/compliance-analysis`, {
    requestType: 'post',
    successMessage: 'Compliance updated',
    queryKeysToInvalidate: [
      `/app/proposals/${proposalUid}/sections/${sectionUid}/requirement-responses`,
      `/app/proposals/${proposalUid}/data`
    ]
  });

  const complianceIsPending = complianceCheckIsRunning || runComplianceMutation.isPending;

  const canClickText = !complianceIsPending;

  const handleRunCompliance = useCallback(() => {
    runComplianceMutation.mutate([]);
    dispatch(resetSelection());
  }, [runComplianceMutation]);
  const complianceSlice = useSelector(selectNarrativeCompliance);
  const selectedResponseUid = complianceSlice.selectedResponseUid;

  const handleComplianceTextClick = (responseUid: string) => {
    if (canClickText) {
      if (selectedResponseUid !== responseUid) {
        onComplianceModeClick();
        dispatch(updateSelectedUid({ responseUid }));
      } else {
        dispatch(resetSelection());
      }
    }
  };

  const lastComplianceAnalysisTime = new Date(section.last_compliance_analysis_time ?? '');
  const lastNarrativeUpdateTime = new Date(section?.updated_at ?? '');
  const validComplianceTime = section.last_compliance_analysis_time !== undefined && (
    section.last_compliance_analysis_time !== null);

  const getLastComplianceAnalysisString = () => {
    if (lastComplianceAnalysisTime?.toLocaleDateString() !== new Date().toLocaleDateString()) {
      return lastComplianceAnalysisTime?.toLocaleDateString();
    } else {
      return lastComplianceAnalysisTime?.toLocaleTimeString();
    }
  };
  const complianceIsOutdated = lastComplianceAnalysisTime < lastNarrativeUpdateTime;

  const outlineEmptyState = (
    <Center
      mt={8}
      style={{
        borderRadius: 4
      }}
      h={250}
      bg='var(--mantine-color-greyPurple-0)'
      ta='center'
    >
      <Text p={8}>
        {EMPTY_STATE_COPY}
      </Text>
    </Center>
  );
  return (
    <Box>
      <EditorPaneViewSelector {...props} />
      <Box
        h={'calc(100vh - 210px)'}
        style={{ overflow: 'auto' }}
      >
        <Group align='center' justify='flex-end' gap='2' mt={4} pr={12} pt={12} pb={12}>
          {validComplianceTime && (
            <Text
              fz='xs'
              c='var(--mantine-color-gray-7)'
              mr={8}
            >
              {`Compliance last updated: ${getLastComplianceAnalysisString()}`}
            </Text>
          )}
          <Button disabled={!complianceIsOutdated} radius={4} w={128} h={30} onClick={handleRunCompliance}>
            {complianceIsPending
              ? <Loader color='white' size='xs'/>
              : (
                  <Group justify='center' align='center'>
                    <ReplaceIcon width={18} height={25} color='white' />
                    <Text fz='s' c='white'>Update</Text>
                  </Group>
                )
            }
          </Button>
        </Group>
        {sectionIsEmpty
          ? outlineEmptyState
          : requirementResponses?.map((response) => {
            return (
            <Box
              key={response.uid}
              mt={8}
              style={{ border: '1px solid var(--mantine-color-gray-2)', borderRadius: '4px' }}
            >
              <ComplianceModeRequirement
                sectionIsEmpty={sectionIsEmpty}
                response={response}
                onTextClick={() => { handleComplianceTextClick(response.uid); }}
                complianceCheckIsRunning={complianceIsPending}
              />
            </Box>
            );
          })}
      </Box>
    </Box>
  );
};

export default ComplianceView;
