import {
  Text, UnstyledButton, Popover, Group, Switch, Divider, Button, ActionIcon
} from '@mantine/core';
import { useCallback, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { PageWordCount } from '../../icons';
import classes from './WordCounter.module.css';
import { getWordsPerPage } from '../../utils/formattingUtils';
import { getProposalManagementRoute } from '../../utils/routerUtils';

interface Props {
  wordCount: number
  spacing: number
}

const WordCounter: React.FC<Props> = (props: Props) => {
  const { spacing, wordCount } = props;
  const { organization, proposalUid } = useParams();
  const navigate = useNavigate();

  const [menuOpened, setMenuOpened] = useState(false);
  const [showWords, setShowWords] = useState(true);
  const [showPages, setShowPages] = useState(true);
  const wordsPerPage = getWordsPerPage(spacing);
  const pages = Math.ceil(wordCount / wordsPerPage);

  const proposalManagementLink = getProposalManagementRoute(organization, proposalUid);

  const onClickWordCounter = useCallback(() => {
    setMenuOpened(!menuOpened);
  }, [menuOpened]);

  const actionIcon = (
    <ActionIcon size={36} p={6} variant='subtle' onClick={onClickWordCounter}>
      <PageWordCount />
    </ActionIcon>
  );

  const wordCounter = (
    <UnstyledButton className={classes.wordCounter} onClick={onClickWordCounter}>
      { showPages && <Text fz="sm"><b>{`${pages} ${pages === 1 ? 'page' : 'pages'}`}</b></Text> }
      { showWords && <Text fz="sm">{`${wordCount} words`}</Text> }
    </UnstyledButton>
  );

  const showActionIcon = !showPages && !showWords;

  return (
    <Popover opened={menuOpened} onChange={setMenuOpened}>
      <Popover.Target>
        { showActionIcon ? actionIcon : wordCounter }
      </Popover.Target>
      <Popover.Dropdown p={16} w={420}>
        <Group justify='space-between'>
          <Switch
            checked={showPages}
            onChange={(event) => { setShowPages(event.currentTarget.checked); }}
            label="Display page count"
          />
          <Switch
            checked={showWords}
            onChange={(event) => { setShowWords(event.currentTarget.checked); }}
            label="Display word count"
          />
        </Group>
        <Divider mt={16} mb={16} />
        <Text>
          {'Pages are an approximation of word count, font size and spacing. ' +
          'This can be configured under Proposal Management'}
        </Text>
        <Divider mt={16} mb={16} />
        <Button fullWidth onClick={() => { navigate(proposalManagementLink); }}>
          Configure Page Values
        </Button>
      </Popover.Dropdown>
    </Popover>
  );
};

export default WordCounter;
