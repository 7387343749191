/* eslint-disable max-len */
import React, { type SVGProps } from 'react';

const Trash: React.FC<Partial<SVGProps<SVGSVGElement>>> = (props: Partial<SVGProps<SVGSVGElement>>) => {
  const { color } = props;
  return (<svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill= {color ?? 'currentColor'}
    xmlns="http://www.w3.org/2000/svg">
    <path d="M5.00008 15.8333C5.00008 16.75 5.75008 17.5 6.66675 17.5H13.3334C14.2501 17.5 15.0001 16.75 15.0001 15.8333V5.83333H5.00008V15.8333ZM15.8334 3.33333H12.9167L12.0834 2.5H7.91675L7.08341 3.33333H4.16675V5H15.8334V3.33333Z"/>
  </svg>);
};

export default Trash;
