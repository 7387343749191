import { Box, Title, Text, Button } from '@mantine/core';
import Lottie from 'lottie-react';

import { useSelector } from 'react-redux';

import { checkmarkAnimation } from '../../../assets/lottie';

import type { Proposal, ProposalSection, WithoutPermissions } from '../../../types/apiTypes';
import { type NarrativeOverlayState } from './EditorPane';
import { EditorPaneViewSelector, WordCounter, type ViewSelectorProps } from '../../../components';
import { selectUpdatedNarrativeText } from '../../../redux/store';
import NarrativeResponseEditor from '../ResponseTextEditor/NarrativeResponseEditor';

type Props = {
  section: WithoutPermissions<ProposalSection>
  proposal: Proposal
  canEdit: boolean
  narrativeOverlayState?: NarrativeOverlayState
  setNarrativeOverlayState: (state: NarrativeOverlayState) => void
  onClickGenerateNarrative: () => void
} & ViewSelectorProps;

interface GetStartedProps {
  onClick: () => void
}
const GetStartedView: React.FC<GetStartedProps> = (props: GetStartedProps) => {
  const { onClick } = props;

  return (
    <Box p={24} ta='center'>
      <Title c='var(--mantine-color-darkPurple-5)' order={5}>
        Narrative ready
      </Title>
      <Text>
        Your draft narrative is ready. Move ahead to finalize your edits.
      </Text>
      <Lottie animationData={checkmarkAnimation} style={{ height: 150, margin: '36px' }}/>
      <Button onClick={onClick}> Get started </Button>
    </Box>
  );
};

const NarrativeEditor: React.FC<Props> = (props: Props) => {
  const {
    canEdit,
    section,
    narrativeOverlayState,
    onClickGenerateNarrative,
    setNarrativeOverlayState,
    proposal
  } = props;
  const sectionContent = section?.content;
  const { wordCount } = useSelector(selectUpdatedNarrativeText);

  if (narrativeOverlayState === 'readyScreen') {
    return (
      <Box>
        <EditorPaneViewSelector {...props} />
        <GetStartedView onClick={() => { setNarrativeOverlayState(null); }}/>;
      </Box>
    );
  }

  const wordCounter = (
    <WordCounter
      wordCount={wordCount}
      spacing={proposal?.spacing ?? 1}
    />
  );

  return (
    <Box>
      <EditorPaneViewSelector
        {...props}
        rightSection={wordCounter}
      />
      <Box
        h={'calc(100vh - 210px)'}
        style={{ overflow: 'auto' }}
      >
        <NarrativeResponseEditor
          key={sectionContent}
          proposalSectionUid={section.uid}
          initialContent={sectionContent ?? undefined}
          canEdit={canEdit}
          onGenerateNarrative={onClickGenerateNarrative}
        />
      </Box>
    </Box>
  );
};

export default NarrativeEditor;
