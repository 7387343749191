import { NavLink as MantineNavLink } from '@mantine/core';
import type { NavLinkProps as MantineNavLinkProps, PolymorphicComponentProps } from '@mantine/core';
import { NavLink as ReactRouterNavLink } from 'react-router-dom';

type Props = PolymorphicComponentProps<typeof ReactRouterNavLink, MantineNavLinkProps>;

const RouterNavLink = (props: Props) => (
  <MantineNavLink
    {...props}
    component={ReactRouterNavLink}
  />
);

export default RouterNavLink;
