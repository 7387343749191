import { Popover, ActionIcon, Button, Divider, Stack } from '@mantine/core';

import { useCallback, useState } from 'react';

import { useDispatch } from 'react-redux';

import { Pen, Trash, ThreeDotsVertical } from '../../icons';
import classes from './DropdownMenus.module.css';
import useEnsisMutation from '../../hooks/useEnsisMutation';

import { deleteRequirements } from '../../redux/WizardSlice';

interface Props {
  handleOpenEditRequirement: () => void
  requirementUid: string
  opportunityUid: string
}

const buttonClassNames = {
  inner: classes.popoverMenuButtonItem,
  section: classes.popoverMenuButtonLeftSection,
  root: classes.popoverMenuButtonItemRoot
};

const WizardRequirementDropdown: React.FC<Props> = (props: Props) => {
  const {
    handleOpenEditRequirement,
    requirementUid,
    opportunityUid
  } = props;

  const [opened, setOpened] = useState(false);
  const dispatch = useDispatch();

  const deleteRequirementMutation = useEnsisMutation(`/app/requirements/${requirementUid}/data`, {
    requestType: 'delete',
    contentType: 'application/json',
    successMessage: 'Requirement Successfully deleted!',
    onSuccess: () => {
      dispatch(deleteRequirements({ requirementUids: [requirementUid] }));
    },
    queryKeysToInvalidate: [`/app/opportunities/${opportunityUid}/requirements`],
    awaitRefetch: false
  });

  const handleDeleteRequirement = useCallback(() => {
    deleteRequirementMutation.mutate([]);
  }, [deleteRequirementMutation]);

  return (
  <Popover
    classNames={{ dropdown: classes.popoverDropdown }}
    opened={opened}
    onChange={setOpened}
    position="bottom-start"
  >
    <Popover.Target>
      <ActionIcon
        onClick={(event) => { setOpened((o) => !o); event.stopPropagation(); }}
        c={'var(--mantine-color-gray-5)'}
        variant='subtle'
      >
        {<ThreeDotsVertical />}
      </ActionIcon>
    </Popover.Target>
    <Popover.Dropdown>
      <Stack gap={0}>
        <Button
        variant="subtle"
        classNames={buttonClassNames}
        leftSection={<Pen/>}
        onClick={() => { handleOpenEditRequirement(); setOpened(false); }}
        >
          Edit Requirement
        </Button>
        <Divider />
        <Button
          onClick={handleDeleteRequirement}
          disabled={deleteRequirementMutation.isPending}
          variant="subtle"
          classNames={{ ...buttonClassNames, root: classes.popoverMenuButtonItemRootRed }}
          leftSection={<Trash/>}
        >
          Delete Requirement
        </Button>
      </Stack>
    </Popover.Dropdown>
  </Popover>
  );
};

export default WizardRequirementDropdown;
