import { Box, Modal, Button, ScrollArea } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';

import { useParams } from 'react-router-dom';

import { useState } from 'react';

import { type Row } from '@tanstack/react-table';

import { CenteredLoader, Pagination, Table } from '../../components';

import { type InviteRow } from '../../types/tableTypes';

import InviteUsers from '../../components/Forms/InviteUsers/InviteUsers';
import useInvitesTableData from '../../hooks/useInvitesTableData';
import RevokeInvite from '../../components/Modals/RevokeInvite/RevokeInvite';
import ResendInvite from '../../components/Modals/ResendInvite/ResendInvite';
import { defaultModalProps } from '../../utils/mantineUtils';
import TableEmptyState from '../../components/Table/TableEmptyState';

const InvitedUsers: React.FC = () => {
  const [inviteUserOpened, inviteUserHandlers] = useDisclosure(false);
  const [revokeUserOpened, revokeUserHandlers] = useDisclosure(false);
  const [resendUserOpened, resendUserHandlers] = useDisclosure(false);
  const [currentInvite, setCurrentInvite] = useState<InviteRow>();
  const { organization } = useParams();

  const handleRevokeOpen = (row: Row<InviteRow>) => {
    setCurrentInvite(row.original);
    revokeUserHandlers.open();
  };
  const handleResenedOpen = (row: Row<InviteRow>) => {
    setCurrentInvite(row.original);
    resendUserHandlers.open();
  };
  const { table, isLoading } = useInvitesTableData(organization ?? '', handleRevokeOpen, handleResenedOpen);

  return (
    <Box ta={'end'} maw={850}>
      <Modal opened={revokeUserOpened} {...defaultModalProps}>
        <RevokeInvite
          close={revokeUserHandlers.close}
          email={currentInvite?.email ?? ''}
          inviteUid={currentInvite?.uid}
        />
      </Modal>
      <Modal opened={resendUserOpened} {...defaultModalProps}>
        <ResendInvite
          close={resendUserHandlers.close}
          email={currentInvite?.email ?? ''}
          inviteUid={currentInvite?.uid}
        />
      </Modal>
      <Modal opened={inviteUserOpened} {...defaultModalProps}>
        <InviteUsers close={inviteUserHandlers.close}/>
      </Modal>
      <Button onClick={inviteUserHandlers.open}> Invite Users</Button>
      <ScrollArea mb={10} style={{ height: 'calc(100vh - 350px)' }}>
        {
          isLoading
            ? <CenteredLoader style={{ height: 224 }} />
            : (
                table.getRowModel().rows.length === 0
                  ? <TableEmptyState text='There are no outstanding organization invites.'/>
                  : <Table tableStyle='simple' table={table}/>
              )
        }
      </ScrollArea>
      <Pagination table={table}/>
    </Box>
  );
};

export default InvitedUsers;
