import { useCallback, useState } from 'react';
import {
  ActionIcon, Box, Button, Divider, Group, Loader, Modal, Overlay, ScrollArea, Stack, Text,
  Tooltip
} from '@mantine/core';
import { useLocation, useParams } from 'react-router-dom';

import { useDisclosure } from '@mantine/hooks';

import { useDispatch } from 'react-redux';

import type { SectionData } from './SectionEditor';
import { getSplitLocation } from '../../utils/stringUtils';
import classes from './Editor.module.css';
import { BulkRequirementButtonGroup, CenteredLoader, RouterNavLink } from '../../components';
import { Pencil, Close, AddParagraph, CollapseLeft, ExpandRight, FormatListChecks, ExitApp } from '../../icons';
import EditOutlineDnD, { type RequirementType } from './EditOutlineDnd';
import { defaultModalProps } from '../../utils/mantineUtils';
import useEnsisQuery from '../../hooks/useEnsisQuery';
import { AddSection } from '../../components/Modals';
import { addSection } from '../../redux/OutlineSlice';
import { formatSectionDataForRedux } from '../../utils/dndUtils';
import { resetSelection } from '../../redux/NarrativeComplianceSlice';

interface SideMenuProps {
  sections: SectionData[]
  hasEditPermission: boolean
}

const EditorSideMenu: React.FC<SideMenuProps> = (props: SideMenuProps) => {
  const { sections, hasEditPermission } = props;
  const { organization, proposalUid } = useParams();
  const location = useLocation();
  const [showEditOutline, setShowEditOutline] = useState(false);
  const [showCollapsedView, setShowCollapsedView] = useState(false);
  const [addSectionOpened, addSectionHandlers] = useDisclosure();
  const { data, isLoading, isFetching } = useEnsisQuery(
    '/app/requirement-responses',
    { queryParams: { proposal_uid: proposalUid } }
  );
  const proposalManagementURL = `/org/${organization}/proposals/${proposalUid}/manage`;
  const allProposalsURL = `/org/${organization}/proposals/`;
  const [parentSectionUid, setParentSectionUid] = useState<string | undefined>(undefined);
  const onOpenAddSection = useCallback((parentSectionUid?: string) => {
    setParentSectionUid(parentSectionUid);
    addSectionHandlers.open();
  }, []);

  const dispatch = useDispatch();
  const [checkedRequirements, setCheckedRequirements] = useState<RequirementType[]>([]);
  const onCheckRequirement = useCallback((requirement: RequirementType) => {
    if (checkedRequirements.includes(requirement)) {
      setCheckedRequirements(checkedRequirements.filter((_requirement) => _requirement !== requirement));
    } else {
      setCheckedRequirements([...checkedRequirements, requirement]);
    }
  }, [checkedRequirements]);

  const onAddSectionSuccess = useCallback((data: any) => {
    const formattedData = formatSectionDataForRedux(data);
    dispatch(addSection(formattedData));
  }, []);

  const onClickNavigate = useCallback(() => {
    // reset compliance selection when navigating to prevent erroneous warning toast
    dispatch(resetSelection());
  }, []);

  const onClearCheckedRequirements = useCallback(() => {
    setCheckedRequirements([]);
  }, []);

  const collapsedView = (
    <Box
      style={{ display: 'flex', flexDirection: 'column', height: 'calc(100vh - 64px)' }}
      pt={24}
      pb={24}
      w={50}
      bg='var(--mantine-color-greyPurple-0)'
    >
      <Group justify='center'>
        <ActionIcon variant='subtle' size='lg' onClick={() => { setShowCollapsedView(false); }}>
          <ExpandRight/>
        </ActionIcon>
      </Group>
      <Stack justify='flex-end' flex={1} gap="lg">
        <RouterNavLink
          to={proposalManagementURL}
          pl={8}
          leftSection={
            <Tooltip label='Proposal Management'>
              <ActionIcon variant='subtle' size='lg'>
                <FormatListChecks />
              </ActionIcon>
            </Tooltip>
          }
        />
        <RouterNavLink
          to={allProposalsURL}
          pl={8}
          leftSection={
            <Tooltip label='Exit Proposal'>
              <ActionIcon variant='subtle' size='lg'>
                <ExitApp />
              </ActionIcon>
            </Tooltip>
          }
        />
      </Stack>
    </Box>
  );

  if (showEditOutline) {
    return (
      <Box w={380}>
        <Modal opened={addSectionOpened} {...defaultModalProps}>
          <AddSection
            onAddSectionSuccess={onAddSectionSuccess}
            parentSectionUid={parentSectionUid}
            sections={sections}
            close={addSectionHandlers.close}
          />
        </Modal>
        <Overlay mt='68px' color='red' backgroundOpacity={0.20} h='calc(100vh - 64px)'>
          <Box
            style={{ display: 'flex', flexDirection: 'column', height: 'calc(100vh - 64px)' }}
            pl={12}
            pt={12}
            w={951}
            bg='var(--mantine-color-greyPurple-0)'
          >
            <Group mr={12} mb={12} justify='space-between'>
              <Text fz='lg' ta={'start'} fw={600}>
                Outline
              </Text>
              <ActionIcon
                c='var(--mantine-color-darkPurple-9)'
                variant='subtle'
                onClick={() => { setShowEditOutline(false); onClearCheckedRequirements(); }}
              >
                <Close />
              </ActionIcon>
            </Group>
            <Group justify='end' ml={12} mr={12} mb={8}>
              {checkedRequirements.length > 0
                ? <BulkRequirementButtonGroup
                    sections={sections}
                    onClearCheckedRequirements={onClearCheckedRequirements}
                    checkedRequirements={checkedRequirements}
                  />
                : (
                  <Button
                    w={150}
                    mr={10}
                    leftSection={<AddParagraph/>}
                    variant='outline'
                    onClick={() => { onOpenAddSection(); }}
                  >
                    Add Section
                  </Button>
                  )
              }
            </Group>
            {isLoading
              ? <CenteredLoader />
              : <ScrollArea h='calc(100vh - 192px)' type='hover'>
                  <EditOutlineDnD
                    sectionWidth='600px'
                    sections={sections}
                    requirementResponses={data?.items ?? []}
                    onOpenAddSection={onOpenAddSection}
                    onCheckRequirement={onCheckRequirement}
                    checkedRequirements={checkedRequirements}
                  />
                </ScrollArea>
              }
          </Box>
        </Overlay>
      </Box>
    );
  }

  if (showCollapsedView) {
    return collapsedView;
  }

  const editButton = hasEditPermission && (
    <ActionIcon
      size='lg'
      variant='subtle'
      onClick={() => { setShowEditOutline(true); }}
    >
      <Pencil/>
    </ActionIcon>
  );

  return (
    <Box
      style={{ display: 'flex', flexDirection: 'column', height: 'calc(100vh - 64px)' }}
      pt={24}
      w={268}
      bg='var(--mantine-color-greyPurple-0)'
    >
      <Group ml={12} mr={12} mb={12} justify='space-between'>
        <Text
          fz='lg'
          ta={'start'}
          fw={600}
        >
          Outline
        </Text>
        <Group gap='xs'>
          {
            isFetching
              ? <Loader size="sm" />
              : editButton
          }
          <ActionIcon variant='subtle' size='lg'>
            <CollapseLeft onClick={() => { setShowCollapsedView(true); }} />
          </ActionIcon>
        </Group>
      </Group>
      <Box
        style={{
          flex: 1,
          display: 'flex',
          flexDirection: 'column'
        }}
      >
        <ScrollArea ml={12} style={{ height: 'calc(100vh - 300px)', minHeight: '200px' }}>
          {props.sections.filter((section) => section.parentSectionUid === undefined).map((section) => (
            <div key={section.uid}>
            <RouterNavLink
              onClick={onClickNavigate}
              active={getSplitLocation(location).includes(section.uid)}
              classNames={{ root: classes.root, label: classes.label }}
              label={section.title}
              to={`/org/${organization}/proposals/${proposalUid}/edit/${section.uid}`}
            />
            {section.childSections?.map((childSection) => (
              <RouterNavLink
                key={childSection.uid}
                onClick={onClickNavigate}
                active={getSplitLocation(location).includes(childSection.uid)}
                classNames={{ root: classes.subsectionRoot, label: classes.subsectionLabel }}
                label={childSection.title}
                to={`/org/${organization}/proposals/${proposalUid}/edit/${childSection.uid}`}
              />
            ))}
            </div>
          ))}
        </ScrollArea>
        <Stack justify='flex-end' flex={1} gap='sm' pt={24} pb={20}>
          <RouterNavLink
            leftSection={<FormatListChecks />}
            key="manage"
            styles={{
              label: {
                fontWeight: 600
              }
            }}
            ml={12}
            to={proposalManagementURL}
            active = {getSplitLocation(location).includes('manage')}
            classNames={{ root: classes.root, label: classes.label }}
            label="Proposal Management"
          />
          <Divider />
          <RouterNavLink
            leftSection={<ExitApp />}
            ml={12}
            mt={8}
            key="exit"
            styles={{
              label: {
                fontWeight: 600
              }
            }}
            to={allProposalsURL}
            classNames={{ root: classes.root, label: classes.label }}
            label="Exit Proposal"
          />
        </Stack>
      </Box>
    </Box>
  );
};

export default EditorSideMenu;
