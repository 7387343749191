/* eslint-disable max-len */
import type { SVGProps } from 'react';

type Props = Partial<SVGProps<SVGSVGElement>>;

const ReplaceIcon: React.FC<Props> = (props: Props) => (
  <svg width={props.width ?? '24'} height={props.height ?? '24'} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M12.5 6V5.5H12C10.2761 5.5 8.62279 6.18482 7.40381 7.40381C6.18482 8.62279 5.5 10.2761 5.5 12C5.5 12.9504 5.70845 13.8775 6.09124 14.7016L5.34695 15.4459C4.80854 14.416 4.5 13.245 4.5 12C4.5 10.0109 5.29018 8.10322 6.6967 6.6967C8.10322 5.29018 10.0109 4.5 12 4.5H12.5V4V2.20711L15.2929 5L12.5 7.79289V6ZM18.6531 8.55405C19.1915 9.58397 19.5 10.755 19.5 12C19.5 13.9891 18.7098 15.8968 17.3033 17.3033C15.8968 18.7098 13.9891 19.5 12 19.5H11.5V20V21.7929L8.70711 19L11.5 16.2071V18V18.5H12C13.7239 18.5 15.3772 17.8152 16.5962 16.5962C17.8152 15.3772 18.5 13.7239 18.5 12C18.5 11.0442 18.2815 10.1253 17.9059 9.30116L18.6531 8.55405Z" fill={props.color ?? '#343A40'} stroke={props.color ?? '#343A40'}/>
  </svg>
);

export default ReplaceIcon;
