import store from '../redux/store';

export const getDraggableSubsectionIndex = (sectionUid: string, index: number) => {
  const state = store.getState().outline;
  const offset = state.requirements[sectionUid]?.length ?? 0;
  // make requirement index after all subsections in the same section
  return index + offset;
};

export const undoDraggableSubsectionIndex = (sectionUid: string, index: number) => {
  const state = store.getState().outline;
  const offset = state.requirements[sectionUid]?.length ?? 0;

  // don't adjust index if it is 0
  if (index === 0 || index < offset) {
    return 0;
  }
  return index - offset;
};

// accounts for dropping subsection after requirements in the same section
export const getAdjustedRequirementIndex = (sectionUid: string, index: number) => {
  const state = store.getState().outline;
  const requirementsLength = state.requirements[sectionUid]?.length;
  if (index > requirementsLength) {
    return requirementsLength;
  }
  return index;
};

export const getSectionUidFromDroppableId = (droppableId: string) => {
  return droppableId.split('_')[1];
};

export const getItemInfoFromDraggableId = (draggableId: string) => {
  return { type: draggableId.split('_')[0], uid: draggableId.split('_')[1] };
};

export const formatSectionDataForRedux = (data: any) => {
  return {
    title: data.title,
    uid: data.uid,
    slug: data.slug,
    ordinal: data.ordinal,
    parentSectionUid: data.parent_proposal_section_uid === null ? undefined : data.parent_proposal_section_uid
  };
};
