/* eslint-disable max-len */

import React, { type SVGProps } from 'react';

const ThreeDotHorizontal: React.FC<Partial<SVGProps<SVGSVGElement>>> = (props: Partial<SVGProps<SVGSVGElement>>) => {
  const { width, height } = props;

  return (
  <svg
    width={ width ?? '18'}
    height= {height ?? '6'}
    viewBox="0 0 18 6"
    fill="var(--mantine-color-darkPurple-9)"
    xmlns="http://www.w3.org/2000/svg">
      <path d="M13.3334 3.00016C13.3334 2.42553 13.5616 1.87443 13.968 1.4681C14.3743 1.06177 14.9254 0.833496 15.5 0.833496C16.0747 0.833496 16.6258 1.06177 17.0321 1.4681C17.4384 1.87443 17.6667 2.42553 17.6667 3.00016C17.6667 3.5748 17.4384 4.1259 17.0321 4.53223C16.6258 4.93856 16.0747 5.16683 15.5 5.16683C14.9254 5.16683 14.3743 4.93856 13.968 4.53223C13.5616 4.1259 13.3334 3.5748 13.3334 3.00016ZM6.83337 3.00016C6.83337 2.42553 7.06165 1.87443 7.46798 1.4681C7.8743 1.06177 8.4254 0.833496 9.00004 0.833496C9.57468 0.833496 10.1258 1.06177 10.5321 1.4681C10.9384 1.87443 11.1667 2.42553 11.1667 3.00016C11.1667 3.5748 10.9384 4.1259 10.5321 4.53223C10.1258 4.93856 9.57468 5.16683 9.00004 5.16683C8.4254 5.16683 7.8743 4.93856 7.46798 4.53223C7.06165 4.1259 6.83337 3.5748 6.83337 3.00016ZM0.333374 3.00016C0.333374 2.42553 0.561647 1.87443 0.967976 1.4681C1.3743 1.06177 1.9254 0.833496 2.50004 0.833496C3.07468 0.833496 3.62578 1.06177 4.03211 1.4681C4.43843 1.87443 4.66671 2.42553 4.66671 3.00016C4.66671 3.5748 4.43843 4.1259 4.03211 4.53223C3.62578 4.93856 3.07468 5.16683 2.50004 5.16683C1.9254 5.16683 1.3743 4.93856 0.967976 4.53223C0.561647 4.1259 0.333374 3.5748 0.333374 3.00016Z" />
    </svg>
  );
};

export default ThreeDotHorizontal;
