import { LoadingOverlay, Text } from '@mantine/core';
import { useCallback } from 'react';

import ModalContent from '../../ModalContent';
import useEnsisMutation from '../../../hooks/useEnsisMutation';

interface Props {
  inviteUid?: string
  email: string
  close: () => void
}
const ResendInvite: React.FC<Props> = (props: Props) => {
  const { close, email, inviteUid } = props;

  const resendInviteMutation = useEnsisMutation(
    `/app/organization-invites/${inviteUid}/resend`,
    {
      requestType: 'patch',
      contentType: 'application/json',
      queryKeysToInvalidate: ['/app/organization-invites'],
      successMessage: 'Invite successfully resent',
      onSuccess: close
    }
  );
  const onRemove = useCallback(() => {
    resendInviteMutation.mutate({});
  }, []);

  return (
    <ModalContent
      title='Resend an invite'
      onClose={close}
      primaryButton={{
        label: 'Confirm',
        onClick: onRemove,
        color: 'red'
      }}
      secondaryButton={{
        label: 'Cancel',
        onClick: close,
        color: 'red'
      }}
    >
      <LoadingOverlay visible={resendInviteMutation.isPending} zIndex={1000}/>
      <Text>
        This will resend an invitation to <Text span fw={700}> {email} </Text> to join your team in Ensis.
      </Text>
    </ ModalContent>
  );
};

export default ResendInvite;
