import { useCallback } from 'react';
import { LoadingOverlay, Select, TextInput, Textarea } from '@mantine/core';
import { useForm } from '@mantine/form';

import ModalContent from '../../ModalContent';
import useEnsisMutation from '../../../hooks/useEnsisMutation';
import { RESOURCE_TYPE_TO_STRING_MAP } from '../../../utils/constants';
import { type ResourceType } from '../../../types/miscTypes';

interface EditProps {
  name?: string
  description?: string
  resourceType?: ResourceType
  onClose: () => void
  resourceUid?: string
}

interface FormValues {
  name: string
  description: string
  resourceType: ResourceType
}

const EditResource: React.FC<EditProps> = (props: EditProps) => {
  const { name, description, resourceUid, resourceType, onClose } = props;
  const editResourceMutation = useEnsisMutation(
    `/app/organization-resources/${resourceUid}/data`,
    {
      requestType: 'patch',
      contentType: 'application/json',
      successMessage: 'Changes saved',
      onSuccess: onClose,
      queryKeysToInvalidate: ['/app/organization-resources']
    }
  );

  const form = useForm({
    initialValues: {
      name: name ?? '',
      description: description ?? '',
      resourceType: resourceType ?? 'WON_PROPOSAL'
    },
    validate: {
      name: (value) => (value.trim().length < 1 ? 'Resource name is required' : null)
    }
  });

  const onSubmitForm = useCallback((values: FormValues) => {
    editResourceMutation.mutate({
      name: values.name,
      description: values.description,
      resource_type: values.resourceType
    });
  }, [editResourceMutation.mutate]);

  return (<ModalContent
    title='Edit resource details'
    subtitle='Rename your resource and edit its description'
    onClose={onClose}
    primaryButton={{
      label: 'Save changes',
      onClick: form.onSubmit(onSubmitForm),
      disabled: !form.isDirty()
    }}
    secondaryButton={{
      label: 'Cancel',
      onClick: onClose
    }}
  >
    <LoadingOverlay zIndex={1000} visible={editResourceMutation.isPending} />
    <TextInput
      label='Name'
      {...form.getInputProps('name')}
      pb={25}
    />
    <Select
      label='Resource Type'
      pb={25}
      radius={4}
      data={Object.keys(RESOURCE_TYPE_TO_STRING_MAP).map((type) => {
        return {
          value: type,
          label: RESOURCE_TYPE_TO_STRING_MAP[type as ResourceType]
        };
      })}
      allowDeselect={false}
     {...form.getInputProps('resourceType')}
    />
    <Textarea
      label='Description'
      placeholder="Enter resource description here"
      autosize
      minRows={3}
      maxRows={5}
      {...form.getInputProps('description')}
    />
  </ModalContent>);
};

export default EditResource;
