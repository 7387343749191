import { Box, Title, Text, ActionIcon, Button, Group } from '@mantine/core';
import type { MantineStyleProp } from '@mantine/core';

import classes from './ModalContent.module.css';
import { Close } from '../../icons';

export interface ButtonProps {
  label: string
  disabled?: boolean
  onClick: () => void
  color?: string
};

interface Props {
  title?: string
  subtitle?: string
  children: React.ReactNode
  onClose?: () => void
  primaryButton?: ButtonProps
  secondaryButton?: ButtonProps
  leftSideButton?: ButtonProps
  contentContainerStyle?: MantineStyleProp
};

const ModalContent: React.FC<Props> = (props: Props) => {
  const {
    title, subtitle, children, onClose, primaryButton, secondaryButton, leftSideButton, contentContainerStyle
  } = props;

  return (
      <Box m={12}>
        {onClose !== undefined &&
          <Box className={classes.closeButton}>
           <ActionIcon variant='subtle' color='var(--mantine-color-darkPurple-9)' onClick={onClose}>
                <Close/>
              </ActionIcon>
          </Box>
        }
        <Box pb={32}>
            <Title order={5}> {title} </Title>
            <Text size='sm' c='var(--mantine-color-gray-6)'> {subtitle} </Text>
        </Box>
        <Box style={{ display: 'flex', flexDirection: 'column', ...contentContainerStyle }}>
          {children}
        </Box>
      <Group justify='space-between' mt={32}>
       { (leftSideButton != null)
         ? <Button
         variant='subtle'
         disabled={leftSideButton.disabled ?? false}
         onClick={leftSideButton.onClick}
         color={leftSideButton.color}
        >
           {leftSideButton.label}
         </Button>
         : <Box/> }
        <Group gap='md' >
          {
            (secondaryButton != null) &&
            <Button
              variant='subtle'
              disabled={secondaryButton.disabled ?? false}
              onClick={secondaryButton.onClick}
              color={secondaryButton.color}
            >
              {secondaryButton.label}
            </Button>
          }
          {
            (primaryButton != null) &&
            <Button
              className={classes.primaryButton}
              disabled={primaryButton.disabled ?? false}
              onClick={primaryButton.onClick}
              color={primaryButton.color}
            >
              {primaryButton.label}
            </Button>
          }
        </Group>
      </Group>
    </Box>
  );
};

export default ModalContent;
