/* eslint-disable max-len */

import React, { type SVGProps } from 'react';

const User: React.FC<Partial<SVGProps<SVGSVGElement>>> = (props: Partial<SVGProps<SVGSVGElement>>) => {
  const { width, height } = props;

  return (
  <svg
    width={ width ?? '12'}
    height= {height ?? '13'}
    viewBox="0 0 12 13"
    fill="var(--mantine-color-gray-6)"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M6 0.5C6.79565 0.5 7.55871 0.81607 8.12132 1.37868C8.68393 1.94129 9 2.70435 9 3.5C9 4.29565 8.68393 5.05871 8.12132 5.62132C7.55871 6.18393 6.79565 6.5 6 6.5C5.20435 6.5 4.44129 6.18393 3.87868 5.62132C3.31607 5.05871 3 4.29565 3 3.5C3 2.70435 3.31607 1.94129 3.87868 1.37868C4.44129 0.81607 5.20435 0.5 6 0.5ZM6 2C5.60218 2 5.22064 2.15804 4.93934 2.43934C4.65804 2.72064 4.5 3.10218 4.5 3.5C4.5 3.89782 4.65804 4.27936 4.93934 4.56066C5.22064 4.84196 5.60218 5 6 5C6.39782 5 6.77936 4.84196 7.06066 4.56066C7.34196 4.27936 7.5 3.89782 7.5 3.5C7.5 3.10218 7.34196 2.72064 7.06066 2.43934C6.77936 2.15804 6.39782 2 6 2ZM6 7.25C8.0025 7.25 12 8.2475 12 10.25V12.5H0V10.25C0 8.2475 3.9975 7.25 6 7.25ZM6 8.675C3.7725 8.675 1.425 9.77 1.425 10.25V11.075H10.575V10.25C10.575 9.77 8.2275 8.675 6 8.675Z" fill="#343A40"/>
  </svg>
  );
};

export default User;
