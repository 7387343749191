import { LoadingOverlay, TextInput } from '@mantine/core';

import { useCallback, useState } from 'react';

import { useParams } from 'react-router-dom';

import ModalContent from '../ModalContent';
import useEnsisMutation from '../../hooks/useEnsisMutation';

import { isEmpty } from '../../utils/stringUtils';
import { type SectionData } from '../../Pages/Editor/SectionEditor';

interface Props {
  close: () => void
  sections: SectionData[]
  onAddSectionSuccess?: (data: any) => void
  parentSectionUid?: string
}

const AddSection: React.FC<Props> = (props: Props) => {
  const [sectionTitle, setSectionTitle] = useState('');
  const { proposalUid } = useParams();
  const { close, sections, parentSectionUid, onAddSectionSuccess } = props;

  const addSectionMutation = useEnsisMutation('/app/proposal-sections', {
    requestType: 'post',
    successMessage: 'Section added',
    queryKeysToInvalidate: [`/app/proposals/${proposalUid}/data`],
    onSuccess: close
  });

  const sectionTitles = sections.filter(
    (section) => section.parentSectionUid === parentSectionUid
  ).map(
    (section) => section.title.toLowerCase()
  );

  const handleOnSuccess = useCallback((data: any) => {
    if (onAddSectionSuccess !== undefined) {
      onAddSectionSuccess(data);
    }
  }, [onAddSectionSuccess]);

  const handleAddSection = useCallback(() => {
    addSectionMutation.mutate({
      proposal_uid: proposalUid ?? '',
      title: sectionTitle,
      ordinal: sectionTitles.length + 1,
      parent_proposal_section_uid: parentSectionUid
    },
    {
      onSuccess: handleOnSuccess
    });
  }, [addSectionMutation, sectionTitle, sectionTitles, handleOnSuccess, parentSectionUid]);

  const sectionCopy = parentSectionUid !== undefined ? 'subsection' : 'section';
  const isDuplicateTitle = sectionTitles.includes(sectionTitle.toLowerCase().trim());

  return (
    <ModalContent
      title={`Add ${sectionCopy}`}
      subtitle={`Enter the title for your new ${sectionCopy}`}
      onClose={props.close}
      secondaryButton={{
        label: 'Cancel',
        onClick: close
      }}
      primaryButton={{
        label: 'Confirm',
        onClick: handleAddSection,
        disabled: isEmpty(sectionTitle) || isDuplicateTitle
      }}
    >
      <LoadingOverlay zIndex={1000} visible={addSectionMutation.isPending}/>
      <TextInput
        placeholder={`New ${sectionCopy}`}
        value={sectionTitle}
        onChange={(event) => { setSectionTitle(event.currentTarget.value); }}
        error={isDuplicateTitle && 'Section with this title already exists'}
      />
    </ModalContent>
  );
};

export default AddSection;
