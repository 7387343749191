import { useCallback, useEffect, useState } from 'react';
import { Button, Popover, ScrollArea, Stack, Text } from '@mantine/core';
import { useParams } from 'react-router-dom';

import classes from '../DropdownMenus.module.css';
import { User } from '../../../icons';
import UserAssignmentItem from './UserAssignmentItem';
import type { EnsisUser, WithoutPermissions } from '../../../types/apiTypes';
import useEnsisMutation from '../../../hooks/useEnsisMutation';
import { hasPermissionsForObject } from '../../../utils/apiUtils';
import useEnsisQuery from '../../../hooks/useEnsisQuery';
import CenteredLoader from '../../CenteredLoader';
import { getUserDisplayName } from '../../../utils/stringUtils';

interface Props {
  sectionUid: string
  condensed?: boolean
}

const UserAssignmentDropdown: React.FC<Props> = (props: Props) => {
  const { sectionUid, condensed = false } = props;
  const { proposalUid, organization } = useParams();
  const [opened, setOpened] = useState(false);
  const { data: proposalData, isLoading: proposalDataLoading } = useEnsisQuery(
    `/app/proposals/${proposalUid}/data`
  );
  const { data: usersData, isLoading: usersLoading } = useEnsisQuery(
    '/app/users',
    {
      queryParams: {
        organization_slug: organization ?? ''
      }
    }
  );

  const isLoading = proposalDataLoading || usersLoading;
  const section = proposalData?.sections?.find((s) => s.uid === sectionUid);
  const assignedUsers: Array<WithoutPermissions<EnsisUser>> = section?.assigned_users ?? [];
  const [localAssignedUsers, setLocalAssignedUsers] = useState<Array<WithoutPermissions<EnsisUser>>>(
    assignedUsers
  );
  const canAssignSections = hasPermissionsForObject(proposalData, 'assign_sections');

  const updateSectionAssignmentsMutation = useEnsisMutation(`/app/proposal-sections/${section?.uid}/assignments`, {
    requestType: 'patch',
    showSuccessMessage: false,
    queryKeysToInvalidate: [`/app/proposals/${proposalUid}/data`]
  });

  useEffect(() => {
    setLocalAssignedUsers(assignedUsers);
  }, [assignedUsers]);

  const sortByDisplayName = (items: EnsisUser[]) =>
    items.sort((a, b) => {
      const aDisplayName = getUserDisplayName(a).toLowerCase();
      const bDisplayName = getUserDisplayName(b).toLowerCase();
      if ((aDisplayName) < (bDisplayName)) {
        return -1;
      }
      if ((aDisplayName) > (bDisplayName)) {
        return 1;
      }
      return 0;
    });

  const getAssignmentsHaveChanged = () => {
    const assignedUserUids = assignedUsers.map((user) => user.uid);
    const localAssignedUserUids = localAssignedUsers.map((user) => user.uid);
    return assignedUserUids.length !== localAssignedUserUids.length ||
      !assignedUserUids.every((uid) => localAssignedUserUids.includes(uid));
  };

  const handleOnChange = useCallback(() => {
    if (canAssignSections) {
      setOpened(!opened);
    }
    if (opened && getAssignmentsHaveChanged()) {
      updateSectionAssignmentsMutation.mutate({
        assigned_user_uids: localAssignedUsers.map((user) => user.uid)
      });
    }
  }, [opened, canAssignSections, localAssignedUsers, updateSectionAssignmentsMutation]);

  const getAssignmentsLabel = useCallback(() => {
    const hasNoAssignments = localAssignedUsers.length === 0;
    const hasOneAssignment = localAssignedUsers.length === 1;

    if (hasNoAssignments) {
      return 'Unassigned';
    }

    if (hasOneAssignment) {
      return getUserDisplayName(localAssignedUsers[0]);
    }

    return `${localAssignedUsers.length} Assigned`;
  }, [localAssignedUsers]);

  const handleAssignmentSelect = (selectedAssignedUser: WithoutPermissions<EnsisUser>) => {
    setLocalAssignedUsers((previousState) => {
      const isUserSelected = previousState.some((user) => user.uid === selectedAssignedUser.uid);
      if (isUserSelected) {
        return previousState.filter((user) => user.uid !== selectedAssignedUser.uid);
      }
      return [...previousState, selectedAssignedUser];
    });
  };

  if (isLoading) {
    return <CenteredLoader />;
  }

  const label = condensed
    ? <Text maw={150} ml={8} truncate>{getAssignmentsLabel()}</Text>
    : <Text ml={8} truncate>{getAssignmentsLabel()}</Text>;

  return (
    <Popover
      classNames={{ dropdown: classes.popoverDropdown }}
      opened={opened}
      onChange={handleOnChange}
      position={condensed ? 'bottom-end' : 'bottom-start'}
    >
      <Popover.Target>
        <Button
          classNames={{ root: classes.buttonTargetRoot }}
          variant='subtle'
          onClick={(event) => {
            handleOnChange();
            event.stopPropagation();
          }}
          disabled={!canAssignSections}
        >
          <User />
          {label}
        </Button>
      </Popover.Target>
      <Popover.Dropdown>
        <ScrollArea.Autosize mah={400}>
          <Stack ta='left' gap={2}>
            {sortByDisplayName(usersData?.items ?? []).map((user) => (
              <UserAssignmentItem
                handleSelect={handleAssignmentSelect}
                key={user.uid}
                user={user}
                selected={localAssignedUsers.some(
                  (assignment) => assignment.uid === user.uid
                )}
              />
            ))}
          </Stack>
        </ScrollArea.Autosize>
      </Popover.Dropdown>
    </Popover>
  );
};

export default UserAssignmentDropdown;
