import { LoadingOverlay, Text } from '@mantine/core';
import { useCallback } from 'react';

import useEnsisMutation from '../../../hooks/useEnsisMutation';
import ModalContent from '../../ModalContent';

interface Props {
  inviteUid?: string
  email: string
  close: () => void
}
const RemoveUser: React.FC<Props> = (props: Props) => {
  const { close, email, inviteUid } = props;

  const revokeInviteMutation = useEnsisMutation(
    `/app/organization-invites/${inviteUid}/revoke`,
    {
      requestType: 'patch',
      contentType: 'application/json',
      successMessage: 'Invite successfully revoked',
      onSuccess: close,
      queryKeysToInvalidate: ['/app/organization-invites']
    }
  );
  const onRemove = useCallback(() => {
    revokeInviteMutation.mutate({});
  }, []);

  return (
    <ModalContent
      title='Revoke an invite'
      onClose={close}
      primaryButton={{
        label: 'Confirm',
        onClick: onRemove,
        color: 'red'
      }}
      secondaryButton={{
        label: 'Cancel',
        onClick: close,
        color: 'red'
      }}
    >
      <LoadingOverlay visible={revokeInviteMutation.isPending} zIndex={1000} />
      <Text>
        This will revoke <Text span fw={700}> {`${email}'s`}</Text> invitation to join your team in Ensis.
        They will no longer be able to join your team.
      </Text>
    </ ModalContent>
  );
};

export default RemoveUser;
