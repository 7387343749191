/* eslint-disable max-len */
import React from 'react';

const ResendIcon: React.FC = () => (
<svg
  width="24"
  height="25"
  viewBox="0 0 24 25" fill="currentColor"
  xmlns="http://www.w3.org/2000/svg">
  <path d="M17.6493 6.85097C16.0193 5.22097 13.7093 4.28097 11.1693 4.54097C7.49929 4.91097 4.47929 7.89097 4.06929 11.561C3.51929 16.411 7.26929 20.501 11.9993 20.501C15.1893 20.501 17.9293 18.631 19.2093 15.941C19.5293 15.271 19.0493 14.501 18.3093 14.501C17.9393 14.501 17.5893 14.701 17.4293 15.031C16.2993 17.461 13.5893 19.001 10.6293 18.341C8.40929 17.851 6.61929 16.041 6.14929 13.821C5.30929 9.94097 8.25929 6.50097 11.9993 6.50097C13.6593 6.50097 15.1393 7.19097 16.2193 8.28097L14.7093 9.79097C14.0793 10.421 14.5193 11.501 15.4093 11.501H18.9993C19.5493 11.501 19.9993 11.051 19.9993 10.501V6.91097C19.9993 6.02097 18.9193 5.57097 18.2893 6.20097L17.6493 6.85097Z" />
</svg>);

export default ResendIcon;
