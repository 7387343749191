import { Text } from '@mantine/core';
import {
  type ColumnDef,
  createColumnHelper,
  type Row
} from '@tanstack/react-table';

import { ThreeDotsMenu } from '../components';
import { type TableRows, type InviteRow } from '../types/tableTypes';
import useOrgPermissions from './useOrgPermissions';
import { Minus as MinusIcon, Resend as ResendIcon } from '../icons';
import usePaginatedTable from './usePaginatedTable';
import type { OrganizationInvite } from '../types/apiTypes';

const DEFAULT_SORTING_FIELD = 'email';
const COLUMN_NAME_TO_DB_FIELD_MAP = new Map([
  ['uid', 'uid'],
  ['email', 'email_address'],
  ['status', 'expiration_time']
]);
const DEFAULT_SORT_DIRECTION = 'ASC';

const formatInvitedUsersData = (
  invitedUsersData: OrganizationInvite[]
): InviteRow[] => invitedUsersData
  ?.map((invite) => ({
    uid: invite.uid ?? '',
    email: invite.email_address ?? '',
    status: invite.is_valid ? 'Invited' : 'Invite Expired',
    isClickable: false
  }));

const useInvitesTableData = (
  organizationSlug: string,
  openDelete: (row: Row<InviteRow>) => void,
  openResend: (row: Row<InviteRow>) => void
) => {
  const { userHasPermission } = useOrgPermissions();
  const menuItems = (row: Row<InviteRow>) => ([
    {
      label: 'Revoke',
      onClick: () => { openDelete(row); },
      shouldRender: row.original.status === 'Invited' && userHasPermission('add_user'),
      icon: <MinusIcon />
    },
    {
      label: 'Resend',
      onClick: () => { openResend(row); },
      shouldRender: userHasPermission('add_user'),
      icon: <ResendIcon />
    }]);

  const columnHelper = createColumnHelper<InviteRow>();

  const columns = [
    columnHelper.accessor('email', {
      header: 'EMAIL',
      cell: info => <Text> {info.getValue()} </Text>,
      size: 62
    }),
    columnHelper.accessor('status', {
      header: 'STATUS',
      cell: info => <Text> {info.getValue()} </Text>,
      size: 35
    }),
    columnHelper.display({
      id: 'actions',
      cell: info => (
        userHasPermission('change_user_role') && (
            <ThreeDotsMenu
              orientation='horizontal'
              menuItems={menuItems(info.row)}
            />
        )),
      size: 3
    })
  ] as Array<ColumnDef<TableRows>>;

  return usePaginatedTable({
    defaultSortingField: DEFAULT_SORTING_FIELD,
    defaultSortDirection: DEFAULT_SORT_DIRECTION,
    route: '/app/organization-invites',
    formatData: formatInvitedUsersData,
    columnNameToDBFieldMap: COLUMN_NAME_TO_DB_FIELD_MAP,
    columnData: columns,
    queryParams: {
      organization_slug: organizationSlug ?? ''
    }
  });
};

export default useInvitesTableData;
