import { useEffect } from 'react';
import { Loader } from '@mantine/core';
import { useAuth0 } from '@auth0/auth0-react';
import { useParams, useNavigate } from 'react-router-dom';

import useEnsisMutation from '../hooks/useEnsisMutation';
import NotFoundPage from './NotFoundPage';

const AcceptInvitePage: React.FC = () => {
  const { inviteUid } = useParams();
  const { isAuthenticated, loginWithRedirect } = useAuth0();
  const acceptInviteMutation = useEnsisMutation(
    `/app/organization-invites/${inviteUid}/accept`,
    {
      requestType: 'patch',
      successMessage: 'Successfully added to organization',
      queryKeysToInvalidate: ['/app/users/me']
    }
  );
  const navigate = useNavigate();

  useEffect(() => {
    if (isAuthenticated) {
      acceptInviteMutation.mutate({});
    } else {
      loginWithRedirect(
        {
          appState: { returnTo: window.location.pathname },
          authorizationParams: { screen_hint: 'signup' }
        }
      ).catch(() => {});
    }
  }, [isAuthenticated]);

  useEffect(() => {
    if (acceptInviteMutation.isSuccess) {
      const organizationSlug = acceptInviteMutation?.data?.organization_slug;
      if (organizationSlug != null) {
        navigate(`/org/${organizationSlug}/proposals`);
      } else {
        navigate('/');
      }
    }
  }, [acceptInviteMutation.isSuccess]);

  if (acceptInviteMutation.isError) {
    return <NotFoundPage />;
  }

  return <Loader />;
};

export default AcceptInvitePage;
