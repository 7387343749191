/* eslint-disable max-len */
import { type SVGProps } from 'react';

const ArrowRight: React.FC<Partial<SVGProps<SVGSVGElement>>> = (props: Partial<SVGProps<SVGSVGElement>>) => {
  return (
  <svg width={props.width ?? '8'} height={props.height ?? '16'} viewBox="0 0 8 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
    <path d="M0.666748 2.12385L6.54297 8.00008L0.666748 13.8763V2.12385Z" />
  </svg>
  );
};

export default ArrowRight;
