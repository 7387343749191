import { Box, Button, Modal, ScrollArea } from '@mantine/core';

import { useDisclosure } from '@mantine/hooks';

import { useCallback, useState } from 'react';

import { useDispatch } from 'react-redux';

import EditOutlineDnD from '../Editor/EditOutlineDnd';
import { type SectionData } from '../Editor/SectionEditor';
import WizardContent from './WizardContent';
import { defaultModalProps } from '../../utils/mantineUtils';
import { AddParagraph } from '../../icons';
import { AddSection } from '../../components/Modals';
import { addSection } from '../../redux/OutlineSlice';
import { formatSectionDataForRedux } from '../../utils/dndUtils';

interface Props {
  sections: SectionData[]
  onClickNext: () => void
  onClickPrevious: () => void
  showCopyForGeneratedSections: boolean
}
const ReviewSections: React.FC<Props> = (props: Props) => {
  const { onClickNext, onClickPrevious, sections, showCopyForGeneratedSections } = props;

  const [addSectionOpened, addSectionHandlers] = useDisclosure();
  const [parentSectionUid, setParentSectionUid] = useState<string | undefined>(undefined);
  const titleCopy = showCopyForGeneratedSections ? 'Review and edit your outline' : 'Organize your outline';
  const subtitleCopy = showCopyForGeneratedSections
    ? `Ensis generated an outline based on the opportunity.
    Please review the outline below and make any necessary changes. You can update these later.`
    : 'Please structure your proposal to ensure it meets your needs. You can change these later.';

  const dispatch = useDispatch();
  const onOpenAddSection = useCallback((parentSectionUid?: string) => {
    setParentSectionUid(parentSectionUid);
    addSectionHandlers.open();
  }, []);

  const onAddSectionSuccess = useCallback((data: any) => {
    const formattedData = formatSectionDataForRedux(data);
    dispatch(addSection(formattedData));
  }, []);

  return (
    <>
      <Modal {...defaultModalProps} opened={addSectionOpened}>
        <AddSection
         onAddSectionSuccess={onAddSectionSuccess}
         parentSectionUid={parentSectionUid}
         close={addSectionHandlers.close}
         sections={sections}
        />
      </Modal>
      <Box w='55vw' pt={24}>
      <WizardContent
        subtitleCopy={subtitleCopy}
        titleCopy={titleCopy}
        onClickNext={onClickNext}
        onClickPrevious={onClickPrevious}
      >
        <Box ta='end' mr={6}>
          <Button pl={16} leftSection={<AddParagraph />} variant='outline' onClick={() => { onOpenAddSection(); }}>
            Add Section
          </Button>
        </Box>
        <ScrollArea type='hover' h='calc(100vh - 300px)'>
         <EditOutlineDnD
            sectionWidth='calc(55vw - 340px)'
            sections={sections}
            requirementResponses={[]}
            showAddRequirement={false}
            onOpenAddSection={onOpenAddSection}
            isReviewSectionsView={true}
          />
        </ScrollArea>
      </WizardContent>
      </Box>
    </>
  );
};

export default ReviewSections;
