import {
  Box, Group, Avatar, Flex, Image, Menu, UnstyledButton, ActionIcon
} from '@mantine/core';
import { useAuth0 } from '@auth0/auth0-react';
import '@mantine/core/styles.css';

import { useNavigate, useParams } from 'react-router-dom';

import classes from './Header.module.css';
import ProposalNameTextInput from '../ProposalNameTextInput/ProposalNameTextInput';
import Logo from '../../assets/ensis-logo.svg';
import { DownloadProposalDropdown, ProposalStatusDropdown } from '../DropdownMenus';

interface Props {
  firstName: string
  lastName: string
  email: string
}

const items = [
  {
    label: 'My Profile',
    link: 'settings'
  },
  {
    label: 'Team Settings',
    link: 'settings'
  },
  {
    label: 'Logout',
    link: 'logout'
  }
];

const Header: React.FC<Props> = ({ firstName, lastName, email }: Props) => {
  const { logout } = useAuth0();
  const { organization, proposalUid } = useParams();
  let initials = '';
  if (firstName !== '' && lastName !== '') {
    initials = firstName.charAt(0).toUpperCase() + lastName.charAt(0).toUpperCase();
  } else {
    initials = email.charAt(0).toUpperCase();
  }

  const inProposalRoute = proposalUid !== undefined;

  const navigate = useNavigate();

  const menuItems = items.map((item, key) => {
    const onClick = () => {
      if (item.link === 'logout') {
        logout({
          logoutParams: { returnTo: window.location.origin }
        }).catch(() => {});
      } else {
        navigate(`/org/${organization}/${item.link}`);
      }
    };
    return (
      <Menu.Item key={+key} onClick={onClick}>
          <Flex justify='start' pl={12}>
            {item.label}
          </Flex>
      </Menu.Item>
    );
  });

  return (
    <header>
      <Group className={classes.headerRoot} justify='space-between'>
        <Group>
          <Box display="flex" style={{ flexDirection: 'row', justifyContent: 'flex-start' }}>
            <UnstyledButton onClick={() => { navigate('/'); }}>
              <Image src={Logo} fit='contain' width='auto' />
            </UnstyledButton>
          </Box>
          { inProposalRoute && (
            <ProposalNameTextInput proposalUid={proposalUid} />
          )}
        </Group>
        <Group gap={8}>
          {inProposalRoute && <ProposalStatusDropdown proposalUid={proposalUid} />}
          {inProposalRoute && <DownloadProposalDropdown proposalUid={proposalUid} />}
          <Menu
            offset={25}
            width={175}
            position="bottom-end"
            classNames={{
              dropdown: classes.dropdown,
              item: classes.item
            }}
          >
            <Menu.Target>
              <ActionIcon size={36}>
                <Avatar bg='var(--mantine-color-lightPurple-5)' color='white'>
                  {initials}
                </Avatar>
              </ActionIcon>
            </Menu.Target>
            <Menu.Dropdown>
                {menuItems}
            </Menu.Dropdown>
          </Menu>
        </Group>
      </Group>
    </header>
  );
};

export default Header;
