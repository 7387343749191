import { ActionIcon, Menu, Stack, Group, Flex, Tooltip, Box, type MantineStyleProp } from '@mantine/core';

import { ThreeDotsVertical, ThreeDotsHorizontal } from '../../icons';
import classes from './ThreeDotsMenu.module.css';

interface ThreeDotProps {
  menuItems: Array<{
    icon?: React.ReactNode
    label: string | React.ReactNode
    onClick: () => void
    shouldRender: boolean
    disabled?: boolean
    disabledHelpText?: string
  }>
  orientation: 'vertical' | 'horizontal'
  dropDownMenuStyle?: MantineStyleProp
}

const ThreeDotsMenu: React.FC<ThreeDotProps> = (props: ThreeDotProps) => {
  const {
    menuItems,
    orientation,
    dropDownMenuStyle
  } = props;
  return (
    <Menu
      width={170}
      position='bottom-end'
      classNames={{ dropdown: classes.dropdown, item: classes.item }}
    >
    <Menu.Target>
      <ActionIcon
      variant='subtle'
        classNames={{
          root: orientation === 'horizontal'
            ? classes.buttonRootHorizontal
            : classes.buttonRootVertical
        }}
        onClick={(event: React.MouseEvent<HTMLElement>) => {
          event.stopPropagation();
        }}
      >
        {orientation === 'horizontal' ? <ThreeDotsHorizontal /> : <ThreeDotsVertical /> }
      </ActionIcon>
    </Menu.Target>
    <Menu.Dropdown style={{ width: 220, ...(dropDownMenuStyle ?? {}) }}>
      <Stack gap={4} style={{ flexWrap: 'nowrap' }}>
        {
          menuItems.map((item, index) =>
            item.shouldRender &&
          <Tooltip
            key={+index}
            events={{
              hover: item.disabled === true && item.disabledHelpText !== undefined,
              focus: false,
              touch: false
            }}
            label={item.disabledHelpText}
            position='left'
          >
            <Box>
              <Menu.Item
                onClick={(event: React.MouseEvent<HTMLElement>) => {
                  item.onClick();
                  event.stopPropagation();
                }}
                disabled={item.disabled}
              >
                <Group gap={'xs'}>
                  <Flex justify='center' align='center' w={20}> {item.icon} </Flex>
                  {item.label}
                </Group>
              </Menu.Item>
            </Box>
          </Tooltip>)
        }
      </Stack>
    </Menu.Dropdown>
  </Menu>
  );
};

export default ThreeDotsMenu;
