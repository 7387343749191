/* eslint-disable max-len */
import React from 'react';

const FilterFilled: React.FC = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M7.3333 7.33337L11.1733 2.41337C11.2816 2.27439 11.3304 2.09821 11.3092 1.92333C11.2879 1.74844 11.1983 1.58907 11.06 1.48004C10.9481 1.39007 10.8101 1.33863 10.6666 1.33337H1.3333C1.18292 1.33425 1.03726 1.38593 0.919965 1.48004C0.781589 1.58907 0.691993 1.74844 0.670757 1.92333C0.649521 2.09821 0.698371 2.27439 0.806632 2.41337L4.66663 7.33337V11.2467C4.65457 11.3476 4.66572 11.4499 4.69923 11.5458C4.73273 11.6417 4.78771 11.7286 4.85997 11.8L6.1933 13.1334C6.31821 13.2575 6.48718 13.3272 6.6633 13.3272C6.83942 13.3272 7.00839 13.2575 7.1333 13.1334C7.20675 13.0626 7.26297 12.9759 7.29764 12.88C7.33232 12.784 7.34452 12.6814 7.3333 12.58V7.33337ZM8.66663 10.6667L12 14L15.3333 10.6667H8.66663Z" fill="#7139B0"/>
  </svg>
);

export default FilterFilled;
