import { useState, useCallback, useEffect } from 'react';
import { LoadingOverlay } from '@mantine/core';
import { type FileWithPath } from '@mantine/dropzone';
import { useParams } from 'react-router-dom';

import { showFailureNotification, DEFAULT_FAILURE_MESSAGE } from '../../../utils/mantineUtils';
import { INVALID_FILE_ERROR_MESSAGE } from '../../../utils/apiUtils';
import ModalContent from '../../ModalContent';
import useEnsisMutation from '../../../hooks/useEnsisMutation';
import Dropzone from '../../Dropzone/Dropzone';
import { type ResourceType } from '../../../types/miscTypes';
import { withAnalyticsEvent } from '../../../utils/analyticsUtils';

interface Props {
  onClose: () => void
  setSelected?: (uids: string[]) => void
  onUploadResources?: () => void
}

export interface FileWithMetadata {
  fileObject: FileWithPath
  resourceType?: ResourceType
}
const UploadResources: React.FC<Props> = (props: Props) => {
  const { onClose, setSelected, onUploadResources } = props;
  const { organization } = useParams();
  const [files, setFiles] = useState<FileWithMetadata[]>([]);

  const successMessage = files.length > 1 ? 'Resources added' : 'Resource added';

  // TODO: update for adding resources to proposal proposalUid
  const uploadResourcesMutation = useEnsisMutation(
    '/app/organization-resources',
    {
      contentType: 'multipart/form-data',
      requestType: 'post',
      showFailureMessage: false,
      successMessage,
      onSuccess: () => {
        if (onUploadResources !== undefined) {
          onUploadResources();
        }
        onClose();
      },
      onError: (error) => {
        let errorMessage = DEFAULT_FAILURE_MESSAGE;
        if (error?.response?.status === 422) {
          errorMessage = INVALID_FILE_ERROR_MESSAGE;
        }
        showFailureNotification(errorMessage);
      },
      queryKeysToInvalidate: ['/app/organization-resources']
    }
  );

  const onSubmit = useCallback(() => {
    const filesToUpload = files.map((file) => file.fileObject) as unknown as string[];
    uploadResourcesMutation.mutate({
      files: filesToUpload,
      organization_slug: organization ?? '',
      resource_types: files.map((file) => file.resourceType).join('|')
    });
  }, [files]);

  useEffect(() => {
    if (uploadResourcesMutation.data !== undefined && setSelected !== undefined) {
      const resourceUids = uploadResourcesMutation.data.map((item) => item.uid);
      setSelected(resourceUids);
    }
  }, [uploadResourcesMutation.data]);

  return (
    <ModalContent
      title='Import resources'
      subtitle='Import resources to keep your models fresh and trained on your latest data'
      onClose={onClose}
      primaryButton={{
        label: 'Import',
        onClick: withAnalyticsEvent(onSubmit, 'AddResource'),
        disabled: files.length === 0 || files.some(
          (file) => file.resourceType === undefined || file.resourceType === null
        )
      }}
      secondaryButton={{
        label: 'Cancel',
        onClick: onClose
      }}
    >
      <LoadingOverlay visible={uploadResourcesMutation.isPending} zIndex={1000}/>
      <Dropzone
        files={files}
        setFiles={setFiles}
        showResourceTypeDropdown={true}
        instructionText='Click or drag and drop to upload new resources,
        including product descriptions, pricing tables, and past proposals.'
      />
</ModalContent>);
};

export default UploadResources;
