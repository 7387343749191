import { Group, Text } from '@mantine/core';
import {
  createColumnHelper,
  type Row
} from '@tanstack/react-table';
import { useState } from 'react';

import { ThreeDotsMenu } from '../components';
import { type OpportunityFileRow } from '../types/tableTypes';
import { Trash as TrashIcon, Replace as ReplaceIcon, Download as DownloadIcon } from '../icons';
import usePaginatedTable from './usePaginatedTable';
import type { OpportunityFile, ProcessingStatusType } from '../types/apiTypes';
import { hasPermissionsForObject } from '../utils/apiUtils';
import { getDateStringForTableCell } from '../utils/tableUtils';

const DEFAULT_SORTING_FIELD = 'dateAdded';
const COLUMN_NAME_TO_DB_FIELD_MAP = new Map([
  ['uid', 'uid'],
  ['name', 'file_name'],
  ['dateAdded', 'created_at']
]);
const DEFAULT_SORT_DIRECTION = 'ASC';
const DATA_REFETCH_INTERVAL_MS = 10000;

const formatOpportunityFileData = (
  opportunityFiles: OpportunityFile[]
): OpportunityFileRow[] => opportunityFiles
  ?.map((oppFile) => ({
    uid: oppFile.uid ?? '',
    name: oppFile.file_name ?? '',
    dateAdded: oppFile.created_at != null ? new Date(oppFile.created_at) : null,
    isClickable: false,
    canEdit: hasPermissionsForObject(oppFile, 'change'),
    hasRequirements: oppFile.has_requirements,
    url: oppFile.url ?? '',
    processingStatus: oppFile.processing_status as ProcessingStatusType
  }));

const useOpportunityFileTableData = (
  opportunityUid: string,
  openDelete: (row: Row<OpportunityFileRow>) => void,
  openReplace: (row: Row<OpportunityFileRow>) => void
) => {
  const [refetchingEnabled, setRefetchingEnabled] = useState(false);

  const menuItems = (row: Row<OpportunityFileRow>) => ([
    {
      label: 'Download',
      onClick: () => { window.open(row?.original?.url ?? '', '_blank', 'noreferrer'); },
      shouldRender: row.original.canEdit,
      icon: <DownloadIcon />
    },
    {
      label: 'Replace Opportunity File',
      onClick: () => { openReplace(row); },
      shouldRender: row.original.canEdit,
      icon: <ReplaceIcon />
    },
    {
      label: <Text c='red' fw='bold'>Delete Opportunity File</Text>,
      onClick: () => { openDelete(row); },
      shouldRender: row.original.canEdit,
      icon: <TrashIcon color='red' />,
      disabled: row.original.hasRequirements,
      disabledHelpText: 'Cannot delete opportunity file with linked requirements'
    }]);

  const columnHelper = createColumnHelper<OpportunityFileRow>();

  const columns = [
    columnHelper.accessor('name', {
      header: 'NAME',
      cell: info => <Text> {info.getValue()} </Text>,
      sortingFn: 'text',
      size: 55
    }),
    columnHelper.accessor('dateAdded', {
      header: 'DATE ADDED',
      cell: info => <Text> {getDateStringForTableCell(info.getValue())} </Text>,
      sortingFn: 'datetime',
      size: 30
    }),
    columnHelper.display({
      id: 'actions',
      cell: info => (
        <Group justify='flex-end'>
          {
            info.row.original.processingStatus === 'PROCESSING'
              ? <Text>PROCESSING</Text>
              : (
                <ThreeDotsMenu
                  orientation='horizontal'
                  menuItems={menuItems(info.row)}
                  dropDownMenuStyle={{ width: 300 }}
                />
                )
          }
        </Group>
      ),
      size: 3
    })
  ];

  const paginatedTable = usePaginatedTable({
    defaultSortingField: DEFAULT_SORTING_FIELD,
    defaultSortDirection: DEFAULT_SORT_DIRECTION,
    route: '/app/opportunity-files',
    formatData: formatOpportunityFileData,
    columnNameToDBFieldMap: COLUMN_NAME_TO_DB_FIELD_MAP,
    columnData: columns,
    queryConfig: {
      refetchInterval: refetchingEnabled ? DATA_REFETCH_INTERVAL_MS : undefined
    },
    queryParams: {
      opportunity_uid: opportunityUid,
      generate_download_url: true,
      exclude_failed_opportunity_files: true
    }
  });

  const shouldRefetch = paginatedTable.table.getRowModel().rows.some(
    (row) => row.original.processingStatus === 'PROCESSING'
  );
  if (refetchingEnabled !== shouldRefetch) {
    setRefetchingEnabled(shouldRefetch);
  }

  return paginatedTable;
};

export default useOpportunityFileTableData;
