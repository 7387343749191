import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

import { type SectionData } from '../Pages/Editor/SectionEditor';
import { type RequirementType } from '../Pages/Editor/EditOutlineDnd';
import { ARCHIVED_SECTION_DATA } from '../utils/stringUtils';

interface OutlineState {
  sections: SectionData[]
  requirements: Record<string, RequirementType[]>
  subsections: Record<string, SectionData[]>
}
interface ReorderSectionPayload {
  fromIndex: number
  toIndex: number
  parentSectionUid?: string
}
interface ReorderSubsectionPayload {
  fromIndex: number
  toIndex: number
  parentSectionUid: string
}
interface UpdateOutlineRequirementsPayload {
  requirements: RequirementType[]
}
interface UpdateOutlineSectionsPayload {
  sections: SectionData[]
}
interface ReorderRequirementPayload {
  sectionUid: string
  fromIndex: number
  toIndex: number
}
interface MoveRequirementPayload {
  fromSectionUid: string
  toSectionUid: string
  fromIndex: number
  toIndex: number
}
interface EditRequirementPayload {
  requirementText: string
  requirementUid: string
  requirementIdentifier: string
  sectionUid: string
}

const initialState: OutlineState = {
  sections: [],
  requirements: {},
  subsections: {}
};

const outlineSlice = createSlice({
  name: 'outline',
  initialState,
  reducers: {
    updateOutlineSections (state, action: PayloadAction<UpdateOutlineSectionsPayload>) {
      state.sections = action.payload.sections.filter(
        (section) => section.parentSectionUid === undefined
      ).concat(ARCHIVED_SECTION_DATA);
      action.payload.sections.forEach((section) => {
        state.subsections[section.uid] = section.childSections ?? [];
        if (state.requirements[section.uid] === undefined) {
          state.requirements[section.uid] = [];
        }
      });
    },
    addSection (state, action: PayloadAction<SectionData>) {
      if (action.payload.parentSectionUid === undefined) { // adding top level section
        const result = [...state.sections];
        result.splice(state.sections.length - 1, 0, action.payload);
        state.sections = result;
        state.requirements[action.payload.uid] = [];
        state.subsections[action.payload.uid] = [];
      } else { // adding subsection
        const result = [...state.subsections[action.payload.parentSectionUid]];
        result.splice(state.subsections[action.payload.parentSectionUid].length, 0, action.payload);
        state.subsections[action.payload.parentSectionUid] = result;
        state.subsections[action.payload.uid] = [];
        state.requirements[action.payload.uid] = [];
      }
    },
    editSectionName (state, action: PayloadAction<SectionData>) {
      if (action.payload.parentSectionUid === undefined) {
        const result = [...state.sections];
        result[result.findIndex((section) => section.uid === action.payload.uid)] = action.payload;
        state.sections = result;
      } else {
        const result = [...state.subsections[action.payload.parentSectionUid]];
        result[result.findIndex((section) => section.uid === action.payload.uid)] = action.payload;
        state.subsections[action.payload.parentSectionUid] = result;
      }
    },
    deleteSection (state, action: PayloadAction<SectionData>) {
      const sectionUidToDelete = action.payload.uid;
      // move all requirements to archived
      const reqs = [...state.requirements[sectionUidToDelete]];
      reqs.push(...state.requirements[ARCHIVED_SECTION_DATA.uid]);
      state.requirements[ARCHIVED_SECTION_DATA.uid] = reqs;
      // remove section uid from req map
      const { [sectionUidToDelete]: _uid, ...newReqs } = { ...state.requirements };
      state.requirements = newReqs;
      // remove section uid from subsections map ...
      const { [sectionUidToDelete]: _subUid, ...newSubsections } = { ...state.subsections };
      state.subsections = newSubsections;
      // remove section uid from subsection list
      if (action.payload.parentSectionUid !== undefined) {
        const result = [...state.subsections[action.payload.parentSectionUid]];
        state.subsections[action.payload.parentSectionUid] = result.filter(
          (section) => section.uid !== sectionUidToDelete
        );
      }
      // remove item from sections
      const result = [...state.sections];
      state.sections = result.filter((section) => section.uid !== sectionUidToDelete);
    },
    reorderSection (state, action: PayloadAction<ReorderSectionPayload>) {
      const result = [...state.sections];
      const [removed] = result.splice(action.payload.fromIndex, 1);
      result.splice(action.payload.toIndex, 0, removed);
      state.sections = result;
    },
    updateOutlineRequirements (state, action: PayloadAction<UpdateOutlineRequirementsPayload>) {
      Object.keys(state.subsections).forEach((sectionUid) => {
        const sectionsRequirements = action.payload.requirements?.filter(
          (req) => req.sectionUid === sectionUid
        );
        if (sectionUid === ARCHIVED_SECTION_DATA.uid) {
          sectionsRequirements?.sort(
            (a, b) => (new Date(b.updatedAt).valueOf() - new Date(a.updatedAt).valueOf())
          );
        }
        state.requirements[sectionUid] = sectionsRequirements ?? [];
      });
      // handle archived which doesn't live in subsections .... maybe thats a mistake ??
      const sectionsRequirements = action.payload.requirements?.filter(
        (req) => req.sectionUid === ARCHIVED_SECTION_DATA.uid
      );
      sectionsRequirements?.sort(
        (a, b) => (new Date(b.updatedAt).valueOf() - new Date(a.updatedAt).valueOf())
      );
      state.requirements[ARCHIVED_SECTION_DATA.uid] = sectionsRequirements ?? [];
    },
    addRequirement (state, action: PayloadAction<RequirementType>) {
      const requirementArray = [...state.requirements[action.payload.sectionUid]];
      requirementArray.unshift(action.payload);
      state.requirements[action.payload.sectionUid] = requirementArray;
    },
    editRequirement (state, action: PayloadAction<EditRequirementPayload>) {
      const requirementArray = [...state.requirements[action.payload.sectionUid]];
      const indexToFix = requirementArray.findIndex((req) => req.requirementUid === action.payload.requirementUid);
      requirementArray[indexToFix].requirementText = action.payload.requirementText;
      requirementArray[indexToFix].requirementIdentifier = action.payload.requirementIdentifier;
      state.requirements[action.payload.sectionUid] = requirementArray;
    },
    reorderSubesction (state, action: PayloadAction<ReorderSubsectionPayload>) {
      const result = [...state.subsections[action.payload.parentSectionUid]];
      const [removed] = result.splice(action.payload.fromIndex, 1);
      result.splice(action.payload.toIndex, 0, removed);
      state.subsections[action.payload.parentSectionUid] = result;
    },
    reorderRequirement (state, action: PayloadAction<ReorderRequirementPayload>) {
      const result = [...state.requirements[action.payload.sectionUid]];
      const [removed] = result.splice(action.payload.fromIndex, 1);
      result.splice(action.payload.toIndex, 0, removed);
      state.requirements[action.payload.sectionUid] = result;
    },
    moveSubsection (state, action: PayloadAction<MoveRequirementPayload>) {
      const sourceState = [...state.subsections[action.payload.fromSectionUid]];
      const destinationState = [...state.subsections[action.payload.toSectionUid]];
      destinationState.splice(action.payload.toIndex, 0, sourceState[action.payload.fromIndex]);
      destinationState[action.payload.toIndex].parentSectionUid = action.payload.toSectionUid;
      sourceState.splice(action.payload.fromIndex, 1);
      state.subsections[action.payload.toSectionUid] = destinationState;
      state.subsections[action.payload.fromSectionUid] = sourceState;
    },
    moveRequirement (state, action: PayloadAction<MoveRequirementPayload>) {
      const sourceState = [...state.requirements[action.payload.fromSectionUid]];
      const destinationState = [...state.requirements[action.payload.toSectionUid]];
      destinationState.splice(action.payload.toIndex, 0, sourceState[action.payload.fromIndex]);
      destinationState[action.payload.toIndex].sectionUid = action.payload.toSectionUid;
      sourceState.splice(action.payload.fromIndex, 1);
      state.requirements[action.payload.toSectionUid] = destinationState;
      state.requirements[action.payload.fromSectionUid] = sourceState;
    }
  }
});

export const {
  reorderSection,
  updateOutlineRequirements,
  updateOutlineSections,
  addSection,
  editSectionName,
  deleteSection,
  reorderRequirement,
  addRequirement,
  editRequirement,
  moveRequirement,
  reorderSubesction,
  moveSubsection
} = outlineSlice.actions;
export default outlineSlice.reducer;
