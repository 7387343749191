import { Box } from '@mantine/core';
import { useState, useCallback, useEffect, useRef, useMemo } from 'react';

import type {
  Proposal, RequirementResponse, ProposalSection, WithoutPermissions, OpportunityRequirement
} from '../../../types/apiTypes';
import {
  CenteredLoader,
  EditorPaneViewSelector,
  OpportunityDocumentsViewer,
  type RequirementMatchData,
  type ViewSelectorProps,
  type OpportunityDocumentsViewerRef
} from '../../../components';
import useEnsisQuery from '../../../hooks/useEnsisQuery';
import { PDF_LOAD_DELAY_MS } from '../../../utils/pdfUtils';
import { sortOpportunityFilesByRequirementCount } from '../../../utils/requirementUtils';

type Props = {
  section: WithoutPermissions<ProposalSection>
  proposal: Proposal
  requirementResponses: RequirementResponse[]
  canEdit: boolean
  focusedRequirementUid: string
  onSelectRequirement?: (reqUid: string) => void
  onAddRequirement?: (requirementText: string, opportunityFileUid?: string) => void
} & ViewSelectorProps;

const DocumentsViewer: React.FC<Props> = (props: Props) => {
  const {
    proposal,
    requirementResponses,
    focusedRequirementUid,
    onSelectRequirement,
    onAddRequirement
  } = props;
  const [currentFileIndex, setCurrentFileIndex] = useState(0);
  const [matches, setMatches] = useState<RequirementMatchData[]>([]);

  const fileViewerRef = useRef<OpportunityDocumentsViewerRef>(null);

  const { data: opportunityFiles, isLoading } = useEnsisQuery(
    '/app/opportunity-files',
    {
      queryParams: { opportunity_uid: proposal?.opportunity?.uid ?? '', generate_download_url: true }
    }
  );

  const requirements: OpportunityRequirement[] = useMemo(() => (
    requirementResponses
      .filter((
        reqResponse => reqResponse?.requirement !== undefined && reqResponse?.requirement != null
      ))
      .map(
        reqResponse => reqResponse.requirement as OpportunityRequirement
      )
  ), [requirementResponses]);

  const sortedOpportunityFiles = useMemo(() => (
    sortOpportunityFilesByRequirementCount(
      opportunityFiles?.items ?? [],
      requirements
    )
  ), [opportunityFiles?.items]);

  useEffect(() => {
    const selectedReq = requirements?.find((req) => req.uid === focusedRequirementUid);
    const fileIndex = sortedOpportunityFiles
      ?.findIndex((file) => file.uid === selectedReq?.opportunity_file?.uid) ?? -1;
    if (fileIndex === currentFileIndex) {
      jumpToRequirement(focusedRequirementUid, matches);
    } else if (fileIndex !== -1 && fileIndex !== currentFileIndex) {
      setCurrentFileIndex(fileIndex);
    }
  }, [focusedRequirementUid]);

  // Jump to currently selected requirement every time matches are re-fetched
  useEffect(() => {
    setTimeout(() => {
      if (focusedRequirementUid !== '') {
        jumpToRequirement(focusedRequirementUid, matches);
      }
    }, PDF_LOAD_DELAY_MS);
  }, [matches]);

  const jumpToRequirement = useCallback((requirementUid: string, _matches: RequirementMatchData[]) => {
    const match = _matches.find((match) => match.requirementUid === requirementUid);
    const pageNumber = match?.pageNumber;
    if (pageNumber !== undefined) {
      fileViewerRef?.current?.jumpToPage(pageNumber);
    }
  }, [fileViewerRef]);

  const handleGetMatches = useCallback((newMatches: RequirementMatchData[]) => {
    setMatches(newMatches);
  }, []);

  if (isLoading) {
    return <CenteredLoader />;
  }

  return (
    <Box>
      <EditorPaneViewSelector {...props} />
      <Box
        h={'calc(100vh - 220px)'}
        style={{ overflow: 'hidden' }}
      >
        <OpportunityDocumentsViewer
          style={{ height: '100%' }}
          ref={fileViewerRef}
          focusedRequirementUid={focusedRequirementUid}
          onClickHighlightedRequirement={onSelectRequirement}
          requirements={requirements}
          opportunityFiles={sortedOpportunityFiles}
          currentFileIndex={currentFileIndex}
          onClickFile={(index: number) => { setCurrentFileIndex(index); }}
          onGetMatches={handleGetMatches}
          onAddRequirement={onAddRequirement}
        />
      </Box>
    </Box>
  );
};

export default DocumentsViewer;
