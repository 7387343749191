import { Box, ScrollArea, Modal, Button, Group, Text, Center } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { useCallback, useState, useRef, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { defaultModalProps } from '../../utils/mantineUtils';
import type { OpportunityRequirement, Proposal } from '../../types/apiTypes';
import { EditRequirement } from '../../components/Modals';
import WizardContent from './WizardContent';
import ReviewRequirementsRow, { type ReviewRequirementsRowRef } from './ReviewRequirementsRow';
import { editRequirement, selectRequirement, selectOpportunityFile } from '../../redux/WizardSlice';
import { selectWizardState } from '../../redux/store';
import DeleteRequirements from '../../components/Modals/DeleteRequirements';
import { AddFile, Trash } from '../../icons';
import { CenteredLoader } from '../../components';

interface Props {
  onClickNext: () => void
  proposal: Proposal
  handleClickRequirement: (requirementUid: string) => void
  handleAddRequirement: (requirementUid: string, opportunityFileUid: string) => void
}
/* eslint-disable-next-line max-len */
const EMPTY_STATE_TEXT = 'No requirements were found in this file. You can add them manually using the "Add Requirement" button at the top, or by highlighting text directly in the document and selecting "Add Requirement."';
const ReviewRequirements: React.FC<Props> = (props: Props) => {
  const {
    onClickNext,
    proposal,
    handleAddRequirement,
    handleClickRequirement
  } = props;
  const [editRequirementOpened, editRequirementHandlers] = useDisclosure();

  const [selectedRequirement, setSelectedRequirement] = useState<OpportunityRequirement | undefined>(
    undefined
  );
  const [currentlyChecked, setCurrentlyChecked] = useState<string[]>([]);
  const [deleteRequirementOpened, deleteRequirementHandlers] = useDisclosure();
  const dispatch = useDispatch();

  const {
    requirements,
    selectedRequirementUid,
    selectedOpportunityFileIndex,
    opportunityFiles,
    matches,
    documentLoading
  } = useSelector(selectWizardState);

  const currentOppFile = opportunityFiles[selectedOpportunityFileIndex];

  const requirementRowRefs = useRef<Record<string, ReviewRequirementsRowRef | null>>({});

  const toggleClickRequirement = useCallback((requirementUid: string) => {
    if (requirementUid === selectedRequirementUid) {
      dispatch(selectRequirement({ requirementUid: '' }));
    } else {
      handleClickRequirement(requirementUid);
    }
  }, [handleClickRequirement, selectedRequirementUid]);

  const handleEditRequirement = useCallback((requirement: OpportunityRequirement) => {
    setSelectedRequirement(requirement);
    editRequirementHandlers.open();
  }, [requirements]);

  const onEditRequirement = useCallback((requirement: OpportunityRequirement) => {
    dispatch(editRequirement({ requirement }));
  }, []);

  const handleClickPrevious = useCallback(() => {
    if (selectedOpportunityFileIndex > 0) {
      dispatch(selectOpportunityFile({ opportunityFileIndex: selectedOpportunityFileIndex - 1 }));
    }
  }, [selectedOpportunityFileIndex]);

  const handleClickNext = useCallback(() => {
    if (selectedOpportunityFileIndex === opportunityFiles.length - 1) {
      onClickNext();
    } else {
      dispatch(selectOpportunityFile({ opportunityFileIndex: selectedOpportunityFileIndex + 1 }));
    }
  }, [opportunityFiles, selectedOpportunityFileIndex, onClickNext]);

  const clearSelections = useCallback(() => {
    setCurrentlyChecked([]);
  }, []);

  useEffect(() => {
    if (selectedRequirementUid !== '') {
      requirementRowRefs.current[selectedRequirementUid]?.scrollIntoView();
    }
  }, [requirements.length, selectedRequirementUid]);

  const sortedFilteredRequirements = useMemo(() => {
    const reqsInCurrentFile: Record<string, OpportunityRequirement> = {};
    requirements.forEach(
      (req) => {
        if (req?.opportunity_file?.uid === currentOppFile?.uid) {
          reqsInCurrentFile[req.uid] = req;
        }
      }
    );
    const reqsWithMatches: OpportunityRequirement[] = [];
    matches.forEach(({ requirementUid }) => {
      if (requirementUid in reqsInCurrentFile) {
        reqsWithMatches.push(reqsInCurrentFile[requirementUid]);
        // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
        delete reqsInCurrentFile[requirementUid];
      }
    });

    // tack on requirements that were not found in matches
    const allReqs = reqsWithMatches.concat(Object.values(reqsInCurrentFile));

    return allReqs;
  }, [requirements, currentOppFile, matches]);

  // clear selected requirements when switching opportunity files
  useEffect(clearSelections, [selectedOpportunityFileIndex]);

  const currentDocString = `Document ${selectedOpportunityFileIndex + 1} of ${opportunityFiles.length}:` +
    ` ${currentOppFile?.file_name}`;

  const selectedRequirements = requirements.filter(
    (requirement) => currentlyChecked.includes(requirement.uid)
  );
  const requirementsListEmptyState = (
    <Center
      style={{
        borderRadius: 4
      }}
      pl={48}
      pr={48}
      h={300}
      bg='var(--mantine-color-greyPurple-0)'
    >
      <Text>{EMPTY_STATE_TEXT}</Text>
    </Center>
  );

  const requirementsList = sortedFilteredRequirements.length > 0
    ? sortedFilteredRequirements?.map((req) => (
    <Box
      mr={2}
      mah={59}
      key={req?.uid}
      style={{
        backgroundColor: req?.uid === selectedRequirementUid ? 'var(--mantine-color-green-1)' : undefined
      }}
    >
      <ReviewRequirementsRow
        ref={(el) => { requirementRowRefs.current[req.uid] = el; }}
        handleEditRequirement={() => { handleEditRequirement(req); }}
        handleClickRequirement={() => { toggleClickRequirement(req.uid); }}
        requirement={req}
        checkedRequirements={currentlyChecked}
        setCheckedRequirements={(reqs) => { setCurrentlyChecked(reqs); }}
        opportunityUid={proposal?.opportunity?.uid}
      />
    </Box>
    ))
    : requirementsListEmptyState;
  const centeredLoader = <CenteredLoader h="calc(100vh - 300px)" />;

  return (
    <>
      {
        selectedRequirement !== undefined && (
          <Modal opened={editRequirementOpened} {...defaultModalProps}>
            <EditRequirement
              close={editRequirementHandlers.close}
              requirement={selectedRequirement}
              opportunityUid={proposal?.opportunity?.uid}
              onEditRequirement={onEditRequirement}
            />
          </Modal>
        )
      }
      <Modal opened={deleteRequirementOpened} {...defaultModalProps}>
        <DeleteRequirements
          close={deleteRequirementHandlers.close}
          requirements={selectedRequirements}
          opportunityUid={proposal?.opportunity?.uid}
          setCurrentlyChecked={setCurrentlyChecked}
        />
      </Modal>
      <Box
        pt={24}
        display="flex"
        style={{
          flexDirection: 'row',
          width: '55vw',
          overflowY: 'scroll'
        }}
      >
        <WizardContent
          titleCopy={`${proposal.name ?? ''} is ready to edit`}
          subtitleCopy={`Begin by adding, editing, or deleting requirements.
          When you're done, we'll help you organize them into sections`}
          onClickNext={handleClickNext}
          onClickPrevious={selectedOpportunityFileIndex === 0 ? undefined : handleClickPrevious}
        >
          <Group
            justify='space-between'
            mb={8}
            mr={6}
            wrap='nowrap'
          >
            <Text truncate='end'>
              <b>{currentDocString}</b>
            </Text>
            <Box ta='end'>
            {
              currentlyChecked.length === 0
                ? (
                  <Button
                    w={190}
                    onClick={() => { handleAddRequirement('', currentOppFile.uid ?? ''); }}
                    variant='outline'
                    leftSection={<AddFile/>}
                  >
                    Add Requirement
                  </Button>)
                : (
                  <Group justify='end' wrap='nowrap'>
                    <Button
                      onClick={clearSelections}
                      variant='outline'
                    >
                      {`Clear ${currentlyChecked.length}`}
                    </Button>
                    <Button
                      onClick={deleteRequirementHandlers.open}
                      variant='outline'
                      color='red'
                      leftSection={<Trash />}
                    >
                      Delete
                    </Button>
                  </Group>
                  )
              }
            </Box>
          </Group>
          <ScrollArea.Autosize type='auto' pr={4} mah="calc(100vh - 300px)">
            { documentLoading ? centeredLoader : requirementsList }
          </ScrollArea.Autosize>
        </WizardContent>
      </Box>
    </>
  );
};

export default ReviewRequirements;
