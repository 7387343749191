/* eslint-disable max-len */

const Pen: React.FC = () => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M-0.00146484 14.2505V18.0005H3.74854L14.8085 6.94055L11.0585 3.19055L-0.00146484 14.2505ZM17.7085 4.04055C18.0985 3.65055 18.0985 3.02055 17.7085 2.63055L15.3685 0.290547C14.9785 -0.0994531 14.3485 -0.0994531 13.9585 0.290547L12.1285 2.12055L15.8785 5.87055L17.7085 4.04055Z" />
  </svg>
);

export default Pen;
