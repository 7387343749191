import { useParams } from 'react-router-dom';

import { ProposalWizard } from './ProposalWizard';
import CreateProposal from './CreateProposal';

const CreateProposalWizard: React.FC = () => {
  const { proposalUid } = useParams();

  if (proposalUid !== undefined) {
    return <ProposalWizard />;
  }

  return <CreateProposal />;
};
export default CreateProposalWizard;
