import { Text } from '@mantine/core';
import {
  type ColumnDef,
  createColumnHelper,
  type Row
} from '@tanstack/react-table';
import { useParams } from 'react-router-dom';

import { ThreeDotsMenu } from '../components';
import { type TableRows, type FactRow } from '../types/tableTypes';
import { type components } from '../generated/schema';
import useOrgPermissions from './useOrgPermissions';
import { Pencil, Trash } from '../icons';
import usePaginatedTable from './usePaginatedTable';
import { getDateStringForTableCell } from '../utils/tableUtils';
import { type PaginatedRoute } from '../types/apiTypes';

const DEFAULT_SORTING_FIELD = 'text';
const COLUMN_NAME_TO_DB_FIELD_MAP = new Map<string, string>([
  ['text', 'text'],
  ['updatedAt', 'updated_at']
]);
const DEFAULT_SORT_DIRECTION = 'ASC';

const formatedCurrentFactsData = (
  currentFactData: Array<components['schemas']['MemoryItemSchema']>
): FactRow[] => currentFactData?.map((fact) => ({
  uid: fact.uid ?? '',
  text: fact.text ?? '',
  updatedAt: fact.updated_at != null ? new Date(fact.updated_at) : null,
  isClickable: false
}));

const useFactsTableData = (
  handleDelete: (row: Row<FactRow>) => void,
  openEdit: (row: Row<FactRow>) => void,
  organizationSlug?: string,
  proposalUid?: string
) => {
  const { userHasPermission } = useOrgPermissions();
  const { organization } = useParams();
  const route: PaginatedRoute = proposalUid !== undefined
    ? `/app/proposals/${proposalUid}/memory-items`
    : `/app/organizations/${organization}/memory-items`;

  const getMenuItems = (row: Row<FactRow>) => ([
    {
      label: 'Edit Fact',
      onClick: () => {
        openEdit(row);
      },
      shouldRender: userHasPermission('change'),
      icon: <Pencil />
    },
    {
      label: <Text c='red' fw='bold'> Delete Fact </Text>,
      onClick: () => { handleDelete(row); },
      shouldRender: userHasPermission('delete'),
      icon: <Trash color='red' />
    }
  ]);

  const columnHelper = createColumnHelper<FactRow>();

  const columns = [
    columnHelper.accessor('text', {
      header: 'FACT',
      cell: info => <Text> {info.getValue()} </Text>,
      size: 65
    }),
    columnHelper.accessor('updatedAt', {
      header: 'LAST EDITED',
      cell: info => <Text> {getDateStringForTableCell(info.getValue())} </Text>,
      size: 32
    }),
    columnHelper.display({
      id: 'actions',
      cell: info => {
        const menuItems = getMenuItems(info.row).filter((item) => item.shouldRender);
        const shouldShowMenu = menuItems.length > 0;
        return shouldShowMenu && (
          <ThreeDotsMenu orientation='horizontal' menuItems={menuItems}/>
        );
      },
      size: 3
    })
  ] as Array<ColumnDef<TableRows>>;

  return usePaginatedTable({
    defaultSortingField: DEFAULT_SORTING_FIELD,
    defaultSortDirection: DEFAULT_SORT_DIRECTION,
    route,
    formatData: formatedCurrentFactsData,
    columnNameToDBFieldMap: COLUMN_NAME_TO_DB_FIELD_MAP,
    columnData: columns,
    queryParams: {
      organization_slug: organizationSlug ?? ''
    }
  });
};

export default useFactsTableData;
