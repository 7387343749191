import { Box, NavLink } from '@mantine/core';

import { useNavigate, useLocation, useParams } from 'react-router-dom';

import classes from './SideMenu.module.css';
import { Star, Cloud, SettingsGear } from '../../icons';
import { getSplitLocation } from '../../utils/stringUtils';

export const SIDE_MENU_WIDTH = '207px';
const sideMenuItems = [
  {
    label: 'My Proposals',
    link: '/proposals',
    activePath: 'proposals',
    icon: Star
  },
  {
    label: 'Resources',
    link: '/resources',
    activePath: 'resources',
    icon: Cloud
  },
  {
    label: 'Settings',
    link: '/settings/profile',
    activePath: 'settings',
    icon: SettingsGear
  }
];
const SideMenu: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { organization, proposalUid } = useParams();
  const isCollapsed = proposalUid !== undefined || location.pathname.includes('create-proposal');

  const items = sideMenuItems.map((item, index) => (
    <NavLink
      key={+index}
      active = {getSplitLocation(location).includes(item.activePath)}
      w={195}
      classNames={{ root: classes.root, label: classes.label }}
      label={item.label}
      leftSection= { <item.icon />}
      onClick={() => {
        navigate(`/org/${organization}${item.link}`);
      }}
    />
  ));

  return isCollapsed
    ? <></>
    : (<nav>
        <Box
          bg= 'var(--mantine-color-greyPurple-1)'
          w={SIDE_MENU_WIDTH}
          style={{
            height: 'calc(100vh - 64px)',
            minHeight: 250,
            borderRadius: '0px 8px 8px 0px'
          }}
        >
          {items}
        </Box>
    </nav>);
};

export default SideMenu;
