import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

interface NarrativeEditorState {
  updatedContent?: string
  wordCount: number
}

interface UpdatePayload {
  content?: string
  originalContent?: string
  wordCount: number
}

const initialState: NarrativeEditorState = {
  wordCount: 0
};

const narrativeEditorSlice = createSlice({
  name: 'narrativeEditor',
  initialState,
  reducers: {
    updateText (state, action: PayloadAction<UpdatePayload>) {
      const updatedContent = action.payload.originalContent === action.payload.content
        ? undefined
        : action.payload.content;
      return {
        updatedContent,
        wordCount: action.payload.wordCount
      };
    },
    resetText (state) {
      return {
        ...state,
        updatedContent: undefined
      };
    }
  }
});

export const { updateText, resetText } = narrativeEditorSlice.actions;
export default narrativeEditorSlice.reducer;
