import { type AxiosError } from 'axios';

import type { paths } from '../generated/schema';
import { DEPLOYMENT_ENV } from '../Config';

import type {
  Content, PermissionType, ObjectWithPermissions, WithoutPermissions, ProposalSection
} from '../types/apiTypes';
import { showFailureNotification, showWarningNotification } from './mantineUtils';
import { type SectionData } from '../Pages/Editor/SectionEditor';

const ENSIS_API_URLS = {
  local: 'https://localhost:8000',
  dev: 'https://api-dev.ensis.ai',
  qa: 'https://api-qa.ensis.ai',
  staging: 'https://api-staging.ensis.ai',
  prod: 'https://api.ensis.ai',
  tria: 'https://api-tria.ensis.ai'
};
export const ENSIS_API_URL = ENSIS_API_URLS[DEPLOYMENT_ENV];

export const getAuthHeaders = (accessToken: string) => ({
  Authorization: `Bearer ${accessToken}`
});

export const getContentHeaders = (contentType: Content) => ({
  'Content-Type': contentType
});

export const getFullURLForRoute = (route: keyof paths) => (
  `${ENSIS_API_URL}${route}`
);

export function hasPermissionsForObject<Item extends ObjectWithPermissions> (
  obj: Item | undefined,
  permission: PermissionType
): boolean {
  return obj?.permissions?.includes(permission) ?? false;
}

export const formatSectionsMetadata: (
  data: Array<WithoutPermissions<ProposalSection>> | undefined
) => SectionData[] = (
  data: Array<WithoutPermissions<ProposalSection>> | undefined
) => data?.map((section) => ({
  uid: section.uid,
  title: section.title,
  slug: section.slug,
  ordinal: section.ordinal,
  parentSectionUid: section.parent_proposal_section_uid ?? undefined,
  childSections: (section.child_sections !== undefined && section.child_sections?.length !== 0)
    ? formatSectionsMetadata(section.child_sections ?? [])
    : []
})) ?? [];

export const handlePotentialLockError = (error: AxiosError, warningCopy: string) => {
  if (error.response?.status === 409) {
    showWarningNotification(warningCopy);
  } else {
    showFailureNotification();
  }
};

export const INVALID_FILE_ERROR_MESSAGE = 'We encountered a problem with one or more of your opportunity files. ' +
  'Please review and correct the files.';
