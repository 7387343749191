import { ActionIcon, Button, Loader, Popover, Stack } from '@mantine/core';
import { useCallback, useState } from 'react';

import useEnsisMutation from '../../hooks/useEnsisMutation';
import classes from './DropdownMenus.module.css';
import { DownloadProposal } from '../../icons';
import { logAnalyticsEvent } from '../../utils/analyticsUtils';

interface Props {
  proposalUid: string
}

enum ProposalExportType {
  OUTLINE = 'OUTLINE',
  NARRATIVE = 'NARRATIVE'
}
const DownloadProposalDropdown: React.FC<Props> = (props: Props) => {
  const { proposalUid } = props;
  const [opened, setOpened] = useState(false);

  const exportProposalMutation = useEnsisMutation(
    '/app/proposal-exports',
    {
      contentType: 'application/json',
      requestType: 'post',
      showSuccessMessage: false,
      onSuccess: (data) => {
        if (data?.url != null) {
          window.open(data?.url, '_blank', 'noreferrer');
        }
      }
    }
  );

  const mutationPending = exportProposalMutation.isPending;
  const handleExportProposal = useCallback((exportType: ProposalExportType) => {
    exportProposalMutation.mutate({ proposal_uid: proposalUid ?? '', export_type: exportType });
  }, [exportProposalMutation.mutate]);

  const buttonClassNames = {
    inner: classes.popoverMenuButtonItem,
    section: classes.popoverMenuButtonLeftSection,
    root: classes.popoverMenuButtonItemRoot
  };

  return (
  <Popover
    classNames={{ dropdown: classes.popoverDropdown }}
    position='bottom-end'
    offset={25}
    opened={opened}
    onChange={setOpened}
  >
    <Popover.Target>
     {mutationPending
       ? <Loader size="sm" />
       : <ActionIcon size={36} p={6} variant='subtle' onClick={() => { setOpened(!opened); }}>
          <DownloadProposal />
        </ActionIcon>
      }
    </Popover.Target>
    <Popover.Dropdown>
      <Stack gap={0}>
        <Button
          variant="subtle"
          classNames={buttonClassNames}
          onClick={() => {
            logAnalyticsEvent('ExportProposalOutline');
            handleExportProposal(ProposalExportType.OUTLINE);
            setOpened(false);
          }}
        >
          Export Proposal Outline
        </Button>
        <Button
          variant="subtle"
          classNames={buttonClassNames}
          onClick={() => {
            logAnalyticsEvent('ExportProposalNarrative');
            handleExportProposal(ProposalExportType.NARRATIVE);
            setOpened(false);
          }}
        >
          Export Proposal Narrative
        </Button>
      </Stack>
    </Popover.Dropdown>
  </Popover>);
};

export default DownloadProposalDropdown;
