/* eslint-disable max-len */

const Exit: React.FC = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
      <path d="M2.16667 0.5H13.8333C14.7583 0.5 15.5 1.24167 15.5 2.16667V5.5H13.8333V2.16667H2.16667V13.8333H13.8333V10.5H15.5V13.8333C15.5 14.2754 15.3244 14.6993 15.0118 15.0118C14.6993 15.3244 14.2754 15.5 13.8333 15.5H2.16667C1.72464 15.5 1.30072 15.3244 0.988156 15.0118C0.675595 14.6993 0.5 14.2754 0.5 13.8333V2.16667C0.5 1.24167 1.25 0.5 2.16667 0.5ZM9.6 10.9833L8.41667 12.1667L4.25 8L8.41667 3.83333L9.6 5.00833L7.44167 7.16667H15.5V8.83333H7.44167L9.6 10.9833Z"/>
    </svg>
  );
};

export default Exit;
