import { useParams } from 'react-router-dom';
import { useCallback, useState } from 'react';
import { type Row } from '@tanstack/react-table';
import { Text, Anchor, Modal } from '@mantine/core';
import { notifications } from '@mantine/notifications';
import { useDisclosure } from '@mantine/hooks';

import { CenteredLoader, Pagination, Table } from '../../components';
import useFactsTableData from '../../hooks/useFactsTableData';
import { type PaginatedRoute } from '../../types/apiTypes';
import { type FactRow } from '../../types/tableTypes';
import useEnsisMutation from '../../hooks/useEnsisMutation';
import TableEmptyState from '../Table/TableEmptyState';
import { defaultModalProps } from '../../utils/mantineUtils';
import EditFact from '../Modals/EditFact';

interface FactsTableProps {
  proposalUid?: string
}

const FactsTable: React.FC<FactsTableProps> = (
  { proposalUid }: FactsTableProps
) => {
  const [selectedRow, setSelectedRow] = useState<FactRow>();
  const { organization } = useParams();
  const [opened, { close: closeModal, open: openModal }] = useDisclosure();
  const [selectedFactRow, setSelectedFactRow] = useState<FactRow>();
  const route: PaginatedRoute = proposalUid !== undefined
    ? `/app/proposals/${proposalUid}/memory-items`
    : `/app/organizations/${organization}/memory-items`;

  const handleOpenEdit = (row: Row<FactRow>) => {
    setSelectedFactRow(row.original);
    openModal();
  };

  const handleCloseEdit = () => {
    setSelectedFactRow(undefined);
    closeModal();
  };

  const undoDeleteFactMutation = useEnsisMutation(
    route,
    {
      requestType: 'post',
      successMessage: 'Fact restored.',
      queryKeysToInvalidate: [route]
    }
  );

  const handleUndoDeleteFact = useCallback(() => {
    notifications.clean();
    undoDeleteFactMutation.mutate({
      items: [selectedRow?.text ?? '']
    });
  }, [undoDeleteFactMutation]);

  const deleteFactMutation = useEnsisMutation(
    `/app/memory-items/${selectedRow?.uid}/data`,
    {
      requestType: 'delete',
      successMessage: (
        <Text fz='sm'>
          {'Fact removed. '}
          <Anchor fz='sm' onClick={handleUndoDeleteFact}>
            Undo
          </Anchor>
        </Text>
      ),
      queryKeysToInvalidate: [route]
    }
  );

  const handleDeleteFact = useCallback((row: Row<FactRow>) => {
    setSelectedRow(row.original);
    deleteFactMutation.mutate({});
  }, [deleteFactMutation]);

  const { table, isLoading } = useFactsTableData(
    handleDeleteFact,
    handleOpenEdit,
    organization,
    proposalUid
  );

  if (isLoading) {
    return <CenteredLoader />;
  }

  if (table.getRowModel().rows.length === 0) {
    return <TableEmptyState text='No facts created yet.' />;
  }

  return (
    <>
      <Modal opened={opened} {...defaultModalProps}>
        <EditFact
          close={handleCloseEdit}
          fact={selectedFactRow?.text ?? ''}
          factUid={selectedFactRow?.uid ?? ''}
          proposalUid={proposalUid}
        />
      </Modal>
      <Table table={table} tableStyle='simple' />
      <Pagination table={table} />
    </>
  );
};

export default FactsTable;
