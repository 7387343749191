import { Box, Radio, TextInput, Text } from '@mantine/core';
import { useDispatch, useSelector } from 'react-redux';

import { useCallback, useEffect, useMemo, useState } from 'react';

import WizardContent from './WizardContent';
import { selectWizardState } from '../../redux/store';

import { selectOpportunityFile } from '../../redux/WizardSlice';
import { CenteredLoader } from '../../components';
import { canRenderFile } from '../../utils/pdfUtils';
import useEnsisMutation from '../../hooks/useEnsisMutation';
import {
  type PageRangeType,
  getPageRangeDataFromOpportunityFile,
  getPageRangeError
} from '../../utils/pageRangeUtils';

interface Props {
  onDone: () => void
}
const SelectRequirementPages: React.FC<Props> = (props: Props) => {
  const { onDone } = props;
  const dispatch = useDispatch();

  const {
    opportunityFiles,
    selectedOpportunityFileIndex,
    documentLoading
  } = useSelector(selectWizardState);
  const opportunityFile = opportunityFiles[selectedOpportunityFileIndex];
  const [pageRangesString, setPageRangesString] = useState('');
  const [pageRangeType, setPageRangeType] = useState<PageRangeType>('all-pages');

  const updatePageRangeMutation = useEnsisMutation(
    `/app/opportunity-files/${opportunityFile?.uid}/data`,
    {
      requestType: 'patch',
      showSuccessMessage: false,
      queryKeysToInvalidate: ['/app/opportunity-files']
    }
  );

  const handleOnInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPageRangesString(event.currentTarget.value);
    setShouldCheckErrorString(true);
  };

  const handleClickNext = useCallback(async () => {
    const shouldExtractRequirements = pageRangeType !== 'none';
    const _pageRangesString = pageRangeType === 'other' ? pageRangesString : '';
    await updatePageRangeMutation.mutateAsync({
      requirements_page_ranges: _pageRangesString,
      should_extract_requirements: shouldExtractRequirements
    });
    if (selectedOpportunityFileIndex < opportunityFiles.length - 1) {
      dispatch(selectOpportunityFile({ opportunityFileIndex: selectedOpportunityFileIndex + 1 }));
    } else {
      onDone();
    }
  }, [pageRangeType, pageRangesString, selectedOpportunityFileIndex, opportunityFiles, onDone]);

  const handleOnChange = useCallback((value: string) => {
    setPageRangeType(value as PageRangeType);
  }, []);

  const currentDocString = `Document ${selectedOpportunityFileIndex + 1} of ${opportunityFiles.length}:` +
    ` ${opportunityFile?.file_name}`;

  const handleOnClickPrevious = () => {
    if (selectedOpportunityFileIndex > 0) {
      dispatch(selectOpportunityFile({ opportunityFileIndex: selectedOpportunityFileIndex - 1 }));
    }
  };

  const fileIsRenderable = useMemo(
    () => canRenderFile(opportunityFile?.file_name ?? ''),
    [opportunityFile]
  );
  const otherIsSelected = pageRangeType === 'other';
  const [shouldCheckErrorString, setShouldCheckErrorString] = useState(false);

  const resetState = useCallback(() => {
    if (opportunityFile !== undefined) {
      const stateData = getPageRangeDataFromOpportunityFile(opportunityFile);
      setPageRangesString(stateData[0]);
      setPageRangeType(stateData[1]);
    }
  }, [opportunityFile]);

  useEffect(resetState, [opportunityFile?.uid]);

  // don't show error on initial click of "specific pages" if no page range has been entered
  useEffect(() => {
    if (otherIsSelected && pageRangesString?.length > 0) {
      setShouldCheckErrorString(true);
    } else {
      setShouldCheckErrorString(false);
    }
  }, [pageRangesString, otherIsSelected]);

  const errorString = useMemo(() => {
    if (!shouldCheckErrorString) {
      return undefined;
    }
    return getPageRangeError(pageRangesString, opportunityFile?.numPages ?? 0);
  }, [
    opportunityFile,
    shouldCheckErrorString,
    pageRangesString
  ]);

  const centeredLoader = <CenteredLoader h="calc(100vh - 300px)" />;

  if (selectOpportunityFile === undefined) {
    return centeredLoader;
  }

  const nextButtonDisabled = errorString !== undefined || updatePageRangeMutation.isPending ||
    (otherIsSelected && pageRangesString.length === 0);

  const radioGroup = (
    <Radio.Group
      value={pageRangeType}
      onChange={handleOnChange}
    >
      <Radio value='all-pages' label='All pages' mt='md'/>
      {fileIsRenderable && <Radio value='other' label='Specific pages' mt='md'/>}
      {fileIsRenderable && <TextInput
        error={errorString}
        value={pageRangesString}
        onChange={handleOnInputChange}
        ml={28}
        mt={8}
        w={290}
        disabled={pageRangeType !== 'other'}
        placeholder='eg. 4-5, 8, 11-14'
      />}
      <Radio value='none' label='No requirements in this document' mt='md'/>
    </Radio.Group>
  );
  return (
    <Box w='55vw' h='calc(100vh - 92px)' pt={24}>
    <WizardContent
      titleCopy="Review content"
      subtitleCopy="Select the pages within your opportunities that contain requirements"
      onClickNext={() => { void handleClickNext(); }}
      nextButtonDisabled={nextButtonDisabled}
      onClickPrevious={selectedOpportunityFileIndex > 0 ? handleOnClickPrevious : undefined}
    >
    {
    <Box ta='start'>
      <Text fw={600} truncate='end'>
        {currentDocString}
      </Text>
      {documentLoading ? centeredLoader : radioGroup}
    </Box>
    }
  </WizardContent>
</Box>
  );
};

export default SelectRequirementPages;
