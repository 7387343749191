import { ActionIcon, Group } from '@mantine/core';

import { useCallback, useMemo } from 'react';

import { useNavigate, useParams } from 'react-router-dom';

import { ChevronLeft, ChevronRight } from '../../icons';
import { type SectionData } from '../../Pages/Editor/SectionEditor';

interface Props {
  sections: SectionData[]
}

const getChildSections = (currentSection: SectionData) => {
  const ans = [currentSection.uid];
  currentSection.childSections?.forEach((childSection) => {
    ans.push(...getChildSections(childSection));
  });
  return ans;
};

const SectionEditorNavigation: React.FC<Props> = (props: Props) => {
  const { sections } = props;
  const { sectionUid, organization, proposalUid } = useParams();

  // make more efficient
  const flatSections = useMemo(() => sections.filter(
    (section) => section.parentSectionUid === undefined
  ).map(
    (section) => getChildSections(section)
  ).flat(1), [sections]);

  const sectionIndex = flatSections.findIndex((_sectionUid) => _sectionUid === sectionUid);

  const navigate = useNavigate();

  const handleClickPrevious = useCallback(() => {
    navigate(`/org/${organization}/proposals/${proposalUid}/edit/${flatSections[sectionIndex - 1]}`);
  }, [sectionIndex]);
  const handleClickNext = useCallback(() => {
    navigate(`/org/${organization}/proposals/${proposalUid}/edit/${flatSections[sectionIndex + 1]}`);
  }, [sectionIndex]);

  const previousDisabled = sectionIndex === 0;
  const nextDisabled = sectionIndex === sections.length - 1;

  return (<Group>
    <ActionIcon disabled={previousDisabled} variant='outline' size={36} onClick={handleClickPrevious}>
      <ChevronLeft/>
    </ActionIcon>
    <ActionIcon disabled={nextDisabled} variant='outline' size={36} onClick={handleClickNext}>
      <ChevronRight/>
    </ActionIcon>
  </Group>);
};

export default SectionEditorNavigation;
