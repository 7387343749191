import { useCallback } from 'react';
import { useParams } from 'react-router-dom';

import useEnsisQuery from './useEnsisQuery';
import type { PermissionType } from '../types/apiTypes';

function useOrgPermissions () {
  const { organization } = useParams();
  const {
    data,
    ...otherItems
  } = useEnsisQuery(`/app/organizations/${organization}/data`);

  const permissions = data?.permissions ?? [] as PermissionType[];
  const organizationName = data?.name ?? '';
  const role = data?.role ?? '';
  const userHasPermission = useCallback(
    (permission: PermissionType) => new Set(permissions).has(permission),
    [permissions]
  );

  return { role, permissions, organizationName, userHasPermission, ...otherItems };
}

export default useOrgPermissions;
