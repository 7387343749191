import { Anchor, Box, Text } from '@mantine/core';

import { CannotRenderFile as CannotRenderFileIcon } from '../../icons';

interface Props {
  downloadUrl: string
}

const CannotRenderFileView: React.FC<Props> = ({ downloadUrl }: Props) => (
  <Box
    h='100%'
    w='100%'
    p={64}
    display='flex'
    style={{ justifyContent: 'center', alignItems: 'center' }}
  >
    <Box display='flex' style={{ flexDirection: 'column', alignItems: 'center' }}>
      <CannotRenderFileIcon />
      <Text c='var(--mantine-color-gray-6)' mt={24}>
        Unable to display this document type. Supported file types are PDF, DOC, or PPT.
      </Text>
      <Text c='var(--mantine-color-gray-6)'>
        {'Click '}
        <Anchor
          href={downloadUrl}
          target="_blank"
          underline='always'
          inherit
        >
          here
        </Anchor>
        {' to download the file.'}
      </Text>
    </Box>
  </Box>
);

export default CannotRenderFileView;
