import { Box, Group, Modal, Title, Button, ScrollArea, LoadingOverlay } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { useParams } from 'react-router-dom';
import { useCallback, useState } from 'react';
import { type Row } from '@tanstack/react-table';

import { Pagination, ResourceFilter, Table } from '../../components';
import { type ResourceRow } from '../../types/tableTypes';
import useResourceTableData from '../../hooks/useResourceTableData';
import { FileUploadIcon } from '../../icons';
import { formatOrganizationTitle } from '../../utils/stringUtils';
import UploadResources from '../../components/Forms/UploadResources/UploadResources';
import EditResource from '../../components/Forms/EditResourceDetails/EditResourceDetails';
import DeleteResource from '../../components/Modals/DeleteResource/DeleteResource';
import useOrgPermissions from '../../hooks/useOrgPermissions';
import TableEmptyState, { type TableEmptyStateProps } from '../../components/Table/TableEmptyState';
import { defaultModalProps } from '../../utils/mantineUtils';
// eslint-disable-next-line max-len
import useEnsisQuery from '../../hooks/useEnsisQuery';
import { type ResourceType } from '../../types/miscTypes';
import { ManageOrganizationResourceTags } from '../../components/Modals';
import { SIDE_MENU_WIDTH } from '../../components/SideMenu/SideMenu';

const ADMIN_EMPTY_STATE_TEXT = 'You haven\'t imported any resources.';
// eslint-disable-next-line max-len
const NON_ADMIN_EMPTY_STATE_TEXT = 'Your organization\'s administator has not imported any resources. Contact your administrator or info@ensis.ai for support.';

const Resources: React.FC = () => {
  const { organization } = useParams();
  const [uploadOpened, uploadHandlers] = useDisclosure(false);
  const [editOpened, editHandlers] = useDisclosure(false);
  const [deleteOpened, deleteHandlers] = useDisclosure(false);
  const [editResourceTagsOpened, editResourceTagsHandlers] = useDisclosure(false);
  const [currentResource, setCurrentResource] = useState<ResourceRow>();
  const [searchTags, setSearchTags] = useState<string[]>([]);
  const [resourceTypes, setResourceTypes] = useState<ResourceType[]>([]);

  const { userHasPermission, organizationName, isLoading: permissionsLoading } = useOrgPermissions();

  const handleOpenEdit = (row: Row<ResourceRow>) => {
    setCurrentResource(row.original);
    editHandlers.open();
  };

  const handleOpenDelete = (row: Row<ResourceRow>) => {
    setCurrentResource(row.original);
    deleteHandlers.open();
  };

  const handleDownload = (row: Row<ResourceRow>) => {
    setCurrentResource(row.original);
    window.open(row?.original?.url ?? '', '_blank', 'noreferrer');
  };

  const userCanUploadResource = userHasPermission('upload_resource');

  const { data, isLoading: tagsLoading } = useEnsisQuery(
    '/app/organization-resource-tags',
    {
      queryParams: {
        organization_slug: organization ?? ''
      }
    }
  );

  const { table, isLoading: tableLoading } = useResourceTableData(
    {
      organizationSlug: organization ?? '',
      onEdit: handleOpenEdit,
      onDelete: handleOpenDelete,
      onDownload: handleDownload,
      onEditResourceTags: () => { editResourceTagsHandlers.open(); },
      searchTags,
      resourceTypes,
      organizationResourceTags: data?.items ?? [],
      selectMode: 'hidden'
    }
  );

  const handleResourcesUploaded = useCallback(() => {
    table.setPageIndex(0);
  }, [table.setPageIndex]);

  const isLoading = permissionsLoading || tableLoading || tagsLoading;

  const emptyStateProps: TableEmptyStateProps = {
    text: userCanUploadResource ? ADMIN_EMPTY_STATE_TEXT : NON_ADMIN_EMPTY_STATE_TEXT,
    actionButton: userCanUploadResource
      ? {
          label: 'Import',
          onClick: uploadHandlers.open
        }
      : undefined
  };

  return (
    <>
      <Modal
        opened={uploadOpened}
        {...defaultModalProps}
      >
        <UploadResources onClose={uploadHandlers.close} onUploadResources={handleResourcesUploaded} />
      </Modal>
      <Modal
        opened={editOpened}
        {...defaultModalProps}
      >
        <EditResource
          resourceUid={currentResource?.uid}
          name={currentResource?.resourceName}
          description={currentResource?.description}
          resourceType={currentResource?.resourceType}
          onClose={editHandlers.close}
        />
      </Modal>
      <Modal
        opened={deleteOpened}
        {...defaultModalProps}
      >
        <DeleteResource
          resourceUid={currentResource?.uid}
          name={currentResource?.resourceName}
          onClose={deleteHandlers.close}
        />
      </Modal>
      <Modal
        opened={editResourceTagsOpened}
        {...defaultModalProps}
      >
        <ManageOrganizationResourceTags
          organizationSlug={organization ?? ''}
          onClose={editResourceTagsHandlers.close}
        />
      </Modal>
      <Box w={`calc(100vw - ${SIDE_MENU_WIDTH})`} px={48} pt={24}>
        <Group justify='space-between'>
          <Title pt={20} ta={'start'} order={5}>
            {formatOrganizationTitle(organizationName ?? '', 'Resources')}
          </Title>
          {
            userCanUploadResource &&
            <Button color='red' h='42' w='224' fz='md' onClick={uploadHandlers.open}>
              <FileUploadIcon />
              Import Resources
            </Button>
          }
        </Group>
        <ScrollArea mb={8} style={{ height: 'calc(100vh - 225px)', maxHeight: '538px' }} >
          <LoadingOverlay visible={isLoading} />
          <ResourceFilter
            tags={data?.items ?? []}
            setSelectedTags={setSearchTags}
            selectedTags={searchTags}
            selectedResourceTypes={resourceTypes}
            setSelectedResourceTypes={setResourceTypes}
            filterByInUse={false}
          />
          {
            table.getRowModel().rows.length === 0
              ? <TableEmptyState {...emptyStateProps} />
              : <Table table={table} tableStyle='withTags' />
          }
        </ScrollArea>
        <Pagination table={table} />
      </Box>
    </>
  );
};

export default Resources;
