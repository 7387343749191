/* eslint-disable max-len */
import React, { type SVGProps } from 'react';

const GenerateAllIcon: React.FC<Partial<SVGProps<SVGSVGElement>>> = () => (
  <svg width='18' height='20' viewBox='0 0 18 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path fillRule='evenodd' clipRule='evenodd' d='M11.2243 2.05526C11.0208 2.00643 10.7931 2 10.0118 2H7.8C6.94342 2 6.36113 2.00078 5.91104 2.03755C5.47262 2.07337 5.24842 2.1383 5.09202 2.21799C4.7157 2.40974 4.40973 2.7157 4.21799 3.09202C4.1383 3.24842 4.07337 3.47262 4.03755 3.91104C4.00078 4.36113 4 4.94342 4 5.8V9H2L2 5.7587C1.99999 4.95373 1.99998 4.28937 2.04419 3.74818C2.09012 3.18608 2.18868 2.66938 2.43598 2.18404C2.81947 1.43139 3.43139 0.819469 4.18404 0.435976C4.66937 0.188685 5.18608 0.0901209 5.74818 0.0441957C6.28937 -2.16812e-05 6.95373 -1.11903e-05 7.7587 1.56512e-06L10.0118 2.04195e-06C10.0462 2.04195e-06 10.0801 -1.41705e-05 10.1137 -3.01446e-05C10.7487 -0.000332221 11.2284 -0.000560626 11.6911 0.110522C12.0993 0.208509 12.4895 0.370127 12.8474 0.589441C13.2531 0.838068 13.5922 1.17749 14.041 1.62672C14.0647 1.65043 14.0887 1.67445 14.113 1.69878L16.3012 3.88701C16.3256 3.91134 16.3496 3.93534 16.3733 3.95903C16.8225 4.40782 17.1619 4.74693 17.4106 5.15265C17.6299 5.51054 17.7915 5.90072 17.8895 6.30886C18.0006 6.77155 18.0003 7.25135 18 7.88634C18 7.91987 18 7.95382 18 7.98823V12.2413C18 13.0463 18 13.7106 17.9558 14.2518C17.9099 14.8139 17.8113 15.3306 17.564 15.816C17.1805 16.5686 16.5686 17.1805 15.816 17.564C15.3306 17.8113 14.8139 17.9099 14.2518 17.9558C13.7106 18 13.0463 18 12.2413 18H11V16H12.2C13.0566 16 13.6389 15.9992 14.089 15.9625C14.5274 15.9266 14.7516 15.8617 14.908 15.782C15.2843 15.5903 15.5903 15.2843 15.782 14.908C15.8617 14.7516 15.9266 14.5274 15.9624 14.089C15.9992 13.6389 16 13.0566 16 12.2V7.98823C16 7.20686 15.9936 6.97916 15.9447 6.77575C15.8957 6.57168 15.8149 6.37659 15.7053 6.19765C15.596 6.01929 15.4395 5.85373 14.887 5.30122L12.6988 3.113C12.1463 2.56048 11.9807 2.40402 11.8024 2.29472C11.6234 2.18506 11.4283 2.10426 11.2243 2.05526Z' fill='#343A40'/>
    <path fillRule='evenodd' clipRule='evenodd' d='M3.52566 13.5257L4.43079 10.8103H5.56921L6.47434 13.5257L9.18974 14.4308V15.5692L6.47434 16.4743L5.56921 19.1897H4.43079L3.52566 16.4743L0.810265 15.5692V14.4308L3.52566 13.5257Z' fill='#343A40'/>
    <path fillRule='evenodd' clipRule='evenodd' d='M12 2V6H16V8H11C10.4477 8 10 7.55229 10 7V2H12Z' fill='#343A40'/>
  </svg>
);

export default GenerateAllIcon;
