import { Extension } from '@tiptap/react';
import { type Node as ProsemirrorNode } from '@tiptap/pm/model';
import { Plugin, PluginKey } from '@tiptap/pm/state';
import { Decoration, DecorationSet } from '@tiptap/pm/view';

import { convertToRegEx } from '../components/OpportunityDocumentsViewer/OpportunityFileViewer';
import classes from '../Pages/Editor/Editor.module.css';
import store from '../redux/store';
import { showWarningNotification } from '../utils/mantineUtils';
import { updateShowWarning } from '../redux/NarrativeComplianceSlice';

const MAX_ARRAY_LENGTH = 20;
interface CompliantMatchType {
  from: number
  to: number
};

const convertHtmlToRegex = (text: string) => (
  convertToRegEx(text.replace(/(<([^>]+)>)/ig, ''))
);

const runCompliantMatchesPlugin: (doc: ProsemirrorNode) => DecorationSet = (doc) => {
  const state = store.getState().narrativeCompliance;
  const decorations = new Array<Decoration>();
  const results = new Array<CompliantMatchType>();
  const compliantTextRegex = new Array<string>();

  if (state.selectedResponseUid !== '') {
    state.requirementToCompliantText[state.selectedResponseUid]?.forEach(
      (item) => {
        // split each reference by <p/> so that each part of the text can be found in getMatches
        item.split('<p/>').forEach((i) => {
          if (i !== '') {
            compliantTextRegex.push(convertHtmlToRegex(i));
          }
        });
      }
    );

    const getMatches = () => {
      const regex = new RegExp(compliantTextRegex?.join('|'), 'gi');
      doc.descendants((node: ProsemirrorNode, position: number) => {
        if (!node.isText) {
          let matches = regex.exec(node.textContent);
          while (matches !== null && results.length < MAX_ARRAY_LENGTH) {
            results.push({ from: position + matches.index + 1, to: position + matches.index + matches[0].length + 1 });
            matches = regex.exec(node.textContent);
          };
        }
      });
    };

    if (compliantTextRegex.length > 0) {
      getMatches();
    };

    results.forEach((item) => {
      decorations.push(
        Decoration.inline(item.from, item.to, {
          class: classes.problem
        })
      );
    });

    if (results.length === 0 && state.showWarning) {
      showWarningNotification('The link to this requirement is missing. Rerun compliance to check if it is addressed');
      store.dispatch(updateShowWarning(false));
    };
  }
  return DecorationSet.create(doc, decorations);
};

export const CompliantHighlight = Extension.create<any>({
  name: 'compliantHighlights',

  addOptions () {
    return {
      plugins: []
    };
  },
  addProseMirrorPlugins () {
    return [
      new Plugin({
        key: new PluginKey('compliantHighlight'),
        state: {
          init (_, { doc }) {
            return runCompliantMatchesPlugin(doc);
          },
          apply (transaction) {
            // transaction.scrollIntoView();
            return runCompliantMatchesPlugin(transaction.doc);
          }
        },
        props: {
          decorations (state) {
            return this.getState(state);
          }
        }
      })
    ];
  }
});
