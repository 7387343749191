import type { OpportunityFile } from '../types/apiTypes';

export type PageRangeType = 'all-pages' | 'other' | 'none';

export const pageRangesToString = (pageRanges: number[][]): string => pageRanges.map((pageRange: number[]) => (
  pageRange[0] === pageRange[1] ? pageRange[0].toString() : `${pageRange[0]}-${pageRange[1]}`
)).join(',');

export const getPageRangeDataFromOpportunityFile = (oppFile: OpportunityFile): [string, PageRangeType] => {
  const {
    should_extract_requirements: shouldExtractRequirements,
    requirements_page_ranges: requirementsPageRanges
  } = oppFile;
  let rangesString = '';
  let rangeType: PageRangeType = 'all-pages';
  const hasPageRanges = requirementsPageRanges !== undefined && requirementsPageRanges != null &&
    (requirementsPageRanges?.length ?? 0) > 0;
  if ((shouldExtractRequirements ?? false) && hasPageRanges) {
    rangesString = pageRangesToString(requirementsPageRanges);
    rangeType = 'other';
  } else if (!(shouldExtractRequirements ?? false)) {
    rangeType = 'none';
  }

  return [rangesString, rangeType];
};

export const getPageRangeError = (pageRange: string, documentLength: number): string | undefined => {
  if (pageRange === '') {
    return 'Page range cannot be empty';
  } else {
    const regex = /^(\d+(-\d+)?)(,\s*\d+(-\d+)?)*$/;
    if (!regex.test(pageRange)) {
      return ('Invalid page range. Use format: 4-5, 8, 11-14');
    } else {
      const rangePattern = /(\d+)(-(\d+))?/g;
      let match;
      while ((match = rangePattern.exec(pageRange)) !== null) {
        const startPage = parseInt(match[1], 10);
        const endPage = match[3] !== '' ? parseInt(match[3], 10) : null;
        // If it’s a range, validate low-to-high order and in bounds
        if (startPage === 0) {
          return ('Invalid page range. Use format: 4-5, 8, 11-14');
        }
        if (endPage !== null) {
          if (startPage > endPage) {
            return ('Invalid page range. Use format: 4-5, 8, 11-14');
          }
          if (endPage > documentLength) {
            return ('Out of range. Page number exceeds document length');
          }
        }
        // Validate individual page or start of range
        if (startPage > documentLength) {
          return ('Out of range. Page number exceeds document length');
        }
      }
    }
    return undefined;
  }
};
