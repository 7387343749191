import { useCallback, useState } from 'react';
import { Box, LoadingOverlay } from '@mantine/core';

import ModalContent from '../ModalContent';
import Dropzone from '../Dropzone';
import type { FileWithMetadata } from '../Forms/UploadResources/UploadResources';
import useEnsisMutation from '../../hooks/useEnsisMutation';

interface Props {
  onClose: () => void
  opportunityUid: string
  opportunityFileUid?: string
  opportunityFileName?: string
}

const ImportOpportunityFile: React.FC<Props> = (props: Props) => {
  const { onClose, opportunityUid, opportunityFileName, opportunityFileUid } = props;
  const isReplacingFile = opportunityFileUid !== undefined;

  const [files, setFiles] = useState<FileWithMetadata[]>([]);

  const addOpportunityFileMutation = useEnsisMutation(
    '/app/opportunity-files',
    {
      requestType: 'post',
      contentType: 'multipart/form-data',
      successMessage: 'Opportunity File added',
      onSuccess: onClose,
      queryKeysToInvalidate: ['/app/opportunity-files']
    }
  );

  const replaceOpportunityFileMutation = useEnsisMutation(
    `/app/opportunity-files/${opportunityFileUid}/data`,
    {
      requestType: 'post',
      contentType: 'multipart/form-data',
      successMessage: 'Opportunity File replaced',
      onSuccess: onClose,
      queryKeysToInvalidate: ['/app/opportunity-files']
    }
  );

  const handleSubmit = useCallback(() => {
    const fileObjects = files.map((file) => file.fileObject) as unknown as string[];
    if (isReplacingFile) {
      replaceOpportunityFileMutation.mutate({ file: fileObjects[0] });
    } else {
      addOpportunityFileMutation.mutate({
        files: fileObjects,
        opportunity_uid: opportunityUid
      });
    }
  }, [
    isReplacingFile,
    opportunityUid,
    files,
    addOpportunityFileMutation.mutate,
    replaceOpportunityFileMutation.mutate
  ]);

  const isLoading = addOpportunityFileMutation.isPending || replaceOpportunityFileMutation.isPending;
  const subtitleText = isReplacingFile
    ? `Select an opportunity file to replace ${opportunityFileName}`
    : 'Select an opportunity file to add';

  return (
    <ModalContent
      title='Import an Opportunity File'
      subtitle={subtitleText}
      onClose={onClose}
      primaryButton={{
        label: 'Next',
        onClick: handleSubmit,
        disabled: files.length === 0 || isLoading
      }}
      leftSideButton={{
        label: 'Cancel',
        onClick: onClose
      }}
    >
      <LoadingOverlay visible={isLoading} />
      <Box ml={40} mr={40}>
        <Dropzone
          files={files}
          setFiles={setFiles}
          instructionText='Click or drag and drop to upload your opportunity file'
          maxFiles={1}
          multiple={false}
        />
      </Box>
    </ModalContent>
  );
};

export default ImportOpportunityFile;
